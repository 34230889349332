import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import axios from "axios";
import AddLeads from "./AddLeads";
import { toast } from "react-toastify";
import { Button, Modal } from "antd";
import { FaWhatsapp, FaEnvelope } from "react-icons/fa";
import ViewLeads from "./ViewLeads";
const AllLeads = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectionStatus, setSelectionStatus] = useState("Pending");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmCount, setConfirmCount] = useState(0);
   const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
  const [leadData, setLeadData] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query]
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchLeads = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/leads/get-leads`, {
        withCredentials: true,
      });
      console.log(response.data.data, "response");
      
      setLeadData(response.data.data);
      setSelectedLeads([])
      setSelectAll(false)
      setShowCheckboxes(false)
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
    finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchLeads();
  }, [apiUrl]);

  const handleRowClick = async (leadId) => {
    // ✅ Prevent modal from opening if checkboxes are active
    if (showCheckboxes) return;
  
    setOpenDropdown(null);
  
    try {
      const response = await axios.get(`${apiUrl}/api/leads/get-leads-data/${leadId}`, {
        withCredentials: true,
      });
  
      console.log("Lead data:", response.data.data);
      setSelectedLeads(response.data.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching lead data:", error);
    }
  };
  

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLeads(null);
  };

  const openEditModal = () => {
    setIsModalOpen(false);
    setIsAddModalOpen(true);
  };

  const handleReload = () => {
    fetchLeads();
  };
  const handleLeadEntriesChange = (value) => {
    setEntriesPerPage(value);
    setCurrentPage(1);
  };

  // Status Change Handler
  const handleStatusChange = async (leadId, newStatus) => {
    try {
      await axios.put(
        `${apiUrl}/api/leads/update-status`,
        { leadId, status: newStatus },
        { withCredentials: true }
      );

      // Update local state after API call
      setLeadData((prevLeads) =>
        prevLeads.map((lead) =>
          lead._id === leadId ? { ...lead, status: newStatus } : lead
        )
      );

      toast.success(`Status updated to ${newStatus}`);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status.");
    }
  };

  // **Handle Lead Selection**
 

   

  
 // **Deleting Selected Leads (excluding confirmed leads)**
 const handleDeleteSelected = async () => {
  if (selectedLeads?.length === 0) {
    setShowCheckboxes(true); // ✅ Ensure checkboxes remain visible
    return;
  }

  const leadsToDelete = selectedLeads?.filter(
    (leadId) => leadData.find((lead) => lead._id === leadId)?.status !== "Confirmed"
  );

  if (leadsToDelete?.length === 0) {
    toast.warning("Confirmed leads cannot be deleted.", { autoClose: 1500 });
    return; // ❌ Don't hide checkboxes
  }

  const confirmDelete = window.confirm(`Are you sure you want to delete ${leadsToDelete?.length} selected leads?`);
  if (!confirmDelete) return;

  try {
    await axios.delete(`${apiUrl}/api/leads/delete-lead-data`, {
      data: { leadIds: leadsToDelete },
      withCredentials: true,
    });

    setLeadData((prevLeads) => prevLeads.filter((lead) => !leadsToDelete.includes(lead._id)));
    setSelectedLeads([]);
    setShowCheckboxes(false); // ✅ Only hide checkboxes after successful deletion
    setSelectAll(false);
    toast.success("✅ Selected leads deleted successfully.", { autoClose: 2000 });
  } catch (error) {
    console.error("Error deleting leads:", error);
    toast.error("❌ Error deleting leads. Please try again.", { autoClose: 2000 });
  }
};

const handleSelectAllChange = () => {
  if (selectedLeads?.length === leadData?.length) {
    setSelectedLeads([]); 
  } else {
    setSelectedLeads(leadData.map((lead) => lead._id));
  }
  // setShowCheckboxes(true); // ✅ Ensure checkboxes remain visible
};





const handleCheckboxChange = (leadId) => {
  setSelectedLeads((prevSelected) => {
    let updatedSelection = Array.isArray(prevSelected) ? [...prevSelected] : [];

    if (updatedSelection.includes(leadId)) {
      updatedSelection = updatedSelection.filter((id) => id !== leadId);
    } else {
      updatedSelection.push(leadId);
    }

    console.log("Updated Selected Leads:", updatedSelection);
    return updatedSelection;
  });

  // ✅ Keep checkboxes visible even if a confirmed lead is selected
  setShowCheckboxes(true);
};





const columns = [
  ...(showCheckboxes
    ? [
        {
          header: (
            <input
              type="checkbox"
              checked={selectedLeads?.length > 0 && selectedLeads?.length === leadData?.length}
              onChange={handleSelectAllChange}
            />
          ),
          accessor: "checkbox",
          render: (row) => (
            <input
              type="checkbox"
              checked={Array.isArray(selectedLeads) && selectedLeads?.includes(row._id)}
              disabled={row.status === "Confirmed"} // ✅ Disable checkbox if status is "Confirmed"
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(row._id);
              }}
            />
          ),
        },
      ]
    : []),
  
  { header: "Name", accessor: "name", className: "cursor-pointer min-w-[120px]" },
  { header: "Email", accessor: "email", className: "min-w-[180px] hidden md:table-cell" },
  { header: "Phone", accessor: "phoneNo", className: "min-w-[120px]" },
  { header: "Work", accessor: "work", className: "min-w-[120px] hidden lg:table-cell" },
  { header: "Country", accessor: "country", className: "min-w-[100px] hidden md:table-cell" },
  {
    header: "Status",
    accessor: "status",
    className: "min-w-[120px] text-center",
    render: (row) => {
      const allStatuses = ["Pending", "Reached Out", "Confirmed"];
      const remainingStatuses = allStatuses.filter((s) => s !== row.status);
  
      return (
        <div className="relative">
          {row.status === "Confirmed" ? (
            <span className="py-1 px-2 rounded-md text-white bg-green-500">{row.status}</span>
          ) : (
            <>
              <button
                className={`py-1 px-2 rounded-md text-white ${
                  row.status === "Pending" ? "bg-yellow-500" : "bg-blue-500"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDropdown(openDropdown === row._id ? null : row._id);
                }}
              >
                {row.status}
              </button>
              {openDropdown === row._id && (
                <div
                  className="absolute left-0 right-0 bg-white shadow-lg border rounded mt-1 z-50 max-h-40 overflow-y-auto"
                  onClick={(e) => e.stopPropagation()}
                >
                  {remainingStatuses.map((status) => (
                    <button
                      key={status}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-100 text-gray-700"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStatusChange(row._id, status);
                        setOpenDropdown(null);
                      }}
                    >
                      {status}
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      );
    },
  },
  
  {
    header: "WhatsApp",
    accessor: "whatsapp",
    render: (row) => (
      <a
        href={`https://wa.me/${row.phoneNo}`} // ✅ Use 'row' instead of 'lead'
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className="text-green-500 text-xl" />
      </a>
    ),
  },
  {
    header: "Mail",
    accessor: "mail",
    render: (row) => (
      <a
        href={`https://mail.google.com/mail/?view=cm&fs=1&to=${row.email}`} // ✅ Use 'row' instead of 'lead'
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaEnvelope className="text-blue-500 text-xl" />
      </a>
    ),
  },
];


// Actions
  const actions = [
    { label: "🔄 Reload", onClick: handleReload, className: "md:hidden" },
    {
      label: "➕ Add Leads",
      primary: true,
      onClick: () => {
        setSelectedLeads(null);
        setIsAddModalOpen(true);
      },
      className: "md:hidden",
    },
    
    {
      label: "🗑 Delete Selected",
      onClick: handleDeleteSelected, // ✅ Updated function
      primary: true,
      

    },
  ];

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchQuery(value?.toLowerCase());
  };

  // Corrected filtering logic
  const filteredLeads = leadData?.filter((lead) =>
    lead.name?.toLowerCase().includes(searchQuery)
  );

 const startIndex = (currentPage - 1) * entriesPerPage;
  const LeadpaginatedData = filteredLeads.slice(startIndex, startIndex + entriesPerPage);




  return (
    <>
      <div className="p-4">
        <Table
          title="Leads"
          columns={columns}
          data={LeadpaginatedData}
          actions={actions}

          exportButton={true}
          importButton={true}
          onRowClick={(row) => handleRowClick(row._id)}
          onSearch={handleSearch}
          currentPage={currentPage}
          totalPages={Math.ceil(filteredLeads.length / entriesPerPage)}
          onPageChange={handlePageChange}
          totalEntries={filteredLeads.length}
          entriesPerPage={entriesPerPage}
          entriesOptions={[5, 10, 25, 50, 100, leadData?.length]}
          onEntriesChange={handleLeadEntriesChange}
          loading={loading}
        />
      </div>
      {isAddModalOpen && (
        <AddLeads
          closeAddModal={closeAddModal}
          setIsAddModalOpen={setIsAddModalOpen}
          addLeads={fetchLeads}
          initialLeadData={selectedLeads}
        />
      )}

       {isModalOpen && (
        <ViewLeads
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          openEditModal={openEditModal}
          setLeadData={setLeadData}
          setSelectedLeads={setSelectedLeads}
          leads={selectedLeads}
          leadData={leadData}
        />
      )} 
      {/* <Modal
        title="Confirm Status Update"
        visible={confirmationOpen}
        onCancel={() => setConfirmationOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setConfirmationOpen(false)}>
            Cancel
          </Button>,
          <Button key="ok" type="primary" onClick={updateStatusToReachedOut}>
            OK
          </Button>,
        ]}
      >
        <p>
          Are you sure you want to mark the selected leads as "Reached Out"?
        </p>
      </Modal> */}
    </>
  );
};

export default AllLeads;

// import React, { useEffect, useState } from "react";
// import Table from "../../components/Table";
// import axios from "axios";
// import AddLeads from "./AddLeads";
// import { Button, Modal } from "antd";
// import { toast } from "react-toastify";
// import {FaWhatsapp, FaEnvelope} from "react-icons/fa"
// import ViewLeads from "./ViewLeads";
// const AllLeads = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectionStatus, setSelectionStatus] = useState("Pending");
//   const [confirmCount,setConfirmCount]= useState(0)
//   const [leadData, setLeadData] = useState([]);
//   const [isAddModalOpen, setIsAddModalOpen] = useState(false);
//   const [filteredClientData, setFilteredClientData] = useState([]);
//   const [todayLeadsCount, setTodayLeadsCount] = useState(0);
//   const [reachedOutCount, setReachedOutCount] = useState(0);
//   const [showCheckboxes, setShowCheckboxes] = useState(false);
//   const [selectedLeads, setSelectedLeads] = useState([]);
//   const [selectAll,setSelectAll] = useState(false)
//   const [confirmationOpen, setConfirmationOpen] = useState(false);
//   const apiUrl = process.env.REACT_APP_API_URL;

//   console.log("leadData",leadData);

//   const fetchLeads = async () => {
//     try {
//       const response = await axios.get(`${apiUrl}/api/leads/get-leads`, {
//         withCredentials: true,
//       });
//       const allLeads = response.data.data;

//       setLeadData(allLeads);
//       setFilteredClientData(allLeads);

//     } catch (error) {
//       console.error("Error fetching leads:", error);
//     }
//   };

//   useEffect(() => {
//     fetchLeads();
//   }, [apiUrl]);

//   const handleRowClick = async (leadId) => {
//     if (showCheckboxes) {
//       handleCheckboxChange(leadId);
//       return;
//     }

//     try {
//       const response = await axios.get(`${apiUrl}/api/leads/get-leads-data/${leadId}`, {
//         withCredentials: true,
//       });

//       console.log("Lead data:", response.data); // Debugging

//       // ✅ Always ensure selectedLeads is an array
//       setSelectedLeads((prevSelected) =>
//         Array.isArray(prevSelected) ? [...prevSelected, response.data.data] : [response.data.data]
//       );

//       setIsModalOpen(true);
//     } catch (error) {
//       console.error("Error fetching lead data:", error);
//     }
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedLeads(null);
//   };

//   const openEditModal = () => {
//     setIsModalOpen(false);
//     setIsAddModalOpen(true);
//   };

//   const addNewClient = (newClient) => {
//     setLeadData((prevClients) => [...prevClients, newClient]);
//     setFilteredClientData((prevClients) => [...prevClients, newClient]);
//   };

//   const handleReload = () => {
//     fetchLeads();
//   };

//   const handleCheckboxChange = (leadId, status) => {
//     if (status === "Confirmed") {
//       alert("You cannot select a confirmed lead.");
//       return;
//     }
//     setSelectedLeads((prevSelected) =>
//       prevSelected.includes(leadId)
//         ? prevSelected.filter((id) => id !== leadId)
//         : [...prevSelected, leadId]
//     );
//   };

//   // const handleStatusUpdate = () => {
//   //   if (selectionStatus === "Pending") {
//   //     setSelectionStatus("Reached Out");
//   //     setShowCheckboxes(true);
//   //   } else if (selectionStatus === "Reached Out") {
//   //     updateStatusToReachedOut();

//   //   } else if (selectionStatus === "Confirmed") {
//   //     updateStatusToConfirmed();
//   //     setShowCheckboxes(false)

//   //   }
//   // };

// // Function to update status to "Reached Out"

// const handleStatusUpdate = () => {
//   // Prevent status update if any "Confirmed" lead is selected
//   const hasConfirmedLead = selectedLeads.some((id) => {
//     const lead = leadData.find((lead) => lead._id === id);
//     return lead && lead.status === "Confirmed";
//   });

//   if (hasConfirmedLead) {
//     alert("You have selected a Confirmed lead. Please deselect it before updating.");
//     return;
//   }

//   // Ensure checkboxes are always shown when moving to "Reached Out" state
//   if (selectionStatus === "Pending") {
//     setShowCheckboxes(true); // Make sure checkboxes are visible
//     setSelectionStatus("Reached Out");
//   } else if (selectionStatus === "Reached Out") {
//     setShowCheckboxes(true);  // Ensure checkboxes are visible
//     updateStatusToReachedOut();
//   } else if (selectionStatus === "Confirmed") {
//     updateStatusToConfirmed();
//     setShowCheckboxes(false);
//   }
// };

// const updateStatusToReachedOut = async () => {
//   try {
//     const response3 = await axios.put(
//       `${apiUrl}/api/leads/update-status`,
//       { leadIds: selectedLeads, status: "Reached Out" },
//       { withCredentials: true }
//     );
//     console.log("Status updated:", response3);

//     setLeadData((prevLeads) =>
//       prevLeads.map((lead) =>
//         selectedLeads?.includes(lead._id) ? { ...lead, status: "Reached Out" } : lead
//       )
//     );
//     setFilteredClientData((prevLeads) =>
//       prevLeads.map((lead) =>
//         selectedLeads?.includes(lead._id) ? { ...lead, status: "Reached Out" } : lead
//       )
//     );

//     setSelectionStatus("Confirmed"); // Move to "Confirm Selection" step
//     setShowCheckboxes(true); // Ensure checkboxes are still visible
//   } catch (error) {
//     console.error("Error updating status:", error);
//   }
// };

// // Function to update status to "Confirmed"
// const updateStatusToConfirmed = async () => {
//   try {
//     const response4 = await axios.put(
//       `${apiUrl}/api/leads/update-status`,
//       { leadIds: selectedLeads, status: "Confirmed" },
//       { withCredentials: true }
//     );
//     console.log("Status confirmed:", response4.data);

//     setLeadData((prevLeads) =>
//       prevLeads.map((lead) =>
//         selectedLeads?.includes(lead._id) ? { ...lead, status: "Confirmed" } : lead
//       )
//     );
//     setFilteredClientData((prevLeads) =>
//       prevLeads.map((lead) =>
//         selectedLeads?.includes(lead._id) ? { ...lead, status: "Confirmed" } : lead
//       )
//     );

//     setShowCheckboxes(false);
//     setSelectedLeads([]);
//     setSelectAll(false);
//   } catch (error) {
//     console.error("Error confirming status:", error);
//   }
// };

// const handleSelectAllChange = () => {
//   const pendingLeads = leadData.filter((lead) => lead.status !== "Confirmed");
//   if (pendingLeads.length === 0) {
//     alert("All leads are already Confirmed.");
//     return;
//   }

//   setSelectAll(!selectAll);
//   setSelectedLeads(selectAll ? [] : pendingLeads.map((lead) => lead._id));
// };
// //   const handleDeleteSelected = async () => {
// //     if (selectedLeads.length === 0) {
// //         setShowCheckboxes(true);
// //         return;
// //     }

// //     const confirmDelete = window.confirm(
// //         `Are you sure you want to delete ${selectedLeads.length} selected leads?`
// //     );
// //     if (!confirmDelete) return;

// //     try {
// //         const response = await axios.delete(`${apiUrl}/api/leads/delete-lead-data`, {
// //             data: { leadIds: selectedLeads }, // Correct way to send DELETE data
// //             withCredentials: true,
// //         });

// //         console.log("Deleted leads:", response.data);

// //         setLeadData((prevLeads) =>
// //             prevLeads.filter((lead) => !selectedLeads.includes(lead._id))
// //         );
// //         setFilteredClientData((prevLeads) =>
// //             prevLeads.filter((lead) => !selectedLeads.includes(lead._id))
// //         );

// //         setSelectedLeads([]);
// //         setShowCheckboxes(false);
// //         alert("Selected leads deleted successfully.");
// //     } catch (error) {
// //         console.error("Error deleting leads:", error);
// //     }
// // };

//     const handleDeleteSelected = async () => {
//     if (selectedLeads?.length === 0) {
//       setShowCheckboxes(true);
//       return;
//     }

//     // Filter out confirmed leads before deletion
//     const leadsToDelete = selectedLeads?.filter(
//       (leadId) =>
//         leadData.find((lead) => lead._id === leadId)?.status !== "Confirmed"
//     );

//     if (leadsToDelete?.length === 0) {
//       toast.warning("Confirmed leads cannot be deleted.", { autoClose: 1000 });
//       setShowCheckboxes(false);
//       return;
//     }

//     const confirmDelete = window.confirm(
//       `Are you sure you want to delete ${leadsToDelete?.length} selected leads?`
//     );
//     if (!confirmDelete) return;

//     try {
//       const response = await axios.delete(
//         `${apiUrl}/api/leads/delete-lead-data`,
//         {
//           data: { leadIds: leadsToDelete }, // Send only non-confirmed leads
//           withCredentials: true,
//         }
//       );

//       console.log("Deleted leads:", response.data);

//       setLeadData((prevLeads) =>
//         prevLeads?.filter((lead) => !leadsToDelete?.includes(lead._id))
//       );
//       // setFilteredClientData((prevLeads) =>
//       //     prevLeads.filter((lead) => !leadsToDelete.includes(lead._id))
//       // );

//       setSelectedLeads([]);
//       setShowCheckboxes(false);
//       setSelectAll(false);
//       toast.success("✅ Selected leads deleted successfully.", {
//         autoClose: 3000,
//       });
//     } catch (error) {
//       console.error("Error deleting leads:", error);
//       toast.error("❌ Error deleting leads. Please try again.", {
//         autoClose: 3000,
//       });
//     }
//   };

//   // Table Columns

//   const columns = [
//     ...(showCheckboxes ? [{
//       header: (
//         <input
//           type="checkbox"
//           checked={selectAll}
//           onChange={handleSelectAllChange}
//         />
//       ),
//       accessor: "checkbox",
//     }] : []),
//     { header: "Name", accessor: "name", className: "cursor-pointer" },
//     { header: "Email", accessor: "email" },
//     { header: "Phone", accessor: "phoneNo" },
//     { header: "Work", accessor: "work" },
//     { header: "Country", accessor: "country" },
//     { header: "Status", accessor: "status" },
//     { header: "WhatsApp", accessor: "whatsapp" },
//     { header: "Mail", accessor: "mail" },
//   ];

//   // Actions
// const actions = [
//   { label: "🔄 Reload", onClick: handleReload },
//   {
//     label: "➕ Add Leads",
//     primary: true,
//     onClick: () => {
//       setSelectedLeads(null);
//       setIsAddModalOpen(true);
//     },
//   },
//   {
//     label:
//       leadData.some((lead) => lead.status === "Pending")
//         ? "⏳ Pending"
//         : leadData.some((lead) => lead.status === "Reached Out")
//         ? "📩 Mark as Reached Out"
//         : "✅ Confirmed", // Default label when none are pending or reached out

//     primary: leadData.some((lead) => lead.status !== "Confirmed"), // Primary true if any lead is not confirmed

//     onClick: () => {
//       if (!leadData?.every((lead) => lead.status === "Confirmed")) {
//         handleStatusUpdate();
//       }
//     },

//     disabled: leadData?.every((lead) => lead.status === "Confirmed"), // Button is disabled if all leads are confirmed
//   },
//   {
//       label: "🗑 Delete Selected",
//       onClick: handleDeleteSelected,
//       primary: true,
//       disabled:
//         selectedLeads?.length > 0 &&
//         selectedLeads?.every(
//           (leadId) =>
//             leadData.find((lead) => lead._id === leadId)?.status === "Confirmed"
//         ),
//     },

// ];

//   const closeAddModal = () => {
//     setIsAddModalOpen(false);
//   };

//   const handleSearch = (searchValue) => {
//     const filteredData = leadData.filter(
//       (client) =>
//         client.name.toLowerCase().includes(searchValue.toLowerCase()) ||
//         client.email.toLowerCase().includes(searchValue.toLowerCase())
//     );
//     setFilteredClientData(filteredData);
//   };

//   return (
//     <>
//       <div className="p-4">
//         {/* Lead Statistics */}

//         <Table
//   title="Leads"
//   columns={columns}
//   data={filteredClientData.map((lead) => ({
//     ...lead,
//     checkbox: showCheckboxes ? (
//       <input
//         type="checkbox"
//         checked={selectedLeads?.includes(lead._id)}
//         onChange={() => {
//           if (lead.status === "Confirmed") {
//             alert("You cannot select a confirmed lead.");
//             return;
//           }
//           handleCheckboxChange(lead._id, lead.status);
//         }}
//         disabled={lead.status === "Confirmed"} // Disable checkbox for confirmed leads
//       />
//     ) : null,

//     whatsapp: (
//       <a
//         href={`https://wa.me/${lead.phoneNo}`}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <FaWhatsapp className="text-green-500 text-xl" />
//       </a>
//     ),

//     mail: (
//       <a
//         href={`https://mail.google.com/mail/?view=cm&fs=1&to=${lead.email}`}
//         target="_blank"
//         rel="noopener noreferrer"
//       >
//         <FaEnvelope className="text-blue-500 text-xl" />
//       </a>
//     ),

//           }))}
//           actions={actions}
//           exportButton={true}
//           importButton={true}
//           onRowClick={(row) => {
//             console.log("Row clicked! Lead ID:", row._id);
//             handleRowClick(row._id);
//           }}
//           onSearch={handleSearch}
//           entriesOptions={[5, 10, 25, 50, 100, leadData.length]}
//           onEntriesChange={(value) => console.log("Entries:", value)}
//         />
//       </div>
//       {isAddModalOpen && (
//         <AddLeads closeAddModal={closeAddModal} setIsAddModalOpen={setIsAddModalOpen} addLeads={fetchLeads} initialLeadData={selectedLeads} />
//       )}

//       {/* {isModalOpen && (
//         <ViewLeads
//           closeModal={closeModal}
//           isModalOpen={isModalOpen}
//           openEditModal={openEditModal}
//           setLeadData={setLeadData}
//           setSelectedLeads={setSelectedLeads}
//           leads={selectedLeads}
//           leadData={leadData}
//         />
//       )} */}
//       <Modal
//         title="Confirm Status Update"
//         visible={confirmationOpen}
//         onCancel={() => setConfirmationOpen(false)}
//         footer={[
//           <Button key="cancel" onClick={() => setConfirmationOpen(false)}>
//             Cancel
//           </Button>,
//           <Button key="ok" type="primary" onClick={updateStatusToReachedOut}>
//             OK
//           </Button>,
//         ]}
//       >
//         <p>Are you sure you want to mark the selected leads as "Reached Out"?</p>
//       </Modal>
//     </>
//   );
// };

// export default AllLeads;
