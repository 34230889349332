import React, { useRef, useState } from 'react';
import logo from "../Images/logo.png";
import { NavLink } from 'react-router-dom';
import useOutsideClick from '../Hooks/useClickOutside';
import axios from "axios";
import { ProfileTaskIcon } from '../utills/Svgs';
import { toast } from 'react-toastify';
import { useRole } from '../context/RoleContext';
import NotificationsModal from './NotificationModal';
import { useLoggedInUser } from '../context/LoggedUser';
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim"


const Header = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const { role, setRole } = useRole();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { loggedInUser } = useLoggedInUser();

    useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));
    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };
    const HandleLogout = async () => {
        try {
            const response = await axios.post(`${apiUrl}/api/auth/logout`, {}, { withCredentials: true });
            // console.log(response.data);
            toast.success(response.data.message);
            if (response.data.success) {
                toast.success(response.data.message);
                localStorage.removeItem("token");
                setRole(null);
                window.location.href = "/";
            }
        } catch (err) {
            // console.log(err);
            if (err.response.status === 401) {
                toast.warn("Unauthorized");
            }
        }
    }
    const CamelCase = (str) => {
        return str
            .replace(/([A-Z])/g, " $1")
            .replace(/^./, function (str) {
                return str.toUpperCase();
            });
    }

    const particleOptions = {
        background: {
            color: "transparent",
        },
        particles: {
            number: {
                value: 250,
                density: {
                    enable: true,
                    value_area: 800,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: 0.6,
                random: true,
                anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0.1,
                },
            },
            size: {
                value: 3,
                random: true,
                anim: {
                    enable: true,
                    speed: 5,
                    size_min: 0.3,
                },
            },
            line_linked: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 2,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "grab",
                },
                onClick: {
                    enable: false,
                    mode: "push",
                },
            },
            modes: {
                repulse: {
                    distance: 100,
                    duration: 0.4,
                },
                push: {
                    particles_nb: 4,
                },
            },
        },
        retina_detect: true,
    };
    const particlesInit = async (engine) => {
        await loadSlim(engine);
    };
    return (
        <>
            <header className="fixed top-0 left-0 w-full bg-white shadow-md p-4 flex items-center justify-between z-20">
                {/* Logo and Title */}
                <div className="flex items-center space-x-4 flex-wrap" onClick={() => window.location.href = `/${role}`}>
                    <img src={logo} alt="Logo" className="h-10 px-2" />
                    <div>
                        <h1 className="text-lg font-semibold text-gray-800 sm:text-base">{loggedInUser?.name}</h1>
                        <p className="text-sm text-gray-500 w-full truncate hidden md:block">
                            {CamelCase(role)}
                        </p>
                    </div>
                </div>

                {/* Notifications and Profile */}
                <div className="flex items-center space-x-4 sm:space-x-2">
                    {/* Notifications */}
                    <div className="relative">
                        <NotificationsModal />
                        {/* <button className="relative p-2 rounded-full hover:bg-gray-100">

                            <span className="absolute top-0 right-0 bg-blue-500 text-white text-xs font-bold rounded-full px-1">
                                3969
                            </span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-gray-600 sm:h-5 sm:w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6 6 0 10-12 0v3.159c0 .538-.214 1.055-.595 1.436L4 17h11z"
                                />
                            </svg>
                        </button> */}
                    </div>

                    {/* Profile Dropdown */}
                    <div className="relative">
                        <button
                            className="flex items-center space-x-2 p-2 rounded-full bg-gray-100 hover:bg-gray-200"
                            onClick={toggleDropdown}
                        >
                            <span className="flex items-center justify-center w-8 h-8 bg-green-500 text-white font-bold rounded-full sm:w-6 sm:h-6">
                                {(loggedInUser?.avatar) ? loggedInUser?.avatar : loggedInUser?.name.charAt(0)}
                            </span>
                            <span className="hidden md:block text-gray-600 text-sm sm:text-xs">
                                Hello, Mate
                            </span>
                        </button>

                        {isDropdownOpen && (<>

                            <div
                                ref={dropdownRef}
                                className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200"
                            >
                                {/* Header Section with Animated Gradient Background */}
                                <div className="relative p-4 bg-gradient-to-r from-[#8d9df8] to-[#4462fa] animate-gradient-x border-b border-gray-200 overflow-hidden">
                                    <Particles
                                        id="tsparticles"
                                        init={particlesInit}
                                        options={{
                                            ...particleOptions,
                                            detectRetina: true,
                                            fullScreen: { enable: false }, // Prevent full-page rendering
                                            background: { opacity: 0 }, // Ensure transparency
                                            particles: {
                                                ...particleOptions.particles,
                                                move: {
                                                    ...particleOptions.particles?.move,
                                                    outModes: { default: "bounce" }, // Keep particles inside
                                                },
                                            },
                                        }}
                                        className="absolute inset-0 w-full h-full"
                                    />
                                    <h3 className="relative text-sm font-semibold text-white truncate">
                                        {loggedInUser?.email}
                                    </h3>
                                </div>



                                {/* Menu Items */}
                                <div className="p-4 space-y-2">
                                    {/* <NavLink
                                        to="#profile"
                                        className="flex items-center text-gray-700 hover:text-blue-600 hover:bg-blue-50 px-3 py-2 rounded-md text-sm transition"
                                    >
                                        <ProfileIcon className="mr-2 w-5 h-5" />
                                        <p className="pl-2">My Profile</p>
                                    </NavLink> */}
                                    <NavLink
                                        to="tasks/all"
                                        className="flex items-center text-gray-700 hover:text-blue-600 hover:bg-blue-50 px-3 py-2 rounded-md text-sm transition"
                                    >
                                        <ProfileTaskIcon className="mr-2 w-5 h-5" />
                                        <p className="pl-2">My Tasks</p>
                                    </NavLink>
                                </div>

                                {/* Sign Out Section */}
                                <div className="border-t border-gray-200 p-4">
                                    <button
                                        onClick={HandleLogout}
                                        className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 text-sm transition"
                                    >
                                        Sign Out
                                    </button>
                                </div>
                            </div>
                        </>
                        )}

                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
