import React, { useState, useRef, useEffect } from "react";
import { FaUpload, FaSearch, FaCog } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { IoReload } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import Loading from "../../components/Loading";

const PrivateSpace = () => {
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filterData, setfilterData] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
  const hasFetched = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 4;

  // Calculate indexes for slicing
  const startIndex = (currentPage - 1) * filesPerPage;
  const endIndex = startIndex + filesPerPage;
  const currentFiles = filterData.slice(startIndex, endIndex);

  // Total number of pages
  const totalPages = Math.ceil(filterData.length / filesPerPage);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (files.length + selectedFiles.length > 5) {
      toast.warn("You can only upload up to 5 files.");
      return;
    }

    const validFiles = selectedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    const validFiles = droppedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.warn("No files selected.");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => formData.append("files", file));

    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/api/privateSpace/add-privatefile`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
          withCredentials: true,
        }
      );

      setLoading(false);
      setFiles([]);
      toast.success("Files uploaded successfully!");
      setFiles([]);
    } catch (error) {
      setLoading(false);
      toast.warn("Failed to upload files. Please try again.");
    }
  };

  const getPrivateFiles = async () => {
    try {
      setLoading(true);
      if (hasFetched.current) return;
      hasFetched.current = true;

      const response = await axios.get(
        `${apiUrl}/api/privateSpace/get-privatefile`,
        {
          withCredentials: true,
        }
      );

      setUploadedFiles(response.data.data);
    } catch (error) {
      console.error(
        "Error fetching private files:",
        error.response?.data || error.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivateFiles();
  }, [apiUrl]);

  const handleReload = () => {
    hasFetched.current = false;
    getPrivateFiles();
  };

  useEffect(() => {
    if (!searchQuery) {
      setfilterData(uploadedFiles);
    } else {
      setfilterData(
        uploadedFiles.filter((item) =>
          item.fileName.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, uploadedFiles]);

  return (
    <div className="flex flex-col h-screen bg-gray-100 p-6">
      <main className="grid grid-cols-1 lg:grid-cols-5 gap-6">
        {/* Left Section - Uploaded Files */}
        <div className="lg:col-span-3 col-span-1 h-[32rem] bg-white shadow-lg rounded-lg p-6 overflow-y-auto">
          <div className="flex items-center justify-between border-b pb-3">
            <h2 className="text-sm lg:text-xl font-semibold text-gray-800">
              Display Files
            </h2>
            <div className="flex items-center gap-3">
              {/* Search Bar */}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search files..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-24 lg:w-64 px-3 py-2 pr-10 border rounded-lg focus:outline-none focus:border-blue-500 text-xs lg:text-lg"
                />
                <FaSearch className="absolute w-3 h-3 lg:w-5 lg:h-5 text-gray-400 right-3 lg:top-2.5 top-3" />
              </div>

              {/* Reload Icon */}
              <div className="relative">
                <IoReload
                  className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700"
                  onClick={() => handleReload()}
                />
              </div>
            </div>
          </div>

          {/* File List */}
          <div className="px-4 pb-4 pt-4 space-y-3">
            {currentFiles.map((file) => (
              <div
                key={file._id}
                className="lg:p-3 p-1 transition-colors border rounded cursor-pointer hover:bg-gray-50"
              >
                <div className="flex flex-row justify-between items-center space-y-2">
                  <div className="flex flex-col space-y-1">
                    <h3
                      className="font-medium text-xs md:text-lg"
                      onClick={() => window.open(file.fileUrl, "_blank")}
                    >
                      {file.fileName}
                    </h3>
                    <div className="flex gap-3 justify-around">
                      <p className="text-sm text-gray-500">
                        {/* [{task.taskId}] */}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2 ">
                    <span className="md:px-3 px-1 md:py-2 py-1 md:text-sm text-xs text-green-700 bg-green-100 rounded-md">
                      {new Date(file.timestamp).toLocaleDateString()}
                    </span>
                    <span>
                      <IoMdDownload
                        className="w-5 h-5 mt-1 text-blue-700"
                        onClick={() => window.open(file.fileUrl)}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {loading && (
            <div>
              <Loading />
            </div>
          )}
          {totalPages > 1 && (
            <div className="flex justify-center items-center gap-4 mt-4">
              <button
                className={`px-3 py-1 border rounded ${
                  currentPage === 1
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-100"
                }`}
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span className="text-sm font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`px-3 py-1 border rounded ${
                  currentPage === totalPages
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-100"
                }`}
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          )}
        </div>

        {/* Right Section - Upload Area */}
        <div className="lg:col-span-2 col-span-1 h-[32rem] bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
          <div>
            <h2 className="text-sm lg:text-xl font-semibold text-gray-800 mb-4">
              Upload Files
            </h2>

            {/* Drag & Drop Area */}
            <div
              className={`p-6 w-full h-72 flex flex-col items-center justify-center border-2 border-dashed rounded-lg text-gray-600 transition-all ${
                dragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
              }`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <label
                htmlFor="browseFileInput"
                className="cursor-pointer flex flex-col items-center"
              >
                <FaUpload className="h-10 w-10 text-blue-600" />
                <p className="mt-2 text-sm">Drag & Drop files here</p>
              </label>
              <input
                type="file"
                id="browseFileInput"
                className="hidden"
                onChange={handleFileChange}
                multiple
              />
            </div>

            {/* File Selection Section */}
            <div className="mt-4 flex items-center">
              <input
                type="text"
                value={
                  files.length > 0
                    ? files.map((file) => file.name).join(", ")
                    : "No files selected"
                }
                readOnly
                className="border border-gray-300 rounded-l-lg p-2 w-full text-gray-600 text-sm truncate"
              />
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
                multiple
              />
              <label
                htmlFor="fileInput"
                className="cursor-pointer px-4 py-2 bg-blue-600 text-white rounded-r-lg hover:bg-blue-700 transition"
              >
                Browse
              </label>
            </div>

            {/* File Upload Info */}
            <p className="text-gray-500 text-sm mt-2 text-center">
              Max file size: 50MB per file | Max 5 files at a time
            </p>
          </div>

          {/* Submit Button - Moved inside the Right Section */}
          <button
            onClick={handleSubmit}
            className="mt-3 w-full py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
          >
            {loading ? "Uploading..." : "Submit"}
          </button>
        </div>
      </main>
    </div>
  );
};

export default PrivateSpace;
