import axios from 'axios';

const setupAxiosInterceptors = () => {
    const token = localStorage.getItem('token');
    // console.log('token', token);
    axios.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if ((error.response && error.response.status === 401) || token === null) {
                alert('Session expired, please log in again.');
                localStorage.removeItem('token');

                localStorage.setItem('token', null);

                window.location.href = '/';
            }

            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;
