//not a tree (old design)

// import { useEffect, useState } from "react";
// import axios from "axios";

// const TeamHierarchy = () => {
//   const [team, setTeam] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const apiUrl = process.env.REACT_APP_API_URL;
//     const fetchTeam = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/api/team/get-team-details`, {
//           withCredentials: true,
//         });
//         setTeam(response.data.team);
//       } catch (err) {
//         setError("Failed to load team data.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchTeam();
//   }, []);

//   const [expanded, setExpanded] = useState({});

//   const toggleExpand = (id) => {
//     setExpanded((prev) => ({
//       ...prev,
//       [id]: !prev[id],
//     }));
//   };

//   const renderTeam = (employees, level = 0) => {
//     return (
//       <ul className="ml-4 border-l-2 border-gray-300 pl-4">
//         {employees.map((employee) => (
//           <li key={employee._id} className="relative mb-2">
//               {employee.subordinates.length > 0 && (
//                 <button onClick={() => toggleExpand(employee._id)} className="p-1 absolute -left-4 mt-4">
//                   {expanded[employee._id] ? "❌":"➕"}
//                 </button>
//               )}
//             <div className="flex items-center ml-4 gap-2 p-2 bg-white shadow rounded-md border border-gray-300 text-sm w-64">
//               <div>
//                 <p className="font-semibold text-gray-800">{employee.name}</p>
//                 <p className="text-gray-600 text-xs">{employee.email}</p>
//                 <p className="text-gray-500 text-xs">{employee.role_id}</p>
//               </div>
//             </div>
//             {expanded[employee._id] && employee.subordinates.length > 0 && (
//               <div className="ml-6 mt-2">{renderTeam(employee.subordinates, level + 1)}</div>
//             )}
//           </li>
//         ))}
//       </ul>
//     );
//   };

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <h1 className="text-2xl font-bold text-gray-800 mb-4">Team Hierarchy</h1>
//       {loading && <p className="text-center text-gray-500">Loading...</p>}
//       {error && <p className="text-center text-red-500">{error}</p>}
//       {!loading && !error && renderTeam(team)}
//     </div>
//   );
// };

// export default TeamHierarchy;










//tree

// import { useEffect, useState } from "react";
// import axios from "axios";

// const TeamHierarchy = () => {
//   const [team, setTeam] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [expanded, setExpanded] = useState({});

//   useEffect(() => {
//     const apiUrl = process.env.REACT_APP_API_URL;
//     const fetchTeam = async () => {
//       try {
//         const response = await axios.get(`${apiUrl}/api/team/get-team`, {
//           withCredentials: true,
//         });
//         // console.log("team", response.data.team);
//         setTeam(response.data.team);
//       } catch (err) {
//         setError("Failed to load team data.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchTeam();
//   }, []);

//   const toggleExpand = (id) => {
//     setExpanded((prev) => ({
//       ...prev,
//       [id]: !prev[id],
//     }));
//   };

//   const renderTreeNode = (employee) => {
//     const hasImmediateChildren = employee.subordinates && employee.subordinates.length > 0;

//     return (
//       <div key={employee._id} className="relative flex flex-col items-center mb-8">
//         <div
//           className="relative flex flex-col items-center p-4 rounded-lg bg-white shadow-md border border-gray-300 cursor-pointer"
//           onClick={() => toggleExpand(employee._id)}
//         >
//           <p className="font-semibold text-gray-800">{employee.first_name} {employee.last_name}</p>
//           <p className="text-gray-600 text-sm">{employee.email}</p>
//           <p className="text-gray-500 text-xs">{employee.role_id}</p>
//           {hasImmediateChildren && (
//             <button
//               className="absolute -bottom-4 left-1/2 transform -translate-x-1/2 bg-indigo-500 text-white w-6 h-6 rounded-full flex items-center justify-center focus:outline-none"
//             >
//               {expanded[employee._id] ? "-" : "+"}
//             </button>
//           )}
//         </div>

//         {expanded[employee._id] && hasImmediateChildren && (
//           <div className="flex flex-wrap justify-center mt-6 border-t-2 border-gray-300 pt-4">
//             {employee.subordinates.map((subordinate) => renderTreeNode(subordinate))}
//           </div>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div className="p-6 bg-gray-100 min-h-screen">
//       <h1 className="text-2xl font-bold text-gray-800 mb-4">Team Hierarchy</h1>
//       {loading && <p className="text-center text-gray-500">Loading...</p>}
//       {error && <p className="text-center text-red-500">{error}</p>}
//       {!loading && !error && team.map((employee) => renderTreeNode(employee))}
//     </div>
//   );
// };

// export default TeamHierarchy;

//team enhanced

import { useEffect, useState } from "react";
import axios from "axios";
import { Tree, TreeNode } from "react-organizational-chart";
import Loading from "../../components/Loading";

const EmployeeCard = ({ employee, onClick, level }) => {
  const colorLevels = ["#E3F2FD", "#FCE4EC", "#F1F8E9", "#FFF3E0", "#EDE7F6"];
  const bcolorLevels = ["#90CAF9 ", "#F48FB1 ", "#C5E1A5 ", "#FFCC80 ", "#B39DDB "];
  const bgColor = colorLevels[level % colorLevels.length];
  const bordercolorLevels = bcolorLevels[level % bcolorLevels.length];

  return (
    <div className="flex flex-col items-center cursor-pointer transition-transform hover:scale-105">
      <div
        className="relative w-full max-w-xs rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-shadow"
        style={{ backgroundColor: bgColor }}
      >
        {/* Card Content */}
        <div
          className="relative backdrop-blur-lg border-l-8 border-t-8 rounded-xl p-4 flex"
          style={{ borderColor: bordercolorLevels }}
        >
          {/* Profile Image */}
          <div className="flex justify-center pb-3">
            <div className="w-16 h-16 rounded-full flex items-center justify-center text-lg font-semibold text-gray-700 bg-gradient-to-br from-gray-200 to-gray-300 shadow-md">
              {employee.first_name[0]}{employee.last_name[0]}
            </div>
          </div>

          {/* Employee Details */}
          <div className="text-center">
            <div className="font-semibold text-gray-900 text-lg">
              {`${employee.first_name} ${employee.last_name}`}
            </div>
            <div className="text-sm text-gray-600 font-medium">{employee.role}</div>
            <div className="text-xs text-gray-500 italic">{employee.email}</div>
          </div>
        </div>
      </div>

      {/* Expand Button */}
      {employee.subordinates && employee.subordinates.length > 0 && (
        <button
          onClick={onClick}
          className="mt-3 bg-indigo-600 text-white w-8 h-8 rounded-full flex items-center justify-center hover:bg-indigo-700 shadow-md transition-all"
        >
          {employee.expanded ? "−" : "+"}
        </button>
      )}
    </div>
  );
};




const TeamHierarchy = () => {
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchTeam = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/team/get-team-details`, {
          withCredentials: true,
        });
        
        const processTeam = (employees) => {
          return employees.map(emp => ({
            ...emp,
            expanded: false,
            subordinates: emp.subordinates ? processTeam(emp.subordinates) : []
          }));
        };
        
        setTeam(processTeam(response.data.team));
      } catch (err) {
        setError("Failed to load team data.");
      } finally {
        setLoading(false);
      }
    };
    fetchTeam();
  }, []);
  
  const toggleExpand = (employeeId) => {
    const updateExpandedState = (employees) => {
      return employees.map(emp => {
        if (emp._id === employeeId) {
          return { ...emp, expanded: !emp.expanded };
        }
        if (emp.subordinates && emp.subordinates.length > 0) {
          return { ...emp, subordinates: updateExpandedState(emp.subordinates) };
        }
        return emp;
      });
    };
    setTeam(updateExpandedState(team));
  };

  const renderTreeNode = (employee, level = 0) => {
    const isFirstLevel = level === 1; // Check if it's Manager or HR level
    const isTeamLead = employee.role === "TL"; // Check if the employee is a Team Lead
  
    return (
      <div
        key={employee._id}
        className={`${
          isFirstLevel || isTeamLead ? "flex flex-row gap-6 justify-center" : "flex flex-col items-center"
        }`}
      >
        <TreeNode
          label={<EmployeeCard employee={employee} onClick={() => toggleExpand(employee._id)} level={level} />}
        >
          {employee.expanded && (
            <div className={`${isTeamLead ? "flex flex-row gap-4 justify-center" : "flex flex-col items-center gap-4"}`}>
              {employee.subordinates.map((sub) => renderTreeNode(sub, level + 1))}
            </div>
          )}
        </TreeNode>
      </div>
    );
  };
  
  
  return (
    <div className="p-6 bg-gray-100 min-h-screen w-full">
      <h1 className="text-2xl font-bold text-gray-800 mb-6 text-start">Team Hierarchy</h1>
  
      {loading && <Loading/>}
      {error && <p className="text-center text-red-500">{error}</p>}
  
      {!loading && !error && team.length > 0 && (
        <div
          className="w-full overflow-x-auto"
          style={{
            overflowX: "auto",
            scrollbarWidth: "thin", // Firefox
            msOverflowStyle: "auto", // IE/Edge
            whiteSpace: "nowrap",
          }}
        >
          {/* Inner wrapper for centering & allowing left visibility */}
          <div
            className="w-full flex justify-start items-center overflow-x-auto"
            style={{
              minWidth: "max-content", // Ensures content is not squished
              overflowX: "auto",
              scrollbarWidth: "thin",
              msOverflowStyle: "auto",
              whiteSpace: "nowrap",
              paddingLeft: "20px", // Reduce fixed left padding
            }}
          >
            <Tree
              lineWidth={"2px"}
              lineColor={"#ccc"}
              lineBorderRadius={"8px"}
              label={<EmployeeCard employee={team[0]} onClick={() => toggleExpand(team[0]._id)} />}
            >
              {team[0].expanded && (
                <div className="flex flex-row gap-6 justify-start overflow-x-auto">
                  {team[0].subordinates.map((emp) => renderTreeNode(emp, 1))}
                </div>
              )}
            </Tree>
          </div>
        </div>
      )}
    </div>
  );
  
};

export default TeamHierarchy;



