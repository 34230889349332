import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { FaPencilAlt, FaPiggyBank, FaThumbsUp, FaUser, FaUserAltSlash, FaEye, FaEyeSlash } from 'react-icons/fa';
import { TbRotateClockwise2 } from 'react-icons/tb';
import { TiSortAlphabetically } from 'react-icons/ti';
import axios from 'axios';
import { useRole } from '../../context/RoleContext';
import { useNotification } from '../../context/NotificationContext'
import { useLoggedInUser } from '../../context/LoggedUser'
import useOutsideClick from '../../Hooks/useClickOutside';
import { toast } from 'react-toastify';
import { useSocket } from '../../context/SocketContext'
import { useNavigate } from 'react-router-dom';

const ViewClients = ({
  isModalOpen,
  client,
  closeModal,
  openEditModal, refreshClients
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const closeRef = useRef(null);
  const assignModalRef = useRef(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [bdms, setBdms] = useState([]);
  const [clientStats, setClientStats] = useState([]);
  const [selectedBdm, setSelectedBdm] = useState("");
  const [assignedUserName, setAssignedUserName] = useState("");
  const [createdbyName, setCreatedbyName] = useState("");
  const [updatedbyName, setUpdatedbyName] = useState("");
  const { socket } = useSocket()
  const [showPassword, setShowPassword] = useState(false);
  useOutsideClick(closeRef, () => closeModal(false));
  useOutsideClick(assignModalRef, () => setIsAssignModalOpen(false));

  // console.log("socket",socket)
  const navigate = useNavigate();
  const { role } = useRole();
  const {notification, createNotification} = useNotification()
  const { loggedInUser } = useLoggedInUser()

  // console.log("loggedInUser", loggedInUser)
  const handleDeActivate = async (clientId) => {

    try {
      await axios.put(
        `${apiUrl}/api/clients/deactivate-client-data/${clientId}`,
        {}, 
        {
          withCredentials: true // Keep withCredentials here in the config
        }
      );
      closeModal();
      toast.success('Client Deactivated Successfully');
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };



  // Fetch Client Data by ID
  const fetchClientById = async (userID) => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchUser/${userID}`, {
        withCredentials: true,
      });
      return response.data.name;
    } catch (error) {
      console.error("Error fetching client data:", error);
      return "Unknown"; // Fallback if the fetch fails
    }
  };

  const fetchClientStats = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/clients/get-client-stats/${client?._id}`, {
        withCredentials: true,
      });
      setClientStats(response.data.data);
    } catch (error) {
      console.error("Error fetching client stats:", error);
    }
  };
  useEffect(() => {
    if (client?.assigned_to) {
      fetchClientById(client.assigned_to).then((name) => setAssignedUserName(name));
    }
    if (client?.created_by) {
      fetchClientById(client.created_by).then((name) => setCreatedbyName(name));
    }
    if (client?.updated_by) {
      fetchClientById(client.updated_by).then((name) => setUpdatedbyName(name));
    }
    fetchClientStats();
  }, [client]);


  useEffect(() => {
    const fetchBDMs = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/fetchbdm`, { withCredentials: true });
        setBdms(response.data);
      } catch (error) {
        console.error("Error fetching BDMs:", error);
      }
    };
    fetchBDMs();
  }, []);

  const handleAssign = async () => {
    try {
     const response = await axios.put(`${apiUrl}/api/clients/update-client-data/${client._id}`, { assigned_to: selectedBdm}, { withCredentials: true });
     // console.log("response",response);
      
     if(response.status === 200){
      socket.emit("assign-client", {
        to: response.data.data.assigned_to,
        message: "New Client assigned",
        date: new Date(),
        notDesc: response.data.data.name
      })

      createNotification({
        sender: loggedInUser._id,
        receiver: response.data.data.assigned_to,
        message: "client assigned",
        notDesc: response.data.data.name
      });
     }
      
      toast.success('Client assigned successfully');
      closeModal();
      refreshClients();
    } catch (error) {
      console.error('Error assigning client:', error);
    }
  };

  const viewTasks = async (link) => {
    try {
      sessionStorage.setItem('clientId', client._id);
      navigate(link);
    } catch (error) {
      console.error('Error assigning client:', error);
    }
  }
  return (
    <>
      <div
        className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-6 transition-opacity duration-300 ease-in-out ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      >
        <div
          className={`bg-white rounded-2xl shadow-xl max-h-[70vh] w-full sm:max-w-md md:max-w-2xl lg:max-w-3xl transform transition-transform duration-500 ease-in-out  overflow-x-auto ${isModalOpen ? 'translate-y-0' : 'translate-y-full'} scrollbar-thin`}
        >
          {/* Header Section */}
          <div className="flex justify-between items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 sm:px-6 py-3 sm:py-4">
            <h2 className="text-lg font-semibold">Client Details</h2>
            <button
              onClick={closeModal}
              className="text-white hover:text-gray-300 focus:outline-none"
            >
              <RxCross2 className="h-6 w-6" />
            </button>
          </div>
          {/* Client Information */}
          <div className="p-6 space-y-6">
            {/* Client Profile */}
            <div className="flex items-start justify-between flex-wrap">
              <div className="flex items-start space-x-4">
                <div className="h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center">
                  <span className="text-blue-600 text-xl font-semibold uppercase">
                    {client?.avatar || client.name?.[0] || "C"}
                  </span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold text-gray-900">{client.name}</h3>
                  <p className="text-gray-500 text-sm">{client.email}</p>
                  <p className="text-gray-400 text-xs">ID: {client.clientId}</p>
                </div>
              </div>

              {/* Buttons (Shifted to the End) */}
              <div className="flex space-x-2 ml-auto">
                {/* Edit Button */}
                <div className="relative group">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-2 rounded-full flex items-center"
                    onClick={openEditModal}
                    title="Edit"
                  >
                    <FaPencilAlt className="w-4 h-4" />
                  </button>
                  <span className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs font-medium rounded px-2 py-1 transition-opacity duration-300">
                    Edit
                  </span>
                </div>

                {/* Deactivate Button */}
                {client.status === "Active" && <div className="relative group">
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-2 rounded-full flex items-center"
                    onClick={() => handleDeActivate(client._id)}
                    title="Deactivate"
                  >
                    <FaUserAltSlash className="w-4 h-4" />
                  </button>
                  <span className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs font-medium rounded px-2 py-1 transition-opacity duration-300">
                    Deactivate
                  </span>
                </div>}

                {/* Assign Button */}
                <div className="relative group">
                  <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-2 rounded-full" onClick={() => setIsAssignModalOpen(true)}>
                    <FaUser className="w-4 h-4" />
                  </button>
                  <span className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs font-medium rounded px-2 py-1 transition-opacity duration-300">
                    Assign
                  </span>
                </div>
              </div>

            </div>

            {/* Info Grid */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2">
              {[
                { label: 'Status', value: client.status, bgColor: `bg-${client.status === 'Active' ? 'green' : 'red'}-100`, textColor: `text-${client.status === 'Active' ? 'green' : 'red'}-600` },
                { label: 'Assigned To', value: assignedUserName || '...', bgColor: 'bg-yellow-100', textColor: 'text-yellow-600' },
                { label: 'Phone', value: client.phone, bgColor: 'bg-pink-100', textColor: 'text-pink-600' },
                { label: 'Country', value: client.country },
                { label: 'Address', value: client.address },
                { label: 'University', value: client.university },
                { label: 'Created By', value: createdbyName || '...' },
                { label: 'Updated By', value: updatedbyName || '...' },
                { label: 'Created At', value: new Date(client.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }) },
                { label: 'Updated At', value: new Date(client.updatedAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }) },
              ].map(({ label, value, bgColor = '', textColor = '' }, index) => (
                <div key={index}>
                  <p className="text-gray-500 text-sm mb-1">{label}</p>
                  <span className={`px-4 py-2 ${bgColor} ${textColor} rounded-full text-sm`}>{value}</span>
                </div>
              ))}

              {/* Preview Password Field */}
              <div>
                <p className="text-gray-500 text-sm mb-1">Preview Password</p>
                <div className="relative gap-2">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={client.previewPassword}
                    readOnly
                    className="pl-4 w-1/2"
                  />
                  <button
                    type="button"
                    className="pl-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                  </button>
                </div>
              </div>

              {/* Organization field for superadmin */}
              {role === "superadmin" && (
                <div key="organization">
                  <p className="text-gray-500 text-sm mb-1">Organization</p>
                  <span className="px-4 py-2 rounded-full text-sm">{client.org_id}</span>
                </div>
              )}
            </div>

            {/* Stats */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2">
              {[
                { label: 'Revenue', value: clientStats.totalRevenue, Icon: FaPiggyBank },
                {
                  label: 'Total Tasks',
                  value: clientStats.totalTasks,
                  Icon: TbRotateClockwise2,
                  link: `/${role}/task-report`, // Add a link for the Total Tasks item
                },
                { label: 'Total Paid', value: clientStats.totalAmountPaid, Icon: FaThumbsUp },
                { label: 'Word Count', value: clientStats.totalWordCount, Icon: TiSortAlphabetically },
              ].map(({ label, value, Icon, link }, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <Icon className="w-5 h-5 text-gray-400" />
                  <div>
                    <p className="text-gray-500 text-sm">{label}</p>
                    <p className="font-medium text-gray-800">
                      {value}
                      {link && value > 0 && (
                        <span
                          onClick={() => viewTasks(link)}
                          className="text-blue-500 text-sm underline ml-4 hover:text-blue-700"
                        >
                          View Tasks
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
      {/* Assign Modal */}
      {isAssignModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-6">
          <div className="bg-white rounded-2xl shadow-xl p-6 w-full max-w-md">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Assign Client</h2>
            <select
              id='assigned_to'
              name="assigned_to"
              className="w-full p-2 border rounded"
              value={selectedBdm}
              onChange={(e) => setSelectedBdm(e.target.value)}
            >
              <option value="" disabled>
                Select Assigned BDM
              </option>
              {bdms.map((bdm) => (
                <option key={bdm._id} value={bdm._id}>
                  {bdm.name || `${bdm.first_name} ${bdm.last_name}`}
                </option>
              ))}
            </select>
            <div className="flex justify-end mt-4 space-x-2">
              <button onClick={() => setIsAssignModalOpen(false)} className="px-4 py-2 bg-gray-300 text-gray-700 rounded">Cancel</button>
              <button onClick={handleAssign} className="px-4 py-2 bg-green-500 text-white rounded">Assign</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewClients;
