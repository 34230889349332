import { useRef, useState, useEffect } from 'react';
import { BellIcon } from '../utills/Svgs';
import useOutsideClick from '../Hooks/useClickOutside';
import { useNotification } from '../context/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { useRole } from '../context/RoleContext';

export default function NotificationsModal() {
  const { notifications, deleteNotification, deleteAllNotifications } = useNotification();
  const [recentNotifications, setRecentNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  const { role } = useRole();

  // console.log("notifications", notifications);
  
  // console.log("recentNotifications", recentNotifications)
  useEffect(() => setRecentNotifications(notifications), [notifications]);




  const handleMarkAllAsRead = () => {
    setRecentNotifications(recentNotifications?.map(n => ({ ...n, unread: false })));
  };

  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div className="relative">
      <button onClick={() => setIsOpen(!isOpen)} className="relative flex h-10 w-10 items-center justify-center rounded-full border bg-white hover:bg-gray-100">
        <BellIcon />
        {recentNotifications?.length > 0 && (
          <span className="absolute -top-2 right-0 flex min-w-[1.25rem] items-center justify-center rounded-full bg-red-500 text-xs text-white">
            {recentNotifications?.length > 99 ? '99+' : recentNotifications?.length}
          </span>
        )}
      </button>

      {isOpen && (
        <div ref={dropdownRef} className="absolute right-0 z-50 mt-2 w-80 rounded-lg border bg-white shadow-lg">
          <div className="flex items-center justify-between px-4 py-3">
            <h2 className="text-sm font-semibold">Notifications</h2>
            {recentNotifications?.length  > 0 && (
              <button onClick={deleteAllNotifications} className="text-xs text-blue-500 hover:underline">
                Mark all as read
              </button>
            )}
          </div>
          <div className="h-px bg-gray-200"></div>
          <div className="max-h-80 overflow-y-auto">
            {recentNotifications?.map(({ _id, message, timestamp, notDesc }) => (
              <div key={_id} className="flex flex-col gap-1 p-3 hover:bg-gray-100 cursor-pointer" onClick={() => {
              // Check if "client" is present in the message or notDesc
              const lowerCaseMessage = `${message} ${notDesc}`.toLowerCase();

              if (lowerCaseMessage.includes("client")) {
                navigate(`/${role.toLowerCase()}/clients/all`);
              }if (lowerCaseMessage.includes("comment")) {
                navigate(`/${role.toLowerCase()}/tasks/all`);
              }if (lowerCaseMessage.includes("task")) {
                navigate(`/${role.toLowerCase()}/tasks/all`);
              }

              deleteNotification(_id);
            }}>
                <p className="text-sm text-gray-800">{message} <span> {notDesc} </span></p>
                <p className="text-xs text-gray-500">{new Date(timestamp).toLocaleString()}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
