import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useRole } from '../../context/RoleContext';
import { usePermissions } from '../../context/PermissionContext';
import { useLoggedInUser } from '../../context/LoggedUser';
import { useNavigate } from 'react-router-dom';

const TaskComponent = () => {
    const [data, setData] = useState([]);
    const [toggle, setToggle] = useState('Total');
    const [startDate, setStartDate] = useState(new Date(2000, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusSummary, setStatusSummary] = useState({});
    const { role } = useRole();
    const { permissions } = usePermissions();
    const { loggedInUser } = useLoggedInUser();
    const [selectedOrg, setSelectedOrg] = useState("all"); // Default to all organization

    const apiUrl = process.env.REACT_APP_API_URL;
    const isAdmin = role === "admin" || role === "superadmin";
    const isFullReport = permissions.includes("Full Report");
    const navigate = useNavigate();
    // Function to set date range dynamically
    const handleToggleChange = (filter) => {
        setToggle(filter);
        const today = new Date();
        let newStartDate, newEndDate;

        if (filter === 'Last Month') {
            newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        } else if (filter === 'Last Year') {
            newStartDate = new Date(today.getFullYear() - 1, 0, 1);
            newEndDate = new Date(today.getFullYear() - 1, 11, 31);
        } else { // 'total'
            newStartDate = new Date(2000, 0, 1); // A very old date to include all records
            newEndDate = today;
        }

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(newEndDate.toISOString().split('T')[0]);
    };

    const fetchReportData = async (org_id = "") => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/reports/${(isFullReport || isAdmin) ? 'get-task-reports' : 'get-task-specific-reports'}`, {
                withCredentials: true,
                params: { startDate, endDate, org_id },
            });
            setData(response.data.tasks);
            setStatusSummary(response.data.statusSummary);
        } catch (error) {
            console.error(`Error fetching report:`, error);
        }

        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (startDate || endDate) {
            fetchReportData(selectedOrg === "all" ? "" : selectedOrg);
        }
    }, [toggle, startDate, endDate]);

    const statusColors = {
        Delayed: "bg-red-50 text-red-800",
        Delivered: "bg-indigo-50 text-indigo-900",
        Completed: "bg-teal-50 text-teal-900",
        Running: "bg-green-50 text-green-900",
        Unassigned: "bg-orange-50 text-orange-900",
        "Assigned to": "bg-blue-50 text-blue-900",
        "Approved By": "bg-purple-50 text-purple-900",
        "Quality Check": "bg-yellow-50 text-yellow-900",
        "Quality Check done by": "bg-gray-50 text-gray-900",
    };
    // Fetch Organizations List
    const fetchOrganizations = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/fetchorganization`, {
                withCredentials: true,
            });
            setOrganizations(response.data.organizations || []);
        } catch (error) {
            console.error("Error fetching organizations:", error);
        }
    };

    useEffect(() => {
        fetchOrganizations();
    }, []);
    useEffect(() => {
        if (loggedInUser?.org_id) {
            setSelectedOrg(loggedInUser.org_id);
        }
    }, [loggedInUser]);

    useEffect(() => {
        fetchReportData(selectedOrg === "all" ? "" : selectedOrg);
    }, [selectedOrg]);

    const handleStatusClick = (status) => {
        switch (status) {
            case 'Total':
                navigate(`/${role}/tasks/all`);
                break;
            case 'Running':
                navigate(`/${role}/tasks/running`);
                break;
            case 'Completed':
                navigate(`/${role}/tasks/completed`);
                break;
            case 'Delivered':
                navigate(`/${role}/tasks/delivered`);
                break;
            case 'Delayed':
                navigate(`/${role}/tasks/overdue`);
                break;
            case 'Quality Check':
                navigate(`/${role}/tasks/quality-check`);
                break;
            case 'Assigned to':
                navigate(`/${role}/tasks/assigned`);
                break;
            case 'Approved By':
                navigate(`/${role}/tasks/approved`);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-200 ">
                <h3 className="text-2xl font-semibold text-gray-800 mb-4">Task Report - {toggle.charAt(0).toUpperCase() + toggle.slice(1)}</h3>

                {/* Toggle Buttons */}
                <div className="flex flex-col md:flex-row md:items-center justify-between space-y-4 md:space-y-0">
                    <div className="flex flex-wrap justify-center md:justify-start gap-2">
                        {['Last Month', 'Last Year', 'Total'].map((item) => (
                            <button
                                key={item}
                                onClick={() => handleToggleChange(item)}
                                className={`px-4 py-2 rounded-lg font-semibold transition-colors duration-300 ${toggle === item ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
                            >
                                {item.charAt(0).toUpperCase() + item.slice(1)}
                            </button>
                        ))}
                    </div>

                    {/* Date Pickers */}
                    <div className="flex flex-wrap justify-center md:justify-end gap-2">
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="px-3 py-2 border border-gray-300 rounded-lg w-full sm:w-auto"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="px-3 py-2 border border-gray-300 rounded-lg w-full sm:w-auto"
                        />
                    </div>
                </div>
                {loggedInUser?.org_id === "" && (
                    <div className="mt-4 mb-b flex justify-end">
                        <select
                            value={selectedOrg}
                            onChange={(e) => setSelectedOrg(e.target.value)}
                            className="p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-300"
                        >
                            <option value="all">All Organizations</option>
                            {organizations.map((org) => (
                                <option key={org.org_id} value={org.org_id}>
                                    {org.org_name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                {/* Status Summary */}
                <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4  text-gray-700 text-sm">
                    {statusSummary && Object.keys(statusSummary).length > 0 ? (Object.entries(statusSummary).map(([status, count]) => {
                        if (count > 0) {
                            // Check visibility conditions for "Completed" and "Delivered"
                            const canSeeCompleted =
                                status === "Completed" &&
                                (role === "admin" || role === "superadmin" || permissions.includes("SeeCompletedTask"));

                            const canSeeDelivered =
                                status === "Delivered" &&
                                (role === "admin" || role === "superadmin" || permissions.includes("SeeDeliveredTask"));

                            // Allow visibility for all other statuses
                            const canSeeOtherStatuses = status !== "Completed" && status !== "Delivered";

                            // Determine if this status should be displayed
                            if (canSeeCompleted || canSeeDelivered || canSeeOtherStatuses) {
                                return (
                                    <div
                                        key={status}
                                        onClick={() => handleStatusClick(status)}
                                        className={`px-4 py-2 rounded-lg text-center hover:border-dark-accent hover:border-2 cursor-pointer shadow-sm ${statusColors[status] || "bg-gray-100 text-gray-700"}`}
                                    >
                                        <span className="text-lg font-semibold">{status}</span>
                                        <p className="text-4xl font-bold">{count}</p>
                                        <p>{count > 0 ? `(${((count / data.length) * 100).toFixed(2)}%)` : ""}</p>
                                    </div>
                                );
                            }
                        }
                        return null;
                    })) : (
                        <p className="text-center text-gray-500">No data available</p>
                    )
                    }
                </div>
            </div>
        </>
    );
};

export default TaskComponent;
