import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useRole } from './RoleContext';
import { useSocket } from './SocketContext'; // Import SocketContext

const NotificationContext = createContext();
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { role } = useRole();
  const { socket } = useSocket(); // Use socket from context

  // console.log("notifications special", notifications);
  
  // Send Notification via Socket
  const createNotification = async ({ sender, receiver, message, notDesc }) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/notification/create-notification`,
        { sender, receiver, message, notDesc },
        { withCredentials: true }
      );
      // console.log('Notification created:', response);
      // console.log('receiver:', receiver);

      // Emit real-time notification to receiver
      socket.emit('notification-sent', {
        to: receiver,
        message: response.data.data.message,
        notDesc: notDesc
      });
      getNotifications(); 
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  // Listen for Real-Time Notifications
  useEffect(() => {
    if (socket) {
      socket.on('notification-received', (data) => {
        // console.log("Received notification:", data);
        setNotifications((prev) => {
          const exists = prev.some((notif) => notif._id === data._id);
          if (!exists) {
            return [
              {
                _id: data._id || crypto.randomUUID(),
                message: data.message,
                notDesc: data.notDesc,
                sender: data.sender,
                timestamp: data.timestamp ?? new Date().toISOString(),
              },
              ...prev,
            ];
          }
          return prev;
        });
      });
  
      // Cleanup on unmount
      return () => {
        socket.off('notification-received');
      };
    }
  }, [socket]);
  
  
  
  const getNotifications = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/notification/get-notifications`, {
        withCredentials: true,
      });
      const fetchedNotifications = response.data.data;
  
      // Merge without duplicates
      setNotifications((prev) => {
        const merged = [...fetchedNotifications];
        prev.forEach((n) => {
          if (!merged.some((item) => item._id === n._id)) {
            merged.push(n);
          }
        });
        return merged;
      });
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    if(role){
        getNotifications() 
    }
  }, [role])

 
const deleteNotification = async (id) => {
    try {
      const response = await axios.delete(`${apiUrl}/api/notification/delete-notification/${id}`
        , { withCredentials: true }
      );
      if (response.data.status === 200) {
        setNotifications((prev) => prev.filter((notif) => notif._id !== id));
      }
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };
  
  const deleteAllNotifications = async () => {
    try {
      const response = await axios.delete(`${apiUrl}/api/notification/delete-allnotifications`);
      if (response.data.status === 200) {
        setNotifications([]); // Clear notifications directly
      }
    } catch (error) {
      console.error('Error deleting all notifications:', error);
    }
  };
  

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        createNotification,
        getNotifications,
        deleteNotification,
        deleteAllNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
