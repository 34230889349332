import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles
const AddLeads = ({ closeAddModal, setIsAddModalOpen, initialLeadData, addLeads }) => {
  //State to manage form input fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    linkedInURL: "",
    work: "",
    country:"",
    description: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;

  // Stae for managing loading and error handling
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  
  // Store for storing fetched Countries
  const [countrieslist,setCountriesList] = useState([]);
 const [phoneNumber, setPhoneNumber] = useState("");
  // Populate form fields when editing a leads
  useEffect(() => {
    if (initialLeadData) {
      setFormData({
        name: initialLeadData.name || "",
        email: initialLeadData.email || "",
        phoneNo: initialLeadData.phoneNo || "",
        work: initialLeadData.work || "",
        country: initialLeadData.country || "",
        description: initialLeadData.description || "",
        linkedInURL:initialLeadData.linkedInURL||""
      });
    }
  }, [initialLeadData]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (initialLeadData) {
        // Edit Client
        const response = await axios.put(
          `${apiUrl}/api/leads/update-lead-data/${initialLeadData._id}`,
          formData,
          { withCredentials: true }
        );
      } else {
        // Create Client
        const response = await axios.post(`${apiUrl}/api/leads/addLead`, formData, {
          withCredentials: true,
        });
        addLeads();
      }
      setLoading(false);
      setIsAddModalOpen(false);
    } catch (err) {
      console.error("Error submitting client data:", err);
      setError(err.response?.data?.message || "Failed to submit client data");
      setLoading(false);
    }
  };

  // Email validation
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, email: value }));

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      setError("Invalid email format");
    } else {
      setError("");
    }
  };

  // Phone validation
  // const handlePhoneChange = (e) => {
  //   const { value } = e.target;

  //   if (!/^\d*$/.test(value)) {
  //     setError("Phone number should contain only numbers");
  //     return;
  //   }

  //   setFormData((prev) => ({ ...prev, phoneNo: value }));

  //   if (value.length !== 10) {
  //     setError("Phone number must be exactly 10 digits");
  //   } else {
  //     setError("");
  //   }
  // };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        setCountriesList(response.data); // ✅ Fix: Set response.data, not response
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    getCountries();
  }, [apiUrl]);

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setFormData((prev) => ({ ...prev, phoneNo: value }));
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
      <div className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold mb-4">
            {initialLeadData ? "Edit Lead" : "Add Lead"}
          </h2>
          {error && <p className="text-red-500">{error}</p>}
          <button className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl" onClick={closeAddModal}>
            &times;
          </button>
        </div>

        <div className="max-h-[350px] overflow-y-scroll mt-6 scrollbar-thin">
          <form className="space-y-6 px-4" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name*</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter first name" required />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email*</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleEmailChange} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter email" required />
            </div>

            <div>
              <label htmlFor="phoneNo" className="block text-sm font-medium text-gray-700">Phone*</label>
              <input type="text" id="phoneNo" name="phoneNo" value={formData.phoneNo} onChange={handlePhoneChange} maxLength={10} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter 10-digit phone number" required />
            </div>

            <div>
              <label htmlFor="work" className="block text-sm font-medium text-gray-700">Work</label>
              <input type="text" id="work" name="work" value={formData.work} onChange={(e) => setFormData({ ...formData, work: e.target.value })} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter university" />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
              <input type="text" id="description" name="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter Description" />
            </div>

            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
              <select className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" id="country" name="country" value={formData.country} onChange={(e) => setFormData({ ...formData, country: e.target.value })}>
                <option value="" disabled>Select Country</option>
                {countrieslist.map((country) => (
                  <option key={country.cca2} value={country.name.common}>{country.name.common}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="linkedInURL" className="block text-sm font-medium text-gray-700">URL</label>
              <input type="text" id="linkedInURL" name="linkedInURL" value={formData.linkedInURL} onChange={(e) => setFormData({ ...formData, linkedInURL: e.target.value })} className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300" placeholder="Enter website URL" />
              {formData.linkedInURL && (
                <a href={formData.linkedInURL} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline mt-2 block">
                  Open URL
                </a>
              )}
            </div>

            <div className="flex justify-end space-x-4 mt-6">
              <button type="button" className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition" onClick={closeAddModal}>Cancel</button>
              <button type="submit" className="bg-blue-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-blue-600 transition" disabled={loading}>
                {initialLeadData ? (loading ? "Editing..." : "Edit Lead") : (loading ? "Creating..." : "Create Lead")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default AddLeads;
