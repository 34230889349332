import React, { useState, useRef, useEffect } from "react";
import useOutsideClick from "../../Hooks/useClickOutside";
import axios from "axios";
import { toast } from "react-toastify";
import { useRole } from "../../context/RoleContext";
import { useLoggedInUser } from "../../context/LoggedUser";

const apiUrl = process.env.REACT_APP_API_URL;

const AddCandidateModal = ({ onClose, getCandidates }) => {

  const { role } = useRole();
  const { loggedInUser } = useLoggedInUser();
  const [validRoles, setValidRoles] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    address: "",
    org_id: "",
    resume: null, // New field for file
  });

  const [loading, setLoading] = useState(false);
  const modalRef = useRef(null);
  useOutsideClick(modalRef, onClose);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      toast.error("Only PDF files are allowed!");
      return;
    }
    setFormData({ ...formData, resume: file });
  };
  // Fetch Organizations List
  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchorganization`, { withCredentials: true });
      setOrganizations(response.data.organizations || []);

    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };
  const fetchRoles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchRole`, { withCredentials: true });
      setValidRoles(response.data || []);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
    fetchRoles();
  }, [apiUrl]);



  const handleSubmit = async (e) => {
    e.preventDefault();





    try {
      setLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("role", formData.role);
      formDataToSend.append("address", formData.address);
      if (role === "superadmin") formDataToSend.append("org_id", formData.org_id);
      if (formData.resume) {
        formDataToSend.append("resume", formData.resume);
      }

      await axios.post(`${apiUrl}/api/hr/add-candidate`, formDataToSend, {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      });

      toast.success("Candidate added successfully!");
      onClose();
      getCandidates();
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-55 flex items-center justify-center p-4 sm:p-8 min-h-screen">
      <div ref={modalRef} className="bg-white rounded-lg shadow-lg p-6 sm:p-8 w-full max-w-xl max-h-[33rem] overflow-y-auto scrollbar-thin transition-transform transform scale-100">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold text-gray-800">Add New Candidate</h2>
          <button className="text-gray-500 hover:text-gray-800 text-2xl" onClick={onClose}>
            &times;
          </button>
        </div>

        <form className="space-y-4 mt-4" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
            required
          />

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
            required
          />

          <input
            type="text"
            name="phone"
            placeholder="Phone (10 digits)"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
            required
          />

          <select
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
            required
          >
            <option value="">Select Role</option>
            {validRoles.map((role) => (
              <option key={role._id} value={role._id}>
                {role.role_name}
              </option>
            ))}
          </select>

          

          {role === "superadmin" && (
            <select
              name="org_id"
              value={formData.org_id}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
              required
            >
              <option value="">Select Organization</option>
              {organizations.map((org) => (
                <option key={org.
                  org_id} value={org.
                  org_id}>
                  {org.org_name}
                </option>
              ))}
            </select>
          )}

          <input
            type="text"
            name="address"
            placeholder="Address"
            value={formData.address}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
          />

          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm"
          />

          <div className="flex justify-end space-x-4 mt-6">
            <button type="button" className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600 transition" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition" disabled={loading}>
              {loading ? "Adding..." : "Add"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCandidateModal;
