// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { usePermissions } from '../../context/PermissionContext';
// import { useRole } from '../../context/RoleContext';
// import { useLoggedInUser } from '../../context/LoggedUser';
// import {  MemoIcon, MoneyIcon, PendingAmountIcon } from '../../utills/Svgs';

// const TransactionsComponent = () => {
//     const [isAddModalOpen, setIsAddModalOpen] = useState(false);
//     const [data, setData] = useState([]);
//     const [financeData, setFinanceData] = useState({});
//     const [toggle, setToggle] = useState('monthly');
//     const [filteredData, setFilteredData] = useState(data);
//     const { loggedInUser } = useLoggedInUser();
//     const [organizations, setOrganizations] = useState([]);

//     const [selectedOrg, setSelectedOrg] = useState("all"); // Default to all organization

//     const apiUrl = process.env.REACT_APP_API_URL;
//     const { permissions } = usePermissions();
//     const { role } = useRole();
//     const isFullReport = permissions.includes("SeeTransactionSummary") || (role === "superadmin" || role === "admin");;
//     const fetchTurnover = async (org_id = "") => {
//         try {
//             const response = await axios.get(`${apiUrl}/api/transactions/getturnover`, { 
//                 withCredentials: true, 
//                 params: { org_id }
//             },
//             );
//             setFinanceData(response.data);

//         } catch (error) {
//             console.error("Error fetching turnover:", error);
//         }
//     };

//     useEffect(() => {
//         if (isFullReport) {

//             fetchTurnover()
//         }
//     }, [apiUrl]);

//     const handleToggleChange = (value) => {
//         setToggle(value);
//     };
//     const getFinanceValue = (type) => {
//         return {
//             turnover: financeData[`${type}Turnover`],
//             amountPaid: financeData[`${type}AmountPaid`],
//             pendingAmount: financeData[`${type}PendingAmount`],
//         };
//     };

//     const { turnover, amountPaid, pendingAmount } = getFinanceValue(toggle);

//     useEffect(() => {
//         setFilteredData([...data]); // Reset filteredData when data changes
//     }, [data]);
//     // Fetch Organizations List
//     const fetchOrganizations = async () => {
//         try {
//             const response = await axios.get(`${apiUrl}/api/fetchorganization`, {
//                 withCredentials: true,
//             });
//             setOrganizations(response.data.organizations || []);
//         } catch (error) {
//             console.error("Error fetching organizations:", error);
//         }
//     };

//     useEffect(() => {
//         fetchOrganizations();
//     }, []);
//     useEffect(() => {
//         if (loggedInUser?.org_id) {
//             setSelectedOrg(loggedInUser.org_id);
//         }
//     }, [loggedInUser]);

//     useEffect(() => {
//         fetchTurnover(selectedOrg === "all" ? "" : selectedOrg);
//     }, [selectedOrg]);



//     return (
//         <div className="bg-white shadow-lg rounded-xl p-6">
//             {/* Header */}
//             <h3 className="text-2xl font-semibold text-gray-800 mb-1 ">
//                 Finance Summary - {toggle.charAt(0).toUpperCase() + toggle.slice(1)}
//             </h3>

//             {/* Toggle Buttons */}
//             <div className="flex justify-end mb-6 space-x-3">
//                 {['monthly', 'yearly', 'total'].map((item) => (
//                     <button
//                         key={item}
//                         onClick={() => handleToggleChange(item)}
//                         className={`px-4 py-2 rounded-lg font-semibold transition duration-300 
//                         ${toggle === item ? 'bg-blue-500 text-white shadow-md' : 'bg-gray-100 text-gray-800 hover:bg-blue-100'}`}
//                     >
//                         {item.charAt(0).toUpperCase() + item.slice(1)}
//                     </button>
//                 ))}
//             </div>
//             {loggedInUser?.org_id === "" && (
//                 <div className="mt-2 mb-4 flex justify-end">
//                     <select
//                         value={selectedOrg}
//                         onChange={(e) => setSelectedOrg(e.target.value)}
//                         className="p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-300"
//                     >
//                         <option value="all">All Organizations</option>
//                         {organizations.map((org) => (
//                             <option key={org.org_id} value={org.org_id}>
//                                 {org.org_name}
//                             </option>
//                         ))}
//                     </select>
//                 </div>
//             )}

//             {/* Finance Summary Grid */}
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//                 <SummaryCard icon={<MemoIcon/>} title="Turnover" value={turnover} color="text-blue-500" />
//                 <SummaryCard icon={<MoneyIcon/>}title="Amount Paid" value={amountPaid} color="text-green-500" />
//                 <SummaryCard icon={<PendingAmountIcon/>} title="Pending Amount" value={pendingAmount} color="text-yellow-500" />
//             </div>
//         </div>
//     );
// };

// const SummaryCard = ({ icon, title, value, color }) => (
//     <div className="flex items-center bg-gray-50 p-4 rounded-lg shadow-sm">
//         <div className={`text-3xl mr-4 ${color}`}>{icon}</div>
//         <div>
//             <p className="text-gray-600 text-sm">{title}</p>
//             <p className="text-lg font-bold text-gray-800">₹ {value}</p>
//         </div>
//     </div>
// );

// export default TransactionsComponent;











import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { usePermissions } from '../../context/PermissionContext';
import { useRole } from '../../context/RoleContext';
import { useLoggedInUser } from '../../context/LoggedUser';
import { MemoIcon, MoneyIcon, PendingAmountIcon } from '../../utills/Svgs';

const TransactionsComponent = () => {
    const [data, setData] = useState([]);
    const [financeData, setFinanceData] = useState([]);
    const [toggle, setToggle] = useState('monthly');
    const { loggedInUser } = useLoggedInUser();
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState("all"); // Default to all organizations

    const apiUrl = process.env.REACT_APP_API_URL;
    const { permissions } = usePermissions();
    const { role } = useRole();
    const isFullReport = permissions.includes("SeeTransactionSummary") || (role === "superadmin" || role === "admin");

    const fetchTurnover = async (org_id = "") => {
        try {
            const response = await axios.get(`${apiUrl}/api/transactions/getturnover`, { 
                withCredentials: true, 
                params: { org_id }
            });
            setFinanceData(response.data);
        } catch (error) {
            console.error("Error fetching turnover:", error);
        }
    };

    useEffect(() => {
        if (isFullReport) {
            fetchTurnover();
        }
    }, [apiUrl]);

    const handleToggleChange = (value) => {
        setToggle(value);
    };

    const getFinanceValue = (type) => {
        return {
            turnover: financeData[`${type}Turnover`] || 0,
            amountPaid: financeData[`${type}AmountPaid`] || 0,
            pendingAmount: financeData[`${type}PendingAmount`] || 0,
        };
    };

    const { turnover, amountPaid, pendingAmount } = getFinanceValue(toggle);

    // Fetch Organizations List
    const fetchOrganizations = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/fetchorganization`, {
                withCredentials: true,
            });
            setOrganizations(response.data.organizations || []);
        } catch (error) {
            console.error("Error fetching organizations:", error);
        }
    };

    useEffect(() => {
        fetchOrganizations();
    }, []);

    useEffect(() => {
        if (loggedInUser?.org_id) {
            setSelectedOrg(loggedInUser.org_id);
        }
    }, [loggedInUser]);

    useEffect(() => {
        fetchTurnover(selectedOrg === "all" ? "" : selectedOrg);
    }, [selectedOrg]);

    return (
        <div className="bg-white shadow-lg rounded-xl p-6">
            {/* Header */}
            <h3 className="text-2xl font-semibold text-gray-800 mb-1">
                Finance Summary - {toggle.charAt(0).toUpperCase() + toggle.slice(1)}
            </h3>

            {/* Toggle Buttons */}
            <div className="flex justify-end mb-6 space-x-3">
                {['monthly', 'yearly', 'total'].map((item) => (
                    <button
                        key={item}
                        onClick={() => handleToggleChange(item)}
                        className={`px-4 py-2 rounded-lg font-semibold transition duration-300 
                        ${toggle === item ? 'bg-blue-500 text-white shadow-md' : 'bg-gray-100 text-gray-800 hover:bg-blue-100'}`}
                    >
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                    </button>
                ))}
            </div>

            {/* Organization Filter */}
            {loggedInUser?.org_id === "" && (
                <div className="mt-2 mb-4 flex justify-end">
                    <select
                        value={selectedOrg}
                        onChange={(e) => setSelectedOrg(e.target.value)}
                        className="p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-300"
                    >
                        <option value="all">All Organizations</option>
                        {organizations.map((org) => (
                            <option key={org.org_id} value={org.org_id}>
                                {org.org_name}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Finance Summary Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <SummaryCard icon={<MemoIcon />} title="Turnover" value={turnover} color="text-blue-500" />
                <SummaryCard icon={<MoneyIcon />} title="Amount Paid" value={amountPaid} color="text-green-500" />
                <SummaryCard icon={<PendingAmountIcon />} title="Pending Amount" value={pendingAmount} color="text-yellow-500" />
            </div>

            {/* Line Chart */}
            <div className="mt-8">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Financial Overview Over Time</h3>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={financeData.monthlySummary} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="turnover" stroke="#8884d8" strokeWidth={2} />
                        <Line type="monotone" dataKey="amountPaid" stroke="#82ca9d" strokeWidth={2} />
                        <Line type="monotone" dataKey="pendingAmount" stroke="#ffc658" strokeWidth={2} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

const SummaryCard = ({ icon, title, value, color }) => (
    <div className="flex items-center bg-gray-50 p-4 rounded-lg shadow-sm">
        <div className={`text-3xl mr-4 ${color}`}>{icon}</div>
        <div>
            <p className="text-gray-600 text-sm">{title}</p>
            <p className="text-lg font-bold text-gray-800">₹ {value}</p>
        </div>
    </div>
);

export default TransactionsComponent;