import { createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useLoggedInUser } from "../context/LoggedUser";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const socket = useRef(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { loggedInUser } = useLoggedInUser();

  useEffect(() => {
    if (loggedInUser?._id) {
      if (!socket.current) {
        socket.current = io(apiUrl, {
          withCredentials: true,
        });
      }

      socket.current.emit("add-user", loggedInUser._id);

      socket.current.on("getOnlineUsers", (res) => {
        setOnlineUsers(res);
        // console.log("res", res);
        
      });

    //   return () => {
    //     if (socket.current) {
    //       socket.current.disconnect();
    //     }
    //   };
    }
  }, [loggedInUser, apiUrl]);

  return (
    <SocketContext.Provider value={{ socket: socket.current, onlineUsers }}>
      {children}
    </SocketContext.Provider>
  );
};
