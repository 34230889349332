
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useRole } from './RoleContext';

const LoggedUserContext = React.createContext();

export const useLoggedInUser = () => useContext(LoggedUserContext);

const LoggedUserProvider = ({ children }) => {
    const { role } = useRole();
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(false);

    // console.log("loggedin user", loggedInUser);
    

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const fetchLoggedInUser = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/api/fetchloggeduser`, { withCredentials: true });
                // console.log(response.data[0]);
                setLoggedInUser(response.data[0]);
                setCompany(response.data[1]);

            } catch (error) {
                console.error(error);
            }finally {
                setLoading(false);
            }
        }

        fetchLoggedInUser();
    }, [role]);



    return (
        <LoggedUserContext.Provider value={{ loggedInUser, company, loading }}>
            {children}
        </LoggedUserContext.Provider>
    );
};

export default LoggedUserProvider;
