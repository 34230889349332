import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../../components/Table';
import { useRole } from '../../context/RoleContext';
import { RxCross2 } from 'react-icons/rx';

const Efforts = () => {
    const [data, setData] = useState([]);
    const [entries, setEntries] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const [modelOpen, setModelOpen] = useState(false);
    const [selectedEffort, setSelectedEffort] = useState(null);
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { role } = useRole();
    const isAdmin = role === "admin" || role === "superadmin";

    const fetchEfforts = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/efforts/get-efforts`, { withCredentials: true });
            setData(response.data.efforts);
        } catch (error) {
            console.error("Error fetching efforts:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEfforts();
    }, [apiUrl]);

    useEffect(() => {
        setFilteredData([...data]);
    }, [data]);

    const handleEffortsEntriesChange = (value) => {
        setEntries(value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };
      
    const handleOnSearch = (value) => {
        const searchValue = value?.toLowerCase();
        if (!searchValue) {
            setFilteredData(data);
            return;
        }

        const filteredResults = data.filter((item) =>
            item.title?.toLowerCase()?.includes(searchValue) ||
            item.status?.toLowerCase()?.includes(searchValue) ||
            item.taskId?.toLowerCase()?.includes(searchValue)
        );

        setFilteredData(filteredResults);
    };

    const startIndex = (currentPage - 1) * entries;
    const paginatedData = filteredData.slice(startIndex, startIndex + entries);
   
// Function to format only the date
const formatDateOnly = (date) => {
    return date ? new Date(date).toLocaleDateString() : "N/A";
};

// Function to format both date and time
const formatDateTime = (date) => {
    return date ? new Date(date).toLocaleString() : "N/A";
};
 // Format data before displaying in table
 useEffect(() => {
    const formattedData = data.map((item) => ({
        ...item,
        soft_deadline: formatDateOnly(item.soft_deadline),
        hard_deadline: formatDateOnly(item.hard_deadline),
        createdAt: formatDateTime(item.createdAt),
        updatedAt: formatDateTime(item.updatedAt),
    }));
    setFilteredData(formattedData);
}, [data]);


const columns = [
    { header: "Task ID", accessor: "taskId" },
    { header: "Title", accessor: "title" },
    { header: "Description", accessor: "description" },
    { header: "Status", accessor: "status", className: "font-semibold" },
    ...(isAdmin ? [{ header: "Soft Deadline", accessor: "soft_deadline" }] : []),
    ...(isAdmin ? [{ header: "Hard Deadline", accessor: "hard_deadline" }] : []),
    ...(isAdmin ? [{ header: "Created At", accessor: "createdAt" }] : []),
    ...(isAdmin ? [{ header: "Updated At", accessor: "updatedAt" }] : []),
];

    return (
        <>
            <div className="p-4">
                <Table
                    title="Efforts"
                    columns={columns}
                    data={filteredData.slice(0, entries)}
                    onSearch={(value) => handleOnSearch(value)}
                    onEntriesChange={handleEffortsEntriesChange}
                    entriesOptions={[5, 10, 25, 50, 100, data?.length]}
                    exportButton={true}
                    onRowClick={(row) => {
                        setSelectedEffort(row);
                        setModelOpen(true);
                    }}
                    currentPage={currentPage}
                    totalPages={Math.ceil(filteredData.length / entries)}
                    onPageChange={handlePageChange}
                    totalEntries={filteredData.length}
                    entries={entries}
                    loading={loading}
                />
            </div>

            {modelOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm z-50 p-6">
                    <div className="bg-white backdrop-blur-lg rounded-3xl shadow-2xl w-full sm:max-w-md md:max-w-2xl lg:max-w-3xl p-8 border border-gray-300">
                        {/* Header */}
                        <div className="flex justify-between items-center border-b border-gray-300 pb-4">
                            <h2 className="text-2xl font-extrabold text-gray-900 tracking-wide">Effort Details</h2>
                            <button
                                onClick={() => setModelOpen(false)}
                                className="text-gray-600 hover:text-red-600 transition-all duration-300 rounded-full p-2 hover:bg-gray-300"
                            >
                                <RxCross2 className="w-6 h-6" />
                            </button>
                        </div>

                        {/* Content */}
                        {selectedEffort && (
                            <div className="mt-6 space-y-4 text-gray-800">
                                <p><span className="font-semibold">Task ID:</span> {selectedEffort.taskId}</p>
                                <p><span className="font-semibold">Title:</span> {selectedEffort.title}</p>
                                <p><span className="font-semibold">Description:</span> {selectedEffort.description}</p>
                                <p><span className="font-semibold">Status:</span>
                                    <span className={`ml-2 px-4 py-1 rounded-full text-sm font-medium shadow-md
                            ${selectedEffort.status === 'Delivered' ? 'bg-green-100 text-green-700' :
                                            selectedEffort.status === 'Completed' ? 'bg-blue-100 text-blue-700' :
                                                'bg-red-100 text-red-700'}`}>
                                        {selectedEffort.status}
                                    </span>
                                </p>

                                {isAdmin && (
                                    <div className="grid sm:grid-cols-2 gap-6 mt-6 border-t border-gray-300 pt-5">
                                        <p><span className="font-semibold">Soft Deadline:</span> {formatDateOnly(selectedEffort.soft_deadline)}</p>
                                        <p><span className="font-semibold">Hard Deadline:</span> {formatDateOnly(selectedEffort.hard_deadline)}</p>
                                        <p><span className="font-semibold">Created At:</span> {formatDateTime(selectedEffort.createdAt)}</p>
                                        <p><span className="font-semibold">Updated At:</span> {formatDateTime(selectedEffort.updatedAt)}</p>

                                    </div>
                                )}
                            </div>
                        )}

                        {/* Footer */}
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setModelOpen(false)}
                                className="px-6 py-2 text-lg bg-gradient-to-r from-gray-700 to-black text-white rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default Efforts;
