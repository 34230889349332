import React, { useEffect, useState, useRef, use } from "react";
import { FaInfo, FaUpload, FaSearch, FaCog, FaPlus, FaFileImport, FaFileExport, FaSync, FaFileUpload, FaCommentDots, FaQuestionCircle, FaFileAlt, FaPrint, FaCopy, FaFileExcel, FaFileCsv } from "react-icons/fa";
import { FaFileImage, FaFilePdf } from "react-icons/fa"; // You can use these or any other icons
import { FiPrinter, FiMoreVertical } from "react-icons/fi";
import * as XLSX from 'xlsx';
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import AddTask from "./AddTask";
import axios from "axios";
import AssignTask from "./AssignTask";
import { MdOutlineAccountTree } from "react-icons/md";
import useOutsideClick from "../../Hooks/useClickOutside";
import { useSocket } from '../../context/SocketContext'
import { useLoggedInUser } from "../../context/LoggedUser";
import CommentModal from "./Comments";
import { useNotification } from '../../context/NotificationContext'
import { useRole } from "../../context/RoleContext";
import { usePermissions } from "../../context/PermissionContext";
import Loading from "../../components/Loading";
const ApprovedTask = () => {
  const { role } = useRole();
  // State variables for task management
  const [entries, setEntries] = useState(5);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [filter, setFilter] = useState("All");
  const [file, setFile] = useState(null);
  const [taskStatus, setTaskStatus] = useState("");
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false)
  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false);
  const [solution, setSolution] = useState(null);
  const [brief, setBrief] = useState(null);
  const [isAssinTaskModalOpen, setIsAssinTaskModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [searchTaskQuery, setSearchTaskQuery] = useState("");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null); // State to store the selected task
  const [showSettings, setShowSettings] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [isTaskLogsVisible, setIsTaskLogsVisible] = useState(false);
  const [isUploadPageVisible, setIsUploadPageVisible] = useState(false);
  const [isUploadBriefVisible, setIsUploadBriefVisible] = useState(false);
  const [names, setNames] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [importedTasks, setImportedTasks] = useState([]); // Imported task list

  const [commentType, setCommentType] = useState(""); // 'comment' or 'query'
  const { permissions } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const { loggedInUser } = useLoggedInUser();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { createNotification } = useNotification()
 
  //Refs for outside click handling
  const printRef = useRef();
  const { socket } = useSocket()
  const closeRef = useRef();
  const dropdownRef = useRef();
  const exportRef = useRef();
  const fileInputRef = useRef(null)
  useOutsideClick(closeRef, () => setShowSettings(false));
  useOutsideClick(dropdownRef, () => setIsDropDownOpen(false));
  useOutsideClick(exportRef, () => setShowExportOptions(false));
  // Allowed file types for upload
  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "application/pdf",
    "video/mp4",
    "audio/mpeg",
    "audio/wav",
  ];
  const [loading, setLoading] = useState(false);
 

  // Fetch task data from API
  const fetchTaskData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/task/get-task/Approvedby`, {
        withCredentials: true,
      });
      // console.log("------------------------------------------>>>", response.data);

      setTaskData(response.data.tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
    }
  };

  // Status colors for UI
  const statusColors = {
    Delayed: "bg-red-50 text-red-800",
    Unassigned: "bg-orange-50 text-orange-900",
    "Assigned to": "bg-blue-50 text-blue-900",
    Running: "bg-green-50 text-green-900",
    "Approved By": "bg-purple-50 text-purple-900",
    "Quality Check": "bg-yellow-50 text-yellow-900",
    "Quality Check done by": "bg-gray-50 text-gray-900",
    Completed: "bg-teal-50 text-teal-900",
    Delivered: "bg-indigo-50 text-indigo-900",
  };


  // Fetch task data on component mount
  useEffect(() => {
    fetchTaskData();
  }, [apiUrl, filter]);

  // Pagination logic
  const totalTaskPages = Math.max(1, Math.ceil(taskData.length / entries));
  useEffect(() => {
    if (currentPage > totalTaskPages) {
      setCurrentPage(totalTaskPages);
    }
  }, [taskData, totalTaskPages, currentPage]);

  // Calculate indexes for slicing
  const startIndex = (currentPage - 1) * entries;
  const endIndex = startIndex + entries;

  // Reload task data
  const handleReload = () => {
    fetchTaskData();
  };

  // Close modals
  const closeAddTaskModal = () => setIsAddTaskModalOpen(false);
  const closeAssignTaskModal = () => setIsAssinTaskModalOpen(false);

  // Handle task click - Selects a task and hides unnecessary sections
  const handleTaskClick = (task) => {
    setSelectedTask(task); // Set the selected task
    setIsTaskLogsVisible(false); // Hide task logs panel
    setIsUploadPageVisible(false); // Hide file upload section
    setIsUploadBriefVisible(false); // Hide brief upload section
    setIsCommentModalOpen(false);
  };

  const handleToggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  // Fetch solution for a specific task
  const fetchSolution = async (taskId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/api/task/get-solution/${taskId}`,
        { withCredentials: true }
      );
      setSolution(response.data.solutions);
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  // Fetch brief details for a specific task
  const fetchBrief = async (taskId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/api/task/get-brief/${taskId}`,
        { withCredentials: true }
      );
      setBrief(response.data.briefs);
    } catch (error) {
      console.error("Error fetching brief:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch solution and brief when a task is selected
  useEffect(() => {
    if (selectedTask) {
      if(permissions.includes('AddSolutions') || permissions.includes('DeliverTask') || permissions.includes('AddTask')){
        
        fetchSolution(selectedTask._id);
      }
      fetchBrief(selectedTask._id);
    }
  }, [selectedTask]);

  // Delete a task
  const handleDelete = async (taskId) => {
    setIsLoading(true);
    try {
      await axios.delete(`${apiUrl}/api/task/delete-task/${taskId}`, {
        withCredentials: true,
      });
      setTaskData((prev) => prev.filter((task) => task._id !== taskId));
      if (selectedTask?._id === taskId || editingTask?._id === taskId) {
        setSelectedTask(null);
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update task status
  const handleStatusChange = async (status) => {
    try {
      let endpoint =
        status === "Completed"
          ? `${apiUrl}/api/task/complete-task`
          : `${apiUrl}/api/task/deliver-task`;

      const response = await axios.put(
        endpoint,
        { taskId: selectedTask._id, employeeId: loggedInUser._id },
        { withCredentials: true }
      );

      

      // Ensure we are comparing with strict equality
      if (response.status === 200 && response.data.task.status === "Delivered") {
        const employees = response.data.task.iterations;

        // console.log("employees", employees)
        if (employees.length > 0) {
          // Emit event to each assigned employee
          employees.forEach(employee => {
            socket.emit("deliver-task", {
              to: employee.assigned_to,
              message: `Task Delivered ${response.data.task.taskId}`,
              date: new Date(),
              notDesc: response.data.task.taskId,
            });
          });

          // Create notifications for assigned employees
          await createNotification({
            sender: loggedInUser._id,
            receiver: employees.map(employee => employee.assigned_to),
            message: "Task Delivered",
            notDesc: response.data.task.taskId,
          });

          
        } else {
          console.warn("No employees assigned for notification");
        }
      }

      if (response.status === 200 && response.data.task.status === "Completed") {
        const employees = response.data.task.iterations;

        if (employees.length > 0) {
          // Emit event to each assigned employee
          employees.forEach(employee => {
            
            socket.emit("complete-task", {
              to: employee.assigned_to,
              message: `Task completed ${response.data.task.taskId}`,
              date: new Date(),
              notDesc: response.data.task.taskId,
            });
          });

          // Create notifications for assigned employees
          await createNotification({
            sender: loggedInUser._id,
            receiver: employees.map(employee => employee.assigned_to),
            message: "Task Completed",
            notDesc: response.data.task.taskId,
          });

          // console.log("Notification created successfully");
        } else {
          console.warn("No employees assigned for notification");
        }
      }

      // Update the local task status
      setTaskStatus(status);
      setTaskData((prevTasks) =>
        prevTasks.map((task) =>
          task._id === selectedTask._id ? { ...task, status } : task
        )
      );
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };


  // Function to handle opening task logs
  const handleOpenTaskLogs = () => {
    setIsTaskLogsVisible(true);
    setIsUploadPageVisible(false);
    setIsUploadBriefVisible(false);
    setIsCommentModalOpen(false);

  };

  // Function to handle closing task logs
  const handleCloseTaskLogs = () => {
    setIsTaskLogsVisible(false);
  };

  // Function to mark the task as "Completed"
  const handleCompleteTask = () => {
    handleStatusChange("Completed");
    fetchTaskData();
  };

  // Function to mark the task as "Delivered"
  const handleDeliverTask = () => {
    handleStatusChange("Delivered");
  };

  // Function to open the task modal and set the selected task for editing
  const openTaskModal = (task) => {
    setIsAddTaskModalOpen(true); // Open the task modal
    setEditingTask(task); // Set the selected task for editing
  };

  // Function to handle opening the upload page
  const handleUpload = () => {
    setIsUploadPageVisible(true);
    setIsTaskLogsVisible(false);
    setIsUploadBriefVisible(false);
    setIsCommentModalOpen(false);
  };

  const handleCommentModal = (type = "comment") => {
    setCommentType(type);
    setIsUploadPageVisible(false);
    setIsTaskLogsVisible(false);
    setIsUploadBriefVisible(false);
    setIsCommentModalOpen(true);
  }

  // Function to handle closing the upload page
  const handleCloseUploadPage = () => {
    setIsUploadPageVisible(false);
    setIsUploadBriefVisible(false);
  };

  // Function to handle file selection from the input field
  const handleFileChange = (event) => {
    event.preventDefault(); // Prevents default browser behavior for file selection
    const file = event.target.files[0]; // Retrieves the first selected file

    // Checks if the selected file type is allowed before setting it
    if (allowedTypes.includes(file.type)) {
      setFile(file);
    }
  };

  // Function to submit the solution
  const handleSubmit = async (taskId) => {
    // Check if a file is selected before proceeding with submission
    if (!file) {
      alert("Please select a file before submitting.");
      return;
    }

    setIsUploading(true); // Set uploading state to true while the file is being processed
    setUploadError(null); // Reset any previous upload errors

    const formData = new FormData();
    formData.append("file", file); // Attach the selected file to the form data
    formData.append("taskId", selectedTask._id); // Include the task ID in the form data

    try {
      // Make an API call to upload the file and associate it with the task
      const response = await axios.post(
        `${apiUrl}/api/task/add-solution/${selectedTask._id}`,
        formData,
        {
          withCredentials: true, // Ensure credentials (cookies, auth tokens) are sent with the request
        }
      );

      // Notify the user upon successful file upload
      alert("Solution and file uploaded successfully!");

      // Store the uploaded solution data received from the backend
      setSolution(response.data.solutions);
    } catch (error) {
      // Log the error in the console for debugging
      console.error("Upload error:", error);

      // Display an error message to the user in case of upload failure
      setUploadError("Failed to upload the file. Please try again.");
    } finally {
      // Ensure that the uploading state is reset once the process is complete
      setIsUploading(false);
    }
  };

  // Function to show the file upload section while hiding other sections
  const handleUploadBrief = () => {
    setIsUploadBriefVisible(true); // Make the upload brief section visible
    setIsUploadPageVisible(false); // Hide the main upload page
    setIsTaskLogsVisible(false); // Hide the task logs section
  };

  // Handle brief upload
  const handleBriefSubmit = async () => {
    if (!file) {
      alert("Please select a file before submitting.");
      return;
    }
    setIsUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("taskId", selectedTask._id);

    try {
      const response = await axios.post(
        `${apiUrl}/api/task/add-brief/${selectedTask._id}`,
        formData,
        {
          withCredentials: true,
        }
      );
      // Immediately fetch updated brief data
      fetchBrief(selectedTask._id);
      setFile(null);
    } catch (error) {
      console.error("Upload error:", error);
      setUploadError("Failed to upload the file. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    // Ensure there is a selected task and it contains iterations before proceeding.
    if (!selectedTask || !selectedTask.iterations) return;

    // Function to fetch user names based on their assigned IDs.
    const fetchNames = async () => {
      const fetchedNames = {}; // Object to store fetched names.

      // Iterate over task iterations and fetch assigned user names.
      const promises = selectedTask.iterations.map(async (log) => {
        // Fetch the name of the user who assigned the task if not already fetched.
        if (log.assigned_by && !fetchedNames[log.assigned_by]) {
          fetchedNames[log.assigned_by] = await fetchNameWithId(
            log.assigned_by
          );
        }
        // Fetch the name of the user the task was assigned to if not already fetched.
        if (log.assigned_to && !fetchedNames[log.assigned_to]) {
          fetchedNames[log.assigned_to] = await fetchNameWithId(
            log.assigned_to
          );
        }
      });

      await Promise.all(promises); // Ensure all names are fetched before proceeding.
      setNames(fetchedNames); // Update state with the fetched names.
    };

    fetchNames();
  }, [selectedTask]);


  // Function to fetch a user’s name based on their ID.
  const fetchNameWithId = async (userId) => {
    try {
      // API request to fetch user data by ID.
      const response = await axios.get(`${apiUrl}/api/fetchUser/${userId}`, {
        withCredentials: true, // Ensures authentication credentials are included.
      });
      return response.data.name; // Return the fetched user name.
    } catch (error) {
      console.error("Error fetching user name:", error);
      return "N/A"; // Return a fallback value if an error occurs.
    }
  };

  // Function to handle the print functionality for tasks.
  const handlePrint = () => {
    if (printRef.current) {
      // Clone the current content inside printRef to avoid modifying the actual DOM.
      const clonedContent = printRef.current.cloneNode(true);

      // Remove unnecessary elements such as buttons and icons before printing.
      clonedContent
        .querySelectorAll("button, svg")
        .forEach((el) => el.remove());

      // console.log("cloned content", clonedContent);

      if (isTaskLogsVisible) {
        // If only task logs should be printed, extract relevant data.
        const taskLogs = selectedTask?.iterations?.map(
          (log, index) =>
            `${index + 1}. ${log.assigned_by} assigned to ${log.assigned_to
            } - ${log.status}`
        ) || ["No logs available."];

        // console.log("taskLogs", taskLogs);

        // Open a new print window and display formatted task logs.
        const printWindow = window.open("", "", "width=800,height=600");
        printWindow.document.write(`
          <html>
            <head>
              <title>Task Logs</title>
              <style>
                body { font-family: Arial, sans-serif; padding: 20px; color: #333; }
                .container { width: 100%; max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; }
                h2 { text-align: center; font-size: 22px; color: #007BFF; }
                .logs { margin-top: 20px; padding: 10px; background: #f9f9f9; border-radius: 5px; }
              </style>
            </head>
            <body>
              <div class="container">
                <h2>📜 Task Logs</h2>
                <div class="logs">
                  <ul>
                    ${taskLogs.map((log) => `<li>${log}</li>`).join("")}
                  </ul>
                </div>
              </div>
            </body>
          </html>
        `);

        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.print();
          printWindow.close();
        };
      } else {
        // If full task details need to be printed, extract required details from the DOM.
        const extractText = (label) => {
          const spans = [...clonedContent.querySelectorAll("span")];
          const targetSpan = spans.find(
            (span) => span.textContent.trim() === label
          );
          return targetSpan
            ? targetSpan.nextElementSibling?.textContent.trim() || "N/A"
            : "N/A";
        };

        // Construct task details with extracted and directly available values.
        const taskDetails = {
          taskId: selectedTask?.taskId || "N/A",
          client: extractText("Client:"),
          department: extractText("Department:"),
          wordCount: extractText("Word Count:"),
          status: selectedTask?.status || "N/A",
          softDeadline: extractText("Soft Deadline:"),
          hardDeadline: extractText("Hard Deadline:"),
          description: selectedTask?.description || "N/A",
        };

        // Open a new print window and display formatted task details.
        const printWindow = window.open("", "", "width=800,height=600");
        printWindow.document.write(`
          <html>
            <head>
              <title>Task Details</title>
              <style>
                body { font-family: Arial, sans-serif; padding: 20px; color: #333; }
                .container { width: 100%; max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; }
                h2 { text-align: center; font-size: 22px; color: #007BFF; }
                table { width: 100%; border-collapse: collapse; margin-top: 15px; }
                th, td { padding: 10px; text-align: left; border-bottom: 1px solid #ddd; }
                th { background-color: #f5f5f5; font-weight: bold; }
                .status { font-weight: bold; color: green; }
                .deadline { font-weight: bold; color: red; }
                .description { margin-top: 15px; font-style: italic; }
              </style>
            </head>
            <body>
              <div class="container">
                <h2>📌 Task Details</h2>
                <table>
                  <tr><th>Task ID:</th><td>${taskDetails.taskId}</td></tr>
                  <tr><th>Client:</th><td>${taskDetails.client}</td></tr>
                  <tr><th>Department:</th><td>${taskDetails.department}</td></tr>
                  <tr><th>Word Count:</th><td>${taskDetails.wordCount}</td></tr>
                  <tr><th>Status:</th><td class="status">${taskDetails.status}</td></tr>
                  <tr><th>Soft Deadline:</th><td class="deadline">${taskDetails.softDeadline}</td></tr>
                  <tr><th>Hard Deadline:</th><td class="deadline">${taskDetails.hardDeadline}</td></tr>
                </table>
                <p class="description"><strong>Description:</strong> ${taskDetails.description}</p>
              </div>
            </body>
          </html>
        `);

        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.print();
          printWindow.close();
        };
      }
    } else {
      console.error("printRef.current is NULL!"); // Log error if print reference is null.
    }
  };

  // Function to filter tasks based on the user's search input.
  const filteredTasks = taskData.filter((task) =>
    task.title?.toLowerCase().includes(searchTaskQuery?.toLowerCase())
  );


  const setIsPaymentButtonClicked = () => {
    sessionStorage.setItem('taskId', selectedTask.taskId);
    window.location.replace(`/${role}/transactions`);
  }


  const handleExportPrint = () => {
    let printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write("<html><head><title>Print Task List</title></head><body>");
    printWindow.document.write("<h2>Task List</h2><table border='1'><tr><th>TaskID</th><th>Title</th><th>Word Count</th><th>Description</th><th>Client</th><th>Department</th><th>Hard Deadline</th><th>Soft Deadline</th><th>Status</th></tr>");

    filteredTasks.forEach(task => {
      printWindow.document.write(
        `<tr>
          <td>${task.taskId}</td>
          <td>${task.title}</td>
          <td>${task.wordcount}</td>
          <td>${task.description}</td>
          <td>${task.clientName}</td>
          <td>${task.departmentName}</td>
          <td>${new Date(task.hard_deadline).toLocaleDateString()}</td>
          <td>${new Date(task.soft_deadline).toLocaleDateString()}</td>
          <td>${task.status}</td>
        </tr>`
      );
    });

    printWindow.document.write("</table></body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const handleCopy = () => {
    let textToCopy = filteredTasks.map(task =>
      `TaskID: ${task.taskId}, Title: ${task.title}, Word Count: ${task.wordcount}, Soft Deadline: ${new Date(task.soft_deadline).toLocaleDateString()},Hard Deadline: ${new Date(task.hard_deadline).toLocaleDateString()}, Status: ${task.status}, Description: ${task.description}, Client: ${task.clientName}, Department: ${task.departmentName}`
    ).join("\n");

    navigator.clipboard.writeText(textToCopy).then(() => {
      alert("Task data copied to clipboard!");
    }).catch(err => {
      console.error("Failed to copy:", err);
    });
  };


  const handleExportExcel = () => {
    if (!filteredTasks.length) return;
  
    // Fields to exclude from export
    const excludeFields = ["_id", "employeeInvolved", "iterations", "createdAt", "updatedAt", "__v", "created_by", "updated_by","dept_id","org_id","clientId"];
  
    // Ensure we capture all keys dynamically, excluding unwanted fields
    let allKeys = Object.keys(filteredTasks[0]).filter(key => !excludeFields.includes(key));
  
    // Ensure "Task ID" appears first (taskId is renamed to "Task ID")
    if (allKeys.includes("taskId")) {
      allKeys = allKeys.filter(key => key !== "taskId"); // Remove taskId before adding it manually
    }
    allKeys = ["Task ID", ...allKeys]; // Ensure Task ID is always the first column
  
    // Convert tasks to Excel-compatible format
    const ws = XLSX.utils.json_to_sheet(
      filteredTasks.map(task => {
        return {
          "Task ID": task.taskId || "", // Ensure Task ID is included
          ...Object.fromEntries(
            allKeys
              .filter(key => key !== "Task ID") // Avoid double-mapping Task ID
              .map(key => {
                let value = task[key] || ""; // Handle missing values
                if (key.toLowerCase().includes("deadline") && value) {
                  value = new Date(value).toISOString().split("T")[0]; // Format deadlines
                }
                return [key, value];
              })
          )
        };
      })
    );
  
    // Adjust column widths dynamically for better UI
    ws["!cols"] = allKeys.map(key => ({ wch: Math.max(10, key.length + 5) }));
  
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tasks");
    XLSX.writeFile(wb, "tasks_data.xlsx");
  };
  
  
  
  


  // 📌 Export to PDF
  const handleExportPDF = () => {
    const doc = new jsPDF();
    doc.text("Task List", 14, 10);

    const tableColumn = ["Task ID", "Title", "Word Count", "Deadline", "Status"];
    const tableRows = filteredTasks.map((task) => [
      task.taskId,
      task.title,
      task.wordcount,
      new Date(task.soft_deadline).toLocaleDateString(),
      task.status,
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.save("tasks_data.pdf");
  };

  const handleExportCSV = () => {
    const ws = XLSX.utils.json_to_sheet(filteredTasks.map(task => ({
      TaskID: task.taskId,
      Title: task.title,
      WordCount: task.wordcount,
      SoftDeadline: new Date(task.soft_deadline).toLocaleDateString(),
      Status: task.status
    })));
    const csv = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "tasks_data.csv";
    link.click();
  };

// // API function to import tasks
const importTasksToAPI = async (tasks) => {
  try {
    const response = await axios.post(
      `${apiUrl}/api/task/import-task-data`,
      { tasks }, // ✅ Send tasks as a raw array
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Tasks imported successfully:", response.data);
    return response.data; // ✅ Return API response
  } catch (error) {
    console.error("Error importing tasks:", error.message);
    throw error; // ✅ Throw error for better debugging
  }
};

const handleImportExcel = async (event) => {

  const file = event.target.files[0];
  if (!file) return;

  const reader = new FileReader();

  reader.onload = async (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);

    const formattedTasks = jsonData.map((task) => ({
      title: task.title || "Untitled Task", // Ensure lowercase and provide default
      wordcount: task.wordcount || 0,  
      description: task.description || "No Description",
      clientId: task.clientId || "Unknown",
      departmentName: task.departmentName || "",
      soft_deadline: task.soft_deadline ? new Date(task.soft_deadline) : undefined, 
      hard_deadline: task.hard_deadline ? new Date(task.hard_deadline) : undefined,
      status: task.status || "Unassigned",  
    }));

    // ✅ Update UI first
    setTaskData((prevTasks) => [...formattedTasks, ...prevTasks]);

    try {
      // ✅ API call after updating UI
      await importTasksToAPI(formattedTasks);
    } catch (error) {
      console.error("Error while importing tasks:", error);
    }
  };

  reader.readAsArrayBuffer(file);
}

return (
    <>
      <div className="flex flex-col h-5/6 bg-gray-100">
        {/* Main Content */}
        <main className="flex flex-col flex-1 gap-4 p-4 lg:flex-row">
          {/* Task List */}
          <div className="w-full lg:w-2/3 bg-white rounded-lg shadow">
            <div className="p-4">
              {/* Task List Header */}
              <div className="flex items-center justify-between mb-1">
                <h2 className="text-lg font-medium">Task list</h2>
                <div className="relative flex items-center">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="w-full px-3 py-2 pr-10 border rounded focus:outline-none focus:border-blue-500"
                      value={searchTaskQuery}
                      onChange={(e) => setSearchTaskQuery(e.target.value)}
                    />
                    <FaSearch className="absolute w-5 h-5 text-gray-400 right-3 top-2" />
                  </div>
                  <div className="relative ml-2">
      <FaCog
        className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700"
        onClick={() => {
          setShowSettings(!showSettings);
          setShowExportOptions(false); // Close export when settings is toggled
        }}
      />
      {showSettings && (
        <div  ref={closeRef} className="absolute top-full right-0 mt-2 bg-white border rounded-lg shadow-md w-36 min-w-[150px] z-50">
          {(permissions.includes("AddTask") || role === "superadmin" || role === "admin") && (
            <button
              onClick={() => {
                setIsAddTaskModalOpen(true);
                setShowSettings(false);
              }}
              className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
            >
              <FaPlus className="mr-2" /> Add Task
            </button>
          )}
<button
  onClick={() => {
    document.getElementById("fileInput").click(); // File input trigger
    
  }}
  className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
>
  <FaFileImport className="mr-2" /> Import
</button>


          {/* Hidden file input */}
          <input
        type="file"
        id="fileInput"
        accept=".xlsx, .xls"
        className="hidden"
        onChange={handleImportExcel}
      />




                        {/* Export Dropdown */}
                        <div className="relative" ref={exportRef}>
                          <button
                            onClick={() => setShowExportOptions(!showExportOptions)}
                            className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                          >
                            <FaFileExport className="mr-2" /> Export
                          </button>
                          {showExportOptions && (
                            <div className="absolute left-full top-0 ml-2 bg-white border rounded-lg shadow-md w-36">
                              <button

                                onClick={() => {
                                  handleExportPrint();
                                  setShowExportOptions(false);
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                              >
                                <FaPrint className="mr-2" /> Print
                              </button>
                              <button
                                onClick={() => {
                                  handleCopy();
                                  setShowExportOptions(false);
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                              >
                                <FaCopy className="mr-2" /> Copy
                              </button>
                              <button
                                onClick={() => {
                                  handleExportExcel();
                                  setShowExportOptions(false);
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                              >
                                <FaFileExcel className="mr-2" /> Excel
                              </button>
                              <button
                                onClick={() => {
                                  handleExportCSV();
                                  setShowExportOptions(false);
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                              >
                                <FaFileCsv className="mr-2" /> CSV
                              </button>
                              <button
                                onClick={() => {
                                  handleExportPDF();
                                  setShowExportOptions(false);
                                }}
                                className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                              >
                                <FaFilePdf className="mr-2" /> PDF
                              </button>
                            </div>
                          )}
                        </div>

                        <button
                          onClick={() => {
                            handleReload();
                            setShowSettings(false);
                          }}
                          className="flex items-center w-full px-4 py-2 text-sm text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200"
                        >
                          <FaSync className="mr-2" /> Reload
                        </button>
                      </div>
                    )}
                  </div>



                </div>
              </div>
            </div>
             {/* Dropdown for "Filter" */}
            {/* <div className="flex justify-end px-4 pb-2">

              <div className="flex items-center space-x-2 text-sm">
                <span className="text-gray-500">Filter</span>
                <select
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="px-2 py-1 border rounded"
                >
                  <option value={'All'}>All</option>
                  <option value={'Running'}>Running</option>
                  <option value={'QualityCheck'}>Quality Check</option>
                  <option value={'Overdue'}>Overdue</option>
                  <option value={'Delivered'}>Delivered</option>
                  <option value={'Completed'}>Completed</option>
                </select>
                <span className="text-gray-500">entries</span> 
              </div>
            </div> */}



            <div className="px-4 pb-4 space-y-3">
  {filteredTasks.slice(startIndex, endIndex).map((task) => (
    <div
      key={task._id}
      className={`p-3 transition-colors border rounded cursor-pointer hover:bg-gray-50 relative
        ${task.high_priority ? "border-red-500 bg-red-50" : "border-gray-200"}`}
      onClick={() => {
        handleTaskClick(task);
        setShowDropDown(false);
      }}
    >
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col space-y-1">
          <h3 className="font-medium">{task.title}</h3>
          <div className="flex justify-between gap-3">
            <p className="text-sm text-gray-500">[{task.taskId}]</p>
            <p className="text-sm text-gray-500">
              Word-Count:{" "}
              <span className="text-blue-500">{task.wordcount}</span>
            </p>
          </div>
       


                    </div>
                    <div className="flex flex-wrap gap-2 ">
                      <span className="px-3 py-2 text-xs text-green-700 bg-green-100 rounded-md">
                        {new Date(task.soft_deadline).toLocaleDateString()}
                      </span>
                      <span
                        className={`px-3 py-2 text-xs rounded-md ${statusColors[task.status] || "bg-red-100 text-red-700"
                          }`}
                      >
                        {task.status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {loading && <Loading />}
            {totalTaskPages > 1 && (
              <div className="flex justify-center items-center gap-4 my-2">
                <button
                  className={`px-3 py-1 border rounded ${currentPage === 1
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-100"
                    }`}
                  disabled={currentPage === 1}
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(1, prev - 1))
                  }
                >
                  Previous
                </button>

                <span className="text-sm font-medium">
                  Page {currentPage} of {totalTaskPages}
                </span>

                <button
                  className={`px-3 py-1 border rounded ${currentPage === totalTaskPages
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-100"
                    }`}
                  disabled={currentPage === totalTaskPages}
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(totalTaskPages, prev + 1))
                  }
                >
                  Next
                </button>
              </div>
            )}
          </div>

          <div className="lg:w-1/3 sm:w-full w-full relative bg-white rounded-lg shadow-lg">
            {/* Header */}
            <div
              ref={printRef}
              className="p-4 border-b flex items-center justify-between"
            >
              <h2 className="text-xl font-semibold text-gray-800">
                {isTaskLogsVisible
                  ? "Task Logs"
                  : isUploadBriefVisible
                    ? "Upload Brief"
                    : isCommentModalOpen
                      ? commentType === "query"
                        ? "Raise Query"
                        : "Add Comment"
                      : isUploadPageVisible
                        ? "Upload Page"
                        : "Task Details"}


              </h2>
              <div className="flex space-x-2 relative">
                {/* Always visible icons */}
                <button className="p-2 text-gray-600 rounded-full hover:bg-gray-100" onClick={() => handleTaskClick(selectedTask)}>
                  <FaInfo className="w-5 h-5" />
                </button>
                <button className="p-2 text-gray-600 rounded-full hover:bg-gray-100" onClick={handleUpload}>
                  <FaUpload className="w-5 h-5" />
                </button>
                <button className="p-2 text-gray-600 rounded-full hover:bg-gray-100" onClick={handleUploadBrief}>
                  <FaFileUpload className="w-5 h-5" />
                </button>

                {/* Three dots menu */}
                <button
                  ref={dropdownRef}
                  className="p-2 text-gray-600 rounded-full hover:bg-gray-200 transition"
                  onClick={() => setShowDropDown(!showDropDown)}
                >
                  <FiMoreVertical className="w-5 h-5" />
                </button>

                {/* Dropdown menu */}
                {showDropDown && (
                  <div
                    ref={closeRef}
                    className="absolute right-0 mt-2 w-44 bg-white shadow-lg rounded-xl py-2 z-10 border border-gray-200"
                  >
                    {(permissions.includes("SeeLogs") || (role === 'superadmin' || role === 'admin')) && <button
                      className="flex items-center gap-3 w-full px-4 py-3 text-gray-700 hover:bg-gray-100 transition rounded-t-xl"
                      onClick={() => { handleOpenTaskLogs(); setShowDropDown(false); }}
                    >
                      <MdOutlineAccountTree className="w-5 h-5 text-gray-600" />
                      <span className="text-sm font-medium">Task Logs</span>
                    </button>}

                    <button
                      className="flex items-center gap-3 w-full px-4 py-3 text-gray-700 hover:bg-gray-100 transition"
                      onClick={() => { handlePrint(); setShowDropDown(false); }}
                    >
                      <FiPrinter className="w-5 h-5 text-gray-600" />
                      <span className="text-sm font-medium">Print</span>
                    </button>

                    {(permissions.includes("SeeComments") || (role === 'superadmin' || role === 'admin')) && <button
                      className="flex items-center gap-3 w-full px-4 py-3 text-gray-700 hover:bg-gray-100 transition"
                      onClick={() => {
                        handleCommentModal("comment"); setShowDropDown(false);
                      }}
                    >
                      <FaCommentDots className="w-5 h-5 text-gray-600" />
                      <span className="text-sm font-medium">Add Comment</span>
                    </button>}

                    {(permissions.includes("SeeQuery") || (role === 'superadmin' || role === 'admin')) && <button
                      className="flex items-center gap-3 w-full px-4 py-3 text-gray-700 hover:bg-gray-100 transition rounded-b-xl"
                      onClick={() => { handleCommentModal("query"); setShowDropDown(false); }}
                    >
                      <FaQuestionCircle className="w-5 h-5 text-gray-600" />
                      <span className="text-sm font-medium">Raise Query</span>
                    </button>}
                  </div>
                )}

              </div>
            </div>

            {/* Content */}
            <div className="p-4 w-full h-[85%] flex flex-col items-center ">
              {isTaskLogsVisible ? (
                <div ref={printRef} className="w-full p-6 bg-white rounded-lg">
                  <h2 className="text-lg font-semibold text-gray-800 text-center">
                    {selectedTask?.title || "Untitled Task"}
                  </h2>

                  <div className="mt-2 space-y-2 text-gray-600">
                    <p>
                      <span className="font-medium">Status:</span>
                      {"  "}
                      <span
                        className={`px-2 py-1 text-sm font-medium rounded-md ${statusColors[selectedTask?.status] ||
                          "bg-red-100 text-red-700"
                          }`}
                      >
                        {selectedTask?.status || "Unknown"}
                      </span>
                    </p>
                    <div className="mt-4">
                      <h3 className="font-medium text-gray-700">Logs:</h3>
                      <div className="bg-gray-100 p-3 rounded-md text-sm text-gray-700">
                        {selectedTask?.iterations &&
                          selectedTask?.iterations.length > 0 ? (
                          <ul className="list-disc list-inside space-y-1">
                            {selectedTask.iterations.map((log, index) => (
                              <React.Fragment key={index}>
                                <li>
                                  {names[log.assigned_by] || "N/A"}{" "}
                                  <span className="font-medium text-gray-800">
                                    {log.status === "Running"
                                      ? "Assigned to"
                                      : log.status}
                                  </span>{" "}
                                  {names[log.assigned_to] || "N/A"}{" "}
                                  <span className="font-medium text-gray-800">
                                    {`(${new Date(log.createdAt).toLocaleString()})`}
                                    </span>
                                </li>
                                {log.status === "Running" && (
                                  <li>
                                    {names[log.assigned_to] || "N/A"}{" "}
                                    <span className="font-medium text-gray-800">
                                      is working on.
                                    </span>
                                  </li>
                                )}
                              </React.Fragment>
                            ))}
                          </ul>
                        ) : (
                          <p>No logs available.</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-center mt-6">
                    <button
                      className="px-6 py-2 bg-[#3758ff] hover:bg-blue-900 text-white font-semibold rounded-md shadow transition"
                      onClick={handleCloseTaskLogs}
                    >
                      Close Logs
                    </button>
                  </div>
                </div>
              ) : isUploadBriefVisible ? (
                <div
                  ref={printRef}
                  className="w-full h-[100%] space-y-6 p-4 bg-white shadow rounded-lg"
                >
                  {(permissions.includes("AddBrief") || (role === 'superadmin' || role === 'admin')) && <>
                    <input
                      type="file"
                      id="file"
                      onChange={handleFileChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 focus:outline-none text-sm"
                    />
                    <button
                      className="mt-4 mr-2 px-4 py-2 bg-blue-600 text-white rounded-lg"
                      onClick={handleBriefSubmit}
                      disabled={isUploading}
                    >
                      {isUploading ? "Uploading..." : "Submit Brief"}
                    </button>
                    <button
                      className="mt-4 px-4 py-2 bg-gray-600 text-white rounded-lg"
                      onClick={handleCloseUploadPage}
                    >
                      Close
                    </button>
                  </>}

                  {/* Display uploaded brief */}
                  {brief && Array.isArray(brief) && brief.length > 0 && (
                    <div className="w-full h-[70%] space-y-6 p-4 bg-white shadow rounded-lg mt-6 overflow-auto scrollbar-thin">
                      <h3 className="text-lg font-medium text-gray-800">Uploaded Brief</h3>
                      {brief.map((item, index) => (
                        <div key={index} className="text-sm text-gray-600">
                          <p>
                            <strong>Uploaded By:</strong> {item.employeeName || "Unknown"}
                          </p>
                          <p>
                            <strong>File:</strong>
                          </p>
                          <ul>
                            <li className="flex items-center gap-2">
                              {item.uploadPath && item.name && (
                                <>
                                  {/* Check the file type */}
                                  {["jpg", "jpeg", "png", "gif"].includes(item.name.split(".").pop().toLowerCase()) ? (
                                    <FaFileImage
                                      className="text-blue-500 cursor-pointer"
                                      size={20}
                                      onClick={() => window.open(item.uploadPath, "_blank")}
                                    />
                                  ) : item.name.split(".").pop().toLowerCase() === "pdf" ? (
                                    <FaFilePdf
                                      className="text-red-500 cursor-pointer"
                                      size={20}
                                      onClick={() => window.open(item.uploadPath, "_blank")}
                                    />
                                  ) : (
                                    <FaFileAlt
                                      className="text-gray-500 cursor-pointer"
                                      size={20}
                                      onClick={() => window.open(item.uploadPath, "_blank")}
                                    />
                                  )}
                                  <span className="text-sm text-gray-600">
                                    {item.name}
                                  </span>
                                </>
                              )}
                            </li>
                          </ul>
                          <p>
                            <strong>Timestamp:</strong> {new Date(item.updatedAt).toLocaleString()}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}

                </div>
              ) : isCommentModalOpen && selectedTask?._id ? (

                <CommentModal
                  isOpen={isCommentModalOpen}
                  taskId={selectedTask?._id}
                  type={commentType} // Pass the type to differentiate
                  onClose={() => setIsCommentModalOpen(false)}
                />

              ) : isUploadPageVisible ? (
                <div
                  ref={printRef}
                  className="w-full h-[100%] space-y-6 p-4 bg-white shadow rounded-lg"
                >
                  {(permissions.includes("AddSolution") || (role === 'superadmin' || role === 'admin')) && <>
                    <input
                      type="file"
                      id="file"
                      onChange={handleFileChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 focus:outline-none text-sm"
                    />
                    {uploadError && (
                      <div className="text-red-500 text-sm mt-2">
                        {uploadError}
                      </div>
                    )}
                    <button
                      className="mt-4 mr-2 px-4 py-2 bg-blue-600 text-white rounded-lg"
                      onClick={handleSubmit}
                      disabled={isUploading}
                    >
                      {isUploading ? "Uploading..." : "Submit Solution"}
                    </button>
                    <button
                      className="mt-4 px-4 py-2 bg-gray-600 text-white rounded-lg"
                      onClick={handleCloseUploadPage}
                    >
                      Close
                    </button>
                  </>}

                  {solution && solution.length > 0 && (
                    <div className="w-full h-[70%] space-y-6 p-4 bg-white shadow rounded-lg mt-6 overflow-auto scrollbar-thin">
                      <h3 className="text-lg font-medium text-gray-800">
                        Uploaded Solution
                      </h3>
                      {solution.map((item, index) => (
                        <div key={index} className="text-sm text-gray-600">
                          <p>
                            <strong>Uploaded By:</strong> {names[item.employeeId] || "Unknown"}
                          </p>
                          {/* <p>
                            <strong>Task ID:</strong> {item.taskId}
                          </p>
                          <p>
                            <strong>Topic:</strong> {item.topic}
                          </p> */}
                          <p>
                            <strong>Files:</strong>
                          </p>
                          <ul>
                            {item.files.map((file, fileIndex) => {
                              const fileExtension = file
                                .split(".")
                                .pop()
                                .toLowerCase();
                              const isImage = [
                                "jpg",
                                "jpeg",
                                "png",
                                "gif",
                              ].includes(fileExtension);
                              const isPDF = fileExtension === "pdf";
                              const handleOpenFile = (file) => {
                                if (isImage) {
                                  window.open(file, "_blank");
                                } else if (isPDF) {
                                  window.open(file, "_blank");
                                }
                              };

                              const fileName = file.split("/").pop();
                              return (
                                <li
                                  key={fileIndex}
                                  className="flex items-center gap-2"
                                >
                                  {isImage && (
                                    <>
                                      <FaFileImage
                                        className="text-blue-500 cursor-pointer"
                                        size={20}
                                        onClick={() => handleOpenFile(file)}
                                      />
                                      <span className="text-sm text-gray-600">
                                        {file.split("/").pop()}
                                      </span>
                                    </>
                                  )}
                                  {isPDF && (
                                    <>
                                      <FaFilePdf
                                        className="text-red-500 cursor-pointer"
                                        size={20}
                                        onClick={() => handleOpenFile(file)}
                                      />
                                      <span className="text-sm text-gray-600">
                                        {file.split("/").pop()}
                                      </span>
                                    </>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                          <p>
                            <strong>Timestamp:</strong>{" "}
                            {new Date(item.timestamp).toLocaleString()}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : selectedTask ? (
                <div
                  ref={printRef}
                  className="w-full h-full scrollbar-thin overflow-auto lg:overflow-hidden lg:h-2/3 space-y-6 p-4 bg-white "
                >
                  {/* Task Details Section */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                      <div className="h-14 w-14 rounded-full bg-blue-100 flex items-center justify-center">
                        <span className="text-blue-600 text-xl font-semibold uppercase">
                          {selectedTask?.title?.[0] || "T"}
                        </span>
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {selectedTask?.title}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Task ID: {selectedTask?.taskId}
                        </p>
                      </div>
                    </div>

                    {/* Three-dot Menu */}
                    <div className="">
                      <button className="p-0 pb-6 text-gray-600 rounded-full">
                        <FiMoreVertical
                          className="w-4 h-4"
                          onClick={handleToggleDropdown}
                        />
                      </button>
                      {isDropDownOpen && (
                        <div
                          ref={dropdownRef}
                          className="absolute right-12 top-1/4 w-48 bg-white border rounded-lg shadow-lg z-10"
                        >
                          {(permissions.includes("AssignTask") || (role === 'superadmin' || role === 'admin')) && <button
                            className="block w-full px-4 py-2 text-left text-sm text-gray-800 hover:bg-gray-100"
                            onClick={() => setIsAssinTaskModalOpen(true)}
                          >
                            Assign
                          </button>}
                          {(permissions.includes("SeeTransaction") || (role === 'superadmin' || role === 'admin')) && <button className="block w-full px-4 py-2 text-left text-sm text-gray-800 hover:bg-gray-100"
                            onClick={() => setIsPaymentButtonClicked()}>
                            Payment
                          </button>}
                          {(permissions.includes("CompleteTask") || (role === 'superadmin' || role === 'admin')) && <button
                            ref={dropdownRef}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-800 hover:bg-gray-100"
                            onClick={handleCompleteTask}
                          >
                            Complete
                          </button>}
                          {(permissions.includes("DeliverTask") || (role === 'superadmin' || role === 'admin')) && <button
                            className="block w-full px-4 py-2 text-left text-sm text-gray-800 hover:bg-gray-100"
                            onClick={handleDeliverTask}
                          >
                            Delivered
                          </button>}
                          <div className="border-t mt-2">
                            {(permissions.includes("UpdateTask") || (role === 'superadmin' || role === 'admin')) && <button
                              className="block w-full px-4 py-2 text-left text-sm text-blue-600 hover:bg-gray-100"
                              onClick={() => openTaskModal(selectedTask)}
                            >
                              Edit Task
                            </button>}
                            {(permissions.includes("DeleteTask") || (role === 'superadmin' || role === 'admin')) && <button
                              className="block w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
                              onClick={() => handleDelete(selectedTask?._id)}
                            >
                              Delete Task
                            </button>}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Task Details Section */}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {(permissions.includes("SeeClient") || (role === 'superadmin' || role === 'admin')) && <div>
                      <span className="text-sm font-medium text-gray-600">
                        Client:
                      </span>
                      <p className="text-gray-800">
                        {selectedTask?.clientName || "N/A"}
                      </p>
                    </div>}
                    <div>
                      <span className="text-sm font-medium text-gray-600">
                        Department:
                      </span>
                      <p className="text-gray-800">
                        {selectedTask?.departmentName || "N/A"}
                      </p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-600">
                        Word Count:
                      </span>
                      <p className="text-gray-800">{selectedTask?.wordcount}</p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-600">
                        Status:
                      </span>
                      <p
                        className={`font-medium ${selectedTask?.status === "Running"
                          ? "text-green-700"
                          : "text-red-700"
                          }`}
                      >
                        {selectedTask?.status}
                      </p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-600">
                        Soft Deadline:
                      </span>
                      <p className="text-gray-800">
                        {new Date(
                          selectedTask?.soft_deadline
                        ).toLocaleDateString()}
                      </p>
                    </div>
                    <div>
                      <span className="text-sm font-medium text-gray-600">
                        Hard Deadline:
                      </span>
                      <p className="text-gray-800">
                        {new Date(
                          selectedTask?.hard_deadline
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>

                  {/* Description */}
                  <div>
                    <span className="text-sm font-medium text-gray-600">
                      Description:
                    </span>
                    <p className="text-gray-800">{selectedTask?.description}</p>
                  </div>
                </div>
              ) : (
                <div className="text-center text-gray-500">
                  <p>No Task Selected</p>
                  <p>Select a task to view details</p>
                </div>
              )}
            </div>
          </div>
        </main >
      </div >
      {isAssinTaskModalOpen && (
        <AssignTask
          taskId={selectedTask?._id}
          closeAssignTaskModal={closeAssignTaskModal}
          setIsAssinTaskModalOpen={setIsAssinTaskModalOpen}
          fetchTaskData = {fetchTaskData}
        />
      )}

      {
        isAddTaskModalOpen && (
          <AddTask
            closeAddTaskModal={closeAddTaskModal}
            setIsAddTaskModalOpen={setIsAddTaskModalOpen}
            editingTask={editingTask}
            fetchTaskData = {fetchTaskData}
          />
        )
      }


    </>
  );
};

export default ApprovedTask;
