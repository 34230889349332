import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AddPostModal = ({ isOpen, onClose, onAddPost, post }) => {
  const [formData, setFormData] = useState({ topic: "", platform: "" });
  const [file, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // ✅ Prevent multiple submissions
  const apiUrl = process.env.REACT_APP_API_URL;

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "video/mp4", "audio/mpeg", "audio/wav"];

  useEffect(() => {
    if (post) {
      setFormData({ topic: post.topic || "", platform: post.platform || "" });
      setFile(post.postPhoto || null);
    } else {
      setFormData({ topic: "", platform: "" });
      setFile(null);
    }
  }, [post]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return toast.error("Please select a file.");

    if (allowedTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      toast.error("Invalid file type!");
    }
  };

  const handleSubmit = async () => {
    if (!formData.topic || !formData.platform) {
      return toast.error("Please fill all fields.");
    }

    if (isSubmitting) return; // ✅ Prevent duplicate requests
    setIsSubmitting(true); // ✅ Lock button while submitting

    const postData = new FormData();
    postData.append("topic", formData.topic);
    postData.append("platform", formData.platform);
    if (file instanceof File) postData.append("postPhoto", file);

    try {
      const endpoint = post ? `${apiUrl}/api/posts/edit-post/${post._id}` : `${apiUrl}/api/posts/create-post`;
      const method = post ? axios.put : axios.post;
      const response = await method(endpoint, postData, { withCredentials: true });

      if (response.data.success) {
        onAddPost(response.data.data);
        toast.success(post ? "Post updated successfully!" : "Post created successfully!");
        onClose();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting post:", error);
      toast.error("Failed to submit post.");
    } finally {
      setIsSubmitting(false); // ✅ Unlock button after API response
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-55 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 sm:p-8 w-full max-w-lg max-h-[70vh] overflow-y-auto relative scrollbar-thin">
        <button onClick={onClose} className="absolute top-3 right-3 text-gray-600 hover:text-gray-800">✖</button>

        <div className="text-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">{post ? "Edit Post" : "Create a New Post"}</h2>
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <label className="block text-gray-700 font-medium">Title</label>
            <input type="text" name="topic" value={formData.topic} onChange={handleChange} className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500" placeholder="Enter post title" />
          </div>

          <div>
            <label className="block text-gray-700 font-medium">Platform</label>
            <select name="platform" value={formData.platform} onChange={handleChange} className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500">
              <option value="">Select</option>
              <option value="Blog">Blog</option>
              <option value="Twitter">Twitter</option>
              <option value="Instagram">Instagram</option>
            </select>
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">Upload Image</label>
            {file && typeof file === "string" && (
              <div className="mb-2">
                <img src={file} alt="Uploaded Preview" className="w-full h-40 object-cover rounded-md" />
              </div>
            )}
            <input type="file" accept="image/*" onChange={handleFileChange} className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100 cursor-pointer" />
          </div>
        </div>

        <div className="mt-6 flex justify-end gap-3">
          <button onClick={onClose} className="px-5 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400">Cancel</button>
          <button onClick={handleSubmit} disabled={isSubmitting} className={`px-5 py-2 rounded-md ${isSubmitting ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 text-white hover:bg-blue-700"}`}>
            {isSubmitting ? "Adding..." : post ? "Update Post" : "Add Post"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddPostModal;
