import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../../components/Table';
import { useRole } from '../../context/RoleContext';
import ViewTaskReport from './ViewTaskReport';
import { usePermissions } from '../../context/PermissionContext';

const TaskReport = () => {
    const [data, setData] = useState([]);
    const [entries, setEntries] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const [toggle, setToggle] = useState('Total');
    const [startDate, setStartDate] = useState(new Date(2000, 0, 1));
    const [endDate, setEndDate] = useState(new Date());
    const [statusSummary, setStatusSummary] = useState({});
    const [activeStatus, setActiveStatus] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const { role } = useRole();
    const { permissions } = usePermissions();
    const apiUrl = process.env.REACT_APP_API_URL;
    const isAdmin = role === "admin" || role === "superadmin";
    const isFullReport = permissions.includes("Full Report");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const storedSearch = sessionStorage.getItem('clientId');
        if (storedSearch && dataLoaded) {
            handleOnSearch(storedSearch);
            setTimeout(() => {
                sessionStorage.removeItem('clientId');
            }, 2000);
        }
    }, [dataLoaded]);

    // Function to set date range dynamically
    const handleToggleChange = (filter) => {
        setToggle(filter);
        const today = new Date();
        let newStartDate, newEndDate;

        if (filter === 'Last Month') {
            newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        } else if (filter === 'Last Year') {
            newStartDate = new Date(today.getFullYear() - 1, 0, 1);
            newEndDate = new Date(today.getFullYear() - 1, 11, 31);
        } else { // 'total'
            newStartDate = new Date(2000, 0, 1); // A very old date to include all records
            newEndDate = today;
        }
        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(newEndDate.toISOString().split('T')[0]);
    };

    useEffect(() => {
        let isMounted = true; // Track component mounting status

        if (startDate && endDate) {
            fetchReportData();
        }

        async function fetchReportData() {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/api/reports/${(isFullReport || isAdmin) ? 'get-task-reports' : 'get-task-specific-reports'}`, {
                    withCredentials: true,
                    params: { startDate, endDate },
                });

                if (isMounted) { // Update state only if the component is still mounted
                    setData(response.data.tasks);
                    setFilteredData(response.data.tasks);
                    setStatusSummary(response.data.statusSummary);
                    setDataLoaded(true); // Mark data as loaded
                }
            } catch (error) {
                console.error(`Error fetching report:`, error);
            } finally {
                setLoading(false);
            }
        }

        // Cleanup function to avoid memory leaks and double calls
        return () => {
            isMounted = false;
        };
    }, [toggle, startDate, endDate]);

   const handleTaskReportEntriesChange = (event) => {
        setEntries(event)
        setCurrentPage(1); // Reset to the first page when changing entries per page
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

    const handleOnSearch = (value) => {
        const searchValue = value?.toLowerCase();
        if (!searchValue) {
            setFilteredData(data);
            return;
        }
        const filteredResults = data.filter((item) =>
            item.clientId?._id?.includes(searchValue) ||
            item.taskId?.toLowerCase().includes(searchValue) ||
            item.title?.toLowerCase().includes(searchValue) ||
            item.status?.toLowerCase().includes(searchValue)
        );
        setFilteredData(filteredResults);
    };

    const startIndex = (currentPage - 1) * entries;
    const TaskReportPaginatedData = filteredData.slice(startIndex, startIndex + entries);

    const columns = [
        { header: "Task ID", accessor: "taskId" },
        ...(isAdmin ? [{ header: "Client", accessor: "client" }] : []),
        { header: "Title", accessor: "title" },
        { header: "Status", accessor: "status" },
        { header: "Word Count", accessor: "wordcount" },
        ...(isAdmin ? [{ header: "Soft Deadline", accessor: "soft_deadline" }] : []),
        ...(isAdmin ? [{ header: "Hard Deadline", accessor: "hard_deadline" }] : []),
        ...(isAdmin ? [{ header: "Created At", accessor: "createdAt" }] : []),
        // ...(isAdmin ? [{ header: "Updated At", accessor: "updatedAt" }] : []),
    ];

    const onClose = () => {
        setIsModalOpen(false);
        setSelectedTask(null);
    };
    const handleRowClick = (row) => {
        setSelectedTask(row);
        setIsModalOpen(true);
    };

    const handleStatusClick = (status) => {
        console.log("status", status);
        if (status === "Total") {
            setActiveStatus("");
            setFilteredData([...data]);
            return
        }
        const filteredResults = data.filter((item) =>
            item.status?.toLowerCase().includes(status.toLowerCase())
        );
        console.log("active status", status);
        setActiveStatus(status);
        setFilteredData(filteredResults);
    }
    return (
        <div className='p-4'>
            <div className='bg-white shadow-lg rounded-lg p-4 mb-6'>
                <h3 className='text-xl font-semibold text-gray-800 mb-4'>Task Report - {toggle.charAt(0).toUpperCase() + toggle.slice(1)}</h3>

                {/* Toggle Buttons */}
                <div className='flex flex-col md:flex-row md:items-center justify-between space-y-4 md:space-y-0'>
                    {/* Toggle Buttons */}
                    <div className='flex flex-wrap justify-center md:justify-start gap-2'>
                        {['Last Month', 'Last Year', 'Total'].map((item) => (
                            <button
                                key={item}
                                onClick={() => handleToggleChange(item)}
                                className={`px-4 py-2 rounded-md font-semibold transition-colors duration-300 ${toggle === item ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
                            >
                                {item.charAt(0).toUpperCase() + item.slice(1)}
                            </button>
                        ))}
                    </div>

                    {/* Date Pickers */}
                    <div className='flex flex-wrap justify-center md:justify-end gap-2'>
                        <input
                            type='date'
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className='px-3 py-2 border rounded-md w-full sm:w-auto'
                        />
                        <input
                            type='date'
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className='px-3 py-2 border rounded-md w-full sm:w-auto'
                        />
                    </div>
                </div>

                {/* Status Summary */}
                <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4 mt-4'>
                    {Object.entries(statusSummary).map(([status, count]) => (
                        <div key={status} onClick={() => handleStatusClick(status)} className={`${activeStatus === status ? 'bg-blue-200 text-white' : 'bg-gray-200 text-gray-700 hover:border-dark-accent hover:border-2'} cursor-pointer p-3 rounded-md shadow-sm text-center`}>
                            <span className='text-sm font-semibold text-gray-700'>{status}</span>
                            <p className='text-lg font-bold text-blue-600'>{count}</p>
                        </div>
                    ))}
                </div>

            </div>

            {/* Table */}
            <div className='py-6'>
                <Table
                    title={`Task Report`}
                    columns={columns}
                    data={TaskReportPaginatedData}
                    onSearch={handleOnSearch}
                    onEntriesChange={handleTaskReportEntriesChange}
                    entriesOptions={[5, 10, 25, 50, 100, data.length]}
                    entries={entries}
                    currentPage={currentPage}
                    totalPages={Math.ceil(filteredData.length / entries)}
                    onPageChange={handlePageChange}
                   totalEntries={filteredData.length}
                    exportButton={true}
                    onRowClick={
                        (row) => {
                            handleRowClick(row);
                        }
                    }
                    loading={loading}
                />
            </div>
            {isModalOpen && (
                <ViewTaskReport isModalOpen={isModalOpen} closeModal={onClose} isAdmin={isAdmin} selectedTask={selectedTask} />
            )}
        </div>
    );
};

export default TaskReport;
