import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import useOutsideClick from '../../Hooks/useClickOutside';

const AddTransactions = ({ closeAddModal, setIsAddModalOpen, fetchTransactions, }) => {
    const [formData, setFormData] = useState({
        clientDetail: "",
        amount: "",
        currency: "",
        payment_amount: "",
        taskDetails: "",
        transactionID: "",
    });
    const [transactionToEdit, setTransactionToEdit] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [clients, setClients] = useState([]);
    const [tasks, setTasks] = useState([]);
    const currencyOptions = ["euro", "usd", "dollar", "inr"];

    useEffect(() => {
        fetchClients();
    }, [apiUrl]);

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/clients/get-clients`, { withCredentials: true });
            setClients(response.data.data);
        } catch (err) {
            console.error("Error fetching clients:", err);
        }
    };

    const fetchTasksByClient = async (clientId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/fetchTaskwithClientID/${clientId}`, { withCredentials: true });
            setTasks(response.data);
        } catch (err) {
            console.error("Error fetching tasks:", err);
        }
    };

    // useEffect(() => {
    //     if (formData.clientDetail) {
    //         fetchTasksByClient(formData.clientDetail);
    //     }
    // }, [formData.clientDetail]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === "clientDetail") {
            fetchTasksByClient(e.target.value);
        }
        // console.log("formData--------->",formData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        try {
            const response = await axios.post(`${apiUrl}/api/transactions/create`, formData, { withCredentials: true });
            // console.log("Transaction processed:", response.data);
            setLoading(false);
            setIsAddModalOpen(false);
            fetchTransactions();
        } catch (err) {
            console.error("Error processing transaction:", err);
            setError(err.response?.data?.message || "Failed to process transaction");
            setLoading(false);
        }
    };

    const getAmmount = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/transactions/getAmount/${formData.taskDetails}`, { withCredentials: true });
            setFormData({ ...formData, amount: response.data.amount, currency: response.data.currency });
            setTransactionToEdit(response.data);
            if(formData.taskDetails === ""){
                setTransactionToEdit(null);
            }
        } catch (err) {
            console.error("Error fetching amount:", err);
        }
    };
    useEffect(() => {

        if (formData.taskDetails) {
            getAmmount()
        }
        
    }, [formData.taskDetails]);
    const closeRef = useRef(null);
    useOutsideClick(closeRef, () => closeAddModal(false));

    return (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
            <div ref={closeRef} className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
                <div className="flex justify-between items-center border-b pb-4">
                    <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">{"Add Transaction"}</h2>
                    <button
                        className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
                        onClick={closeAddModal}
                    >
                        &times;
                    </button>
                </div>

                <div className="max-h-[350px] overflow-y-scroll mt-6 scrollbar-thin">
                    {error && <p className="text-red-500">{error}</p>}
                    <form className="space-y-6 px-4" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="clientDetail" className="block text-sm font-medium text-gray-700">
                                Client
                            </label>
                            <select
                                id="clientDetail"
                                name="clientDetail"
                                value={formData.clientDetail}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            >
                                <option value="" disabled>Select Client</option>
                                {clients.map(client => (
                                    <option key={client._id} value={client._id}>{client.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label htmlFor="taskDetails" className="block text-sm font-medium text-gray-700">
                                Task
                            </label>
                            <select
                                id="taskDetails"
                                name="taskDetails"
                                value={formData.taskDetails}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            >
                                <option value="" disabled>Select Task</option>
                                {tasks.map(task => (
                                    <option key={task._id} value={task._id}>{task.taskId} - {task.title}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                                Currency
                            </label>
                            <select
                                id="currency"
                                name="currency"
                                value={formData.currency}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            >
                                <option value="" disabled>Select Currency</option>
                                {currencyOptions.map(currency => (
                                    <option key={currency} value={currency}>
                                        {currency.toUpperCase()}
                                    </option>
                                ))}

                            </select>
                        </div>

                        <div>
                            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                Total Amount
                            </label>
                            <input
                                type="number"
                                id="amount"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            />
                        </div>
                        {transactionToEdit && transactionToEdit.logs && (
                            <div className="mt-6 b">
                                <h3 className="text-lg font-semibold text-gray-800">Last Payments</h3>
                                {transactionToEdit.logs.map((log, index) => (
                                    <div key={index}>
                                        <div>
                                            {/* <p className="block text-sm font-medium text-gray-700">
                                                Paid Amount
                                            </p> */}
                                            <p className="block text-right text-sm font-medium text-gray-700">Paid on {new Date(log.paidAt).toLocaleString()}</p>
                                            <div
                                                className="mt-1 block w-full px-4 py-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"

                                            >{log.paidAmount}
                                            </div>
                                            
                                        </div>
                                    </div>

                                    // <li key={index} className="bg-gray-100 p-3 rounded-lg">
                                    //     <p>Paid Amount: {log.paidAmount}</p>
                                    //     <p>Left Amount: {log.leftAmount}</p>
                                    //     <p>Paid At: {new Date(log.paidAt).toLocaleString()}</p>
                                    // </li>
                                ))}
                                <div className='mt-2'>
                                    <p className="block text-sm font-medium text-red-500">
                                        Left Amount
                                    </p>
                                    <div
                                        className="mt-1 block w-full text-red-500 px-4 py-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"

                                    >{transactionToEdit.logs[transactionToEdit.logs.length - 1].leftAmount}
                                    </div>

                                </div>

                            </div>
                        )}

                        <div>
                            <label htmlFor="payment_amount" className="block text-sm font-medium text-gray-700">
                                Payment Amount
                            </label>
                            <input
                                type="number"
                                id="payment_amount"
                                name="payment_amount"
                                value={formData.payment_amount}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor="transactionID" className="block text-sm font-medium text-gray-700">
                                Transaction ID
                            </label>
                            <input
                                type="text"
                                id="transactionID"
                                name="transactionID"
                                value={formData.transactionID}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            />
                        </div>

                        <div className="flex justify-end space-x-4 mt-6">
                            <button
                                type="button"
                                className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition"
                                onClick={closeAddModal}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-blue-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-blue-600 transition"
                                disabled={loading}
                            >
                                {loading ? "Processing..." : transactionToEdit ? "Update Transaction" : "Add Transaction"}
                            </button>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    );
};

export default AddTransactions;
