import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import AddClients from "./AddClients";
import axios from "axios";
import ViewClients from "./ViewClients";
import { useSocket } from '../../context/SocketContext'
import EmailModal from "../../components/EmailModal ";
import { toast } from "react-toastify";

const AllClients = () => {
  // State variables
  const [clientData, setClientData] = useState([]); // Holds client data
  const [searchQuery, setSearchQuery] = useState(""); // Holds search input value
  const [selectedClient, setSelectedClient] = useState(null); // Stores selected client details
  const [isModalOpen, setIsModalOpen] = useState(false); // Controls client details modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // Controls add/edit client modal
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const { socket } = useSocket()
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const apiUrl = process.env.REACT_APP_API_URL; // API base URL
  
  // Fetch clients from API
  useEffect(() => {
    fetchClients();
  }, []);

  // useEffect(() =>{
  //     const requestNotificationPermission = async () => {
  //       if ("Notification" in window) {
  //         const permission = await Notification.requestPermission();
  //         if (permission !== "granted") {
  //           alert("Permission denied for notifications")
  //         }
  //       }
  //     };
  
  //     const handleAssignClient = ({message}) =>{
  //       new Notification(`New message`, {
  //         body: message,
  //         // icon: <IoMdNotifications />,
  //       });
  //       // console.log("message assign", message)
  //     }
  //     requestNotificationPermission()
  //     if(socket){
  //       socket.on("client-assigned",handleAssignClient)
  //     }
      
  //   }, [socket, isModalOpen])

  const fetchClients = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/clients/get-clients`, {
        withCredentials: true,
      });
      setClientData(response.data.data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch specific client data when a row is clicked
  const handleRowClick = (clientId) => {
    
      setSelectedClient(clientId);
      setIsModalOpen(true);
    
  };
  // const handleRowClick = async (clientId) => {
  //   try {
  //     const response = await axios.get(
  //       `${apiUrl}/api/clients/get-clients-data/${clientId}`,
  //       { withCredentials: true }
  //     );
  //     setSelectedClient(response.data.data);
  //     setIsModalOpen(true);
  //   } catch (error) {
  //     console.error("Error fetching client data:", error);
  //   }
  // };

  // Close the client details modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  // Open the add/edit client modal
  const openEditModal = () => {
    setIsModalOpen(false);
    setIsAddModalOpen(true);
  };

  // Add a new client to the list
  const addNewClient = (newClient) => {
    setClientData((prevClients) => [...prevClients, newClient]);
  };

  // Refresh the client list
  const handleReload = () => {
    fetchClients();
  };
  const handleSendMail = (employee) => {
    setSelectedClient(employee);
    setIsModalOpen(false); // Ensure ViewClients is closed
    setEmailModalOpen(true);
  };
  const handleEmailSubmit = async (subject, message) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/api/sendmail`, {
        to: selectedClient?.email,
        subject,
        text: message,
      },{
        withCredentials: true
      });
      toast.success(response.data.message);
      setEmailModalOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error sending email");
    } finally {
      setLoading(false);
    }
  };

  
  // Define table columns
  const columns = [

    { header: "Client ID", accessor: "clientId" },
    { header: "Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "University", accessor: "university" },
    { header: "Assign to", accessor: "employeeName" },
    { header: "Address", accessor: "address" },
    { header: "Country", accessor: "country" },
    {
      header: "Status",
      accessor: "status",
      className: "text-green-600 font-semibold",
    },
    {
      header: "Mail",
      accessor: "mail",
      render: (row) => <button onClick={() => handleSendMail(row)}>📧</button>,
    },
  ];

  // Define table actions (buttons)
  const actions = [
    { label: "🔄 Reload", onClick: handleReload },
    {
      label: "➕ Add Client",
      primary: true,
      onClick: () => {
        setSelectedClient(null); // Reset selected client
        setIsAddModalOpen(true); // Open Add Client modal
      },
    },
  ];

  // Close the Add Client modal
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  // Handle search input
  const handleSearch = (searchValue) => {
    setSearchQuery(searchValue?.toLowerCase());
  };
  const handleEntriesChange = (value) => {
    setEntriesPerPage(value);
    setCurrentPage(1);
  };



  // Filter client data based on search query
  const filteredData = clientData.filter((client) => {
    return (
      client?.name?.toLowerCase()?.includes(searchQuery) ||
      client?.email?.toLowerCase()?.includes(searchQuery) ||
      client?.country?.toLowerCase()?.includes(searchQuery) ||
      client?.phone?.toLowerCase()?.includes(searchQuery) ||
      client?.university?.toLowerCase()?.includes(searchQuery) ||
      client?.status?.toLowerCase()?.includes(searchQuery) ||
      client?.assigned_to?.toLowerCase()?.includes(searchQuery) ||
      client?.updated_by?.toLowerCase()?.includes(searchQuery) ||
      client?.clientId?.toLowerCase()?.includes(searchQuery) ||
      client?.taskId?.toLowerCase()?.includes(searchQuery)


    );
  });
  const startIndex = (currentPage - 1) * entriesPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + entriesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="p-4">
        <Table
          title="Client Table"
          columns={columns}
          data={paginatedData} // Use filtered data
          actions={actions}
          exportButton={true}
          importButton={true}
          dataType="clients"
          onRowClick={(row) => handleRowClick(row)}
          onEntriesChange={handleEntriesChange}
          // onRowClick={(row) => handleRowClick(row.clientId)}
          onSearch={handleSearch}
          entriesOptions={[5, 10, 25, 50, 100, clientData.length]}
          loading={loading}
          currentPage={currentPage}
          totalPages={Math.ceil(filteredData.length / entriesPerPage)}
          onPageChange={handlePageChange}
          totalEntries={filteredData.length}
          entriesPerPage={entriesPerPage}
        />
      </div>

      {/* Client Details Modal */}
      {!emailModalOpen && (isModalOpen && selectedClient) && (
        <ViewClients
          client={selectedClient}
          setClientData={setClientData}
          clientData={clientData}
          setSelectedClient={setSelectedClient}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          openEditModal={openEditModal}
          refreshClients={fetchClients}
        />
      )}

      {/* Add/Edit Client Modal */}
      {isAddModalOpen && (
        <AddClients
          closeAddModal={closeAddModal}
          addNewClient={addNewClient}
          addClients={fetchClients}
          setIsAddModalOpen={setIsAddModalOpen}
          initialClientData={selectedClient}
        />
      )}
      {emailModalOpen && (
        <EmailModal
          employee={selectedClient}
          onSubmit={handleEmailSubmit}
          onClose={() => {
            setEmailModalOpen(false);
            setIsModalOpen(false); 
          }}
          isClient = {true}
          loading={loading}
        />
      )}
    </>
  );
};

export default AllClients;
