import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../Hooks/useClickOutside";
import {
  FaPaperclip,
  FaTimes,
  FaRegPaperPlane,
  FaRegCommentDots,
} from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useSocket } from '../../context/SocketContext';
import { useLoggedInUser } from "../../context/LoggedUser";
import { useNotification } from '../../context/NotificationContext'


const CommentModal = ({ isOpen, onClose, taskId, type }) => {
  const modalRef = useRef();
  useOutsideClick(modalRef, onClose); // Close modal when clicking outside

  // State variables for managing comments, queries, employees, and files
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [commentsList, setCommentsList] = useState([]);
  const [queriesList, setQueriesList] = useState([]);
  // State variables for editing comments
  const [editingComment, setEditingComment] = useState(null);
  const [editingText, setEditingText] = useState("");
  const [editingFiles, setEditingFiles] = useState([]);
  // Constants
  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
  const apiUrl = process.env.REACT_APP_API_URL;
  const fileInputRef = useRef(null);
  const { loggedInUser } = useLoggedInUser();
  const { socket } = useSocket();
  const { createNotification } = useNotification()
  
  const formatDate = (timestamp) =>
    new Date(timestamp).toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

  useEffect(() => {
    if (isOpen) {
      fetchEmployees();
      fetchComments();
      fetchQueries();
    }
  }, [isOpen, taskId]); // Yeh sirf modal open hone pe fetch karega

  useEffect(() => {
    socket.on("comment-received", (data) => {
      console.log("📩 New Comment Received:", data);
  
      setCommentsList((prevComments) => {
        const alreadyExists = prevComments.some(
          (comment) => comment._id === data.commentDetails._id
        );
        return alreadyExists ? prevComments : [...prevComments, data.commentDetails];
      });
    });
  
    return () => {
      socket.off("comment-received");
    };
  }, [socket]); // Runs only once

  useEffect(() => {
    socket.on("query-received", (data) => {
      console.log("📩 New Query Received:", data);
  
      setQueriesList((prevQueries) => {
        const alreadyExists = prevQueries.some(
          (query) => query._id === data.queryDetails._id
        );
        return alreadyExists ? prevQueries : [...prevQueries, data.queryDetails];
      });
    });
  
    return () => {
      socket.off("query-received");
    };
  }, [socket]); // Runs only once
  

// Helper function to fetch employee details for a given task
  const fetchEmployees = async () => {
    try {
      // Fetch task details to get the list of employees involved
      const response = await axios.get(
        `${apiUrl}/api/task/view-task/${taskId}`,
        { withCredentials: true }
      );

      if (
        response.data.success &&
        Array.isArray(response.data.data.employeeInvolved)
      ) {
        // Fetch details of each employee concurrently
        const employeeDetails = await Promise.all(
          response.data.data.employeeInvolved.map(async (id) => {
            try {
              const empResponse = await axios.get(
                `${apiUrl}/api/fetchUser/${id}`,
                { withCredentials: true }
              );
              return { id, name: empResponse.data.name };
            } catch (error) {
              console.error("Error fetching employee name:", error);
              return { id, name: "Unknown" };
            }
          })
        );

        // Remove logged-in employee from the dropdown list
        const filteredEmployees = employeeDetails.filter(
          (emp) => emp.id !== loggedInUser._id
        );

        setEmployees(filteredEmployees);
      } else {
        setEmployees([]);
      }
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };


  const fetchComments = async (selectedEmpId = "all") => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/comments/get-comments/${taskId}`,
        { withCredentials: true }
      );
  
      console.log("Fetched Comments:", response.data); // Debugging
  
      if (response.data.success && response.data.comments.length > 0) {
        const fetchedNames = {};
  
        const commentsWithNames = await Promise.all(
          response.data.comments.map(async (comment) => {
            let senderName =
              fetchedNames[comment.commentby] ||
              (await fetchNameWithId(comment.commentby));
            let receiverName =
              fetchedNames[comment.commentTo] ||
              (await fetchNameWithId(comment.commentTo));
  
            fetchedNames[comment.commentby] = senderName;
            fetchedNames[comment.commentTo] = receiverName;
  
            return { ...comment, senderName, receiverName };
          })
        );
  
        // ✅ Directly set filtered comments instead of appending
        setCommentsList(
          selectedEmpId !== "all"
            ? commentsWithNames.filter(
                (comment) =>
                  comment.commentby === selectedEmpId ||
                  comment.commentTo === selectedEmpId
              )
            : commentsWithNames
        );
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  



  const fetchNameWithId = async (userId) => {

    if (!userId) return "N/A";  // Handle missing userId
    try {
      const response = await axios.get(`${apiUrl}/api/fetchUser/${userId}`, {
        withCredentials: true,
      });
      return response?.data?.name || "N/A";
    } catch (error) {
      console.error("Error fetching user name:", error);
      return "N/A";
    }
  };

  const fetchQueries = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/comments/get-queries/${taskId}`,
        { withCredentials: true }
      );
     console.log("response for queries", response);

      if (response.data.success) {


        // Fetch sender names in parallel for all queries
        const queriesWithNames = await Promise.all(
          response.data.data.map(async (query) => {
            const senderName = await fetchNameWithId(query.queryBy);
            return { ...query, senderName };
          })
        );
        console.log("QUERIES", queriesWithNames);

        setQueriesList(queriesWithNames);
        setCommentsList([]);
      }
      
    } catch (error) {
      console.error("Error fetching queries:", error);
    }
  };


  // Prevent rendering if modal is not open

  if (!isOpen) return null;

  // Handle file selection with validation

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (files.length + selectedFiles.length > 5) {
      toast.warn("You can only upload up to 5 files.");
      return;
    }

    const validFiles = selectedFiles.filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  // Remove a selected file
  const removeFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  // Submit comment or query based on type
  const handleSubmit = () => {
    console.log("handle submit");
    const submissionMethod =
      type === "query" ? handleSubmitQuery : handleSubmitComment;
    submissionMethod();
  };
  const handleSubmitComment = async () => {
    if (!comment.trim()) {
      return toast.error("Comment cannot be empty!");
    }
    if (!selectedEmployee) {
      return toast.error("Please select an employee!");
    }

    const formData = createFormData("comment", selectedEmployee);

    try {
      const response = await axios.post(
        `${apiUrl}/api/comments/add-comment`,
        formData,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data.success) {
        const newComment = response.data.comment;
        if (!newComment) {
          return toast.error("Failed to retrieve the added comment.");
        }
        console.log("✅ API Response Comment:", newComment);

        // 🔍 Debugging logs
        console.log("✅ API Response Comment:", newComment);
        console.log("🔍 Selected Employee ID:", selectedEmployee);

        const receiverName = employees.find(emp => emp.id === selectedEmployee)?.name || "Unknown";

        console.log("🔍 Resolved Receiver Name:", receiverName); // Debugging

        setCommentsList((prev) => {
          const alreadyExists = prev.some(comment => comment._id === newComment._id);
          return alreadyExists ? prev : [...prev, { ...newComment, senderName: loggedInUser?.name, receiverName }];
        });

        // 📡 Emit new comment to socket with fixed receiverName
        socket.emit("new-comment", {
          ...newComment,
          senderName: loggedInUser?.name,
          receiverName: receiverName || "Unknown",  // ✅ Fixed
        });

        // 🔹 Send notification
        await createNotification({
          sender: loggedInUser?.name,
          receiver: selectedEmployee,
          message: `${loggedInUser?.name} commented on`,
          notDesc: newComment?.taskId?.title || "a task",
        });

        toast.success("✅ Comment added successfully!");
        setComment("");
        setSelectedEmployee(null);
        resetForm();
      } else {
        toast.error(response.data.message || "Failed to add comment.");
      }
    } catch (error) {
      console.error("🔥 Error submitting comment:", error);
      toast.error("An error occurred while submitting the comment.");
    }
  };





// API Call for Adding a Query
const handleSubmitQuery = async () => {
  if (!comment.trim()) {
    return toast.error("Query cannot be empty!");
  }

  const formData = createFormData("query");

  try {
    const response = await axios.post(
      `${apiUrl}/api/comments/add-query`,
      formData,
      {
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    if (response.data.success) {
      const newQuery = response.data.data;
      if (!newQuery) {
        return toast.error("Failed to retrieve the added query.");
      }

      const senderName = loggedInUser?.name || "Unknown";

      setQueriesList((prev) => [
        ...prev,
        {
          id: newQuery?._id,
          text: comment,
          createdAt: new Date().toLocaleString(),
        },
      ]);

      socket.emit("new-query", { ...newQuery, senderName });

      // ✅ Send notification WITHOUT receiver name
      await createNotification({
        sender: loggedInUser?.name,
        message: `${loggedInUser?.name} queried on`,
        notDesc: newQuery?.taskId?.title || "a task",
      });

      toast.success("✅ Query added successfully!");
      setComment("");
      resetForm();
    } else {
      toast.error(response.data.message || "Failed to add query");
    }
  } catch (error) {
    console.error("🔥 Error submitting query:", error);
    toast.error("An error occurred while submitting the query.");
  }
};


  // Helper function to create FormData for both comment and query
  const createFormData = (type, employeeId = null) => {
    const formData = new FormData();
    formData.append("taskId", taskId);
    if (type === "comment") formData.append("commentTo", employeeId);
    formData.append(type, comment);
    files.forEach((file) => formData.append("files", file));
    return formData;
  };

  // Reset the form after submission
  const resetForm = () => {
    setComment("");
    setFiles([]);
    setSelectedEmployee("");
  };

  // Handle editing functionality
  const handleEdit = (item, type) => {
    setEditingComment(item);
    setEditingText(type === "query" ? item.query : item.comment);
    setEditingFiles(
      type === "query" ? item.queryFile || [] : item.commentFiles || []
    );
  };

  // Handle updating the comment (API call)
  const handleUpdateComment = async () => {
    // Ensure that there is a comment to edit and it is not empty
    if (!editingComment || !editingText.trim()) {
      return toast.error("Comment text cannot be empty!");
    }

    const formData = new FormData();
    formData.append("comment", editingText); // Add the edited comment text to the formData

    // Append any new files being uploaded with the comment
    editingFiles.forEach((file) => formData.append("files", file));

    try {
      // Make the PUT request to update the comment using its ID
      const response = await axios.put(
        `${apiUrl}/api/comments/update-comment/${editingComment?._id}`, // Use the comment's ID in the API call
        formData,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" }, // Indicate file upload
        }
      );

      if (response.data.success) {
        toast.success("Comment updated successfully!"); // Show success message

        // Update the local comments list to reflect the changes
        setCommentsList((prev) =>
          prev.map((c) =>
            c?._id === editingComment?._id
              ? { ...c, comment: editingText, commentFiles: editingFiles } // Update comment and files
              : c
          )
        );

        // Reset the editing state after successful update
        setEditingComment(null);
        setEditingText("");
        setEditingFiles([]);
      } else {
        toast.error(response.data.message || "Failed to update comment"); // Handle errors
      }
    } catch (error) {
      console.error("Error updating comment:", error); // Log the error for debugging
      toast.error("An error occurred while updating the comment."); // Show error message
    }
  };

  // Handle updating the query (similar to updating a comment)
  const handleUpdateQuery = async () => {
    // Ensure that the query text is not empty
    if (!editingComment || !editingText.trim()) {
      return toast.error("Query text cannot be empty!");
    }

    const formData = new FormData();
    formData.append("query", editingText); // Add the edited query text

    // Append any new files being uploaded with the query
    editingFiles.forEach((file) => formData.append("files", file));

    try {
      // Make the PUT request to update the query using its ID
      const response = await axios.put(
        `${apiUrl}/api/comments/update-query/${editingComment?._id}`, // Use the query's ID in the API call
        formData,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" }, // Indicate file upload
        }
      );

      if (response.data.success) {
        toast.success("Query updated successfully!"); // Show success message

        // Update the local queries list to reflect the changes
        setQueriesList((prev) =>
          prev.map((q) =>
            q?._id === editingComment?._id
              ? { ...q, query: editingText, queryFile: editingFiles } // Update query and files
              : q
          )
        );

        // Reset the editing state after successful update
        setEditingComment(null);
        setEditingText("");
        setEditingFiles([]);
      } else {
        toast.error(response.data.message || "Failed to update query"); // Handle errors
      }
    } catch (error) {
      console.error("Error updating query:", error); // Log the error for debugging
      toast.error("An error occurred while updating the query."); // Show error message
    }
  };

  // Delete comment function (handles API request to delete a comment)
  const handleDeleteComment = async (commentId) => {
    try {
      // Sending a DELETE request to the backend API to remove the comment
      const response = await axios.delete(
        `${apiUrl}/api/comments/delete-comments/${commentId}`,
        {
          withCredentials: true, // Ensure the token is sent with the request
        }
      );

      if (response.data.success) {
        toast.success("Comment deleted successfully!"); // Show success message

        // Update the comments list by removing the deleted comment
        setCommentsList((prev) =>
          prev.filter((comment) => comment._id !== commentId) // Remove the deleted comment
        );
      }
    } catch (error) {
      console.error("Error deleting comment:", error); // Log the error for debugging
      toast.error("An error occurred while deleting the comment."); // Show error message
    }
  };

  // Delete query function (handles API request to delete a query)
  const handleDeleteQuery = async (queryId) => {
    try {
      // Sending a DELETE request to the backend API to remove the query
      const response = await axios.delete(
        `${apiUrl}/api/comments/delete-query/${queryId}`,
        {
          withCredentials: true, // Ensure the token is sent with the request
        }
      );

      if (response.data.success) {
        toast.success("Query deleted successfully!"); // Show success message

        // Update the queries list by removing the deleted query
        setQueriesList((prev) => prev.filter((query) => query._id !== queryId)); // Remove the deleted query
      }
    } catch (error) {
      console.error("Error deleting query:", error); // Log the error for debugging
      toast.error("An error occurred while deleting the query."); // Show error message
    }
  };


  return (
    <div className="bg-white w-full rounded-lg flex flex-col h-[90vh]">

      {/* Comments List - Scrollable */}
      <div className="flex flex-col h-[30vh] sm:h-[30vh] overflow-y-auto ">
        {type === "query" && queriesList?.length > 0 ? (
          <div className="space-y-4">
            {queriesList.map((query) => (
              <div
                key={query._id}
                className="relative bg-white shadow-md rounded-xl p-5 border border-gray-200"
              >
                {/* Sender Info */}
                <div className="flex items-center gap-3 mb-4">
                  {/* Profile Icon */}
                  <div className="h-9 w-9 bg-blue-100 rounded-full flex items-center justify-center">
                    <span className="text-sm font-semibold text-blue-600">
                      {query.senderName?.charAt(0)}
                    </span>
                  </div>
                  {/* Sender Name & Date */}
                  <div>
                    <p className="text-sm font-semibold text-gray-800">
                      {query.senderName || "Unknown User"}
                    </p>
                    <p className="text-xs text-gray-500">{formatDate(query.createdAt)}</p>
                  </div>
                </div>

                {/* Edit & Delete Icons */}
                <div className="absolute top-3 right-3 flex gap-2 opacity-70 hover:opacity-100">
                  <button
                    className="text-gray-500 hover:text-blue-600"
                    onClick={() => handleEdit(query, "query")}
                  >
                    ✏️
                  </button>
                  <button
                    className="text-gray-500 hover:text-red-600"
                    onClick={() => handleDeleteQuery(query?._id)}
                  >
                    🗑️
                  </button>
                </div>

                {/* Query Text */}
                <p className="text-sm text-gray-800 leading-relaxed">{query.query}</p>

                {/* Attachments Section */}
                {query?.queryFile?.length > 0 && (
                  <div className="mt-3 border-t border-gray-200 pt-3">
                    <p className="text-xs font-medium text-gray-600 mb-2">Attachments:</p>
                    <div className="grid grid-cols-2 gap-3">
                      {query.queryFile.map((file, index) => (
                        <div key={index} className="flex items-center gap-2">
                          {file.fileType?.startsWith("image/") ? (
                            <img
                              src={file.uploadPath}
                              alt={file.name}
                              className="h-16 w-16 object-cover rounded-md shadow-sm border"
                            />
                          ) : (
                            <a
                              href={file.uploadPath}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center gap-2 text-blue-600 hover:text-blue-800 text-sm font-medium"
                            >
                              📄 {file.name}
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : type === "comment" && commentsList?.length > 0 ? (
          <div className="space-y-4">
            {commentsList.map((comment) => (
              <div
                key={comment?._id}
                className="relative bg-white shadow-md rounded-xl p-4 border border-gray-200"
              >
                {/* Edit & Delete Icons */}
                <div className="absolute top-3 right-3 flex gap-2 opacity-70 hover:opacity-100">
                  <button
                    className="text-gray-500 hover:text-blue-600"
                    onClick={() => handleEdit(comment, "comment")}
                  >
                    ✏️
                  </button>
                  <button
                    className="text-gray-500 hover:text-red-600"
                    onClick={() => handleDeleteComment(comment?._id)}
                  >
                    🗑️
                  </button>
                </div>

                {/* Comment Header (Profile + Name + Date) */}
                <div className="flex items-center gap-4 mb-3">
                  {/* Sender Profile */}
                  <div className="h-10 w-10 rounded-full bg-gradient-to-r from-green-400 to-green-600 flex items-center justify-center shadow-md">
                    <span className="text-lg font-semibold text-white">
                      {comment?.senderName?.charAt(0)}
                    </span>
                  </div>

                  {/* Comment Details */}
                  <div className="flex flex-col">
                    <p className="text-sm font-semibold text-gray-900">{comment?.senderName}</p>
                    <p className="text-xs text-gray-500">{formatDate(comment.createdAt)}</p>
                  </div>
                </div>

                {/* Receiver Section (Optional: Only Show If Receiver Exists) */}
                {comment?.receiverName && (
                  <div className="ml-14 flex items-center gap-4">
                    {/* Receiver Profile */}
                    <div className="h-8 w-8 rounded-full bg-gradient-to-r from-blue-400 to-blue-600 flex items-center justify-center shadow-md">
                      <span className="text-sm font-semibold text-white">
                        {comment?.receiverName?.charAt(0)}
                      </span>
                    </div>

                    <p className="text-sm font-medium text-gray-700">To: {comment?.receiverName}</p>
                  </div>
                )}

                {/* Comment Text */}
                <div className="bg-gray-100 px-4 py-3 rounded-lg shadow-sm ml-14">
                  <p className="text-sm text-gray-800">{comment?.comment}</p>
                </div>



                {/* Attachments Section */}
                {comment?.commentFile?.length > 0 && (
                  <div className="ml-12 mt-3 border-t border-gray-200 pt-2">
                    <p className="text-xs font-medium text-gray-600 mb-2">
                      Attachments:
                    </p>
                    <div className="grid grid-cols-2 gap-2">
                      {comment.commentFile.map((file, index) => (
                        <div key={index} className="flex items-center gap-2">
                          {file.fileType?.startsWith("image/") ? (
                            <img
                              src={file.uploadPath}
                              alt={file.name}
                              className="h-16 w-16 object-cover rounded-md shadow-sm border"
                            />
                          ) : (
                            <a
                              href={file.uploadPath}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex items-center gap-2 text-blue-600 hover:text-blue-800 text-sm font-medium"
                            >
                              📄 {file.name}
                            </a>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="h-full flex flex-col items-center justify-center text-gray-500">
            <FaRegCommentDots className="h-12 w-12 mb-2 opacity-50" />
            <p className="text-sm">
              {type === "comment" ? "No comments yet" : "No queries yet"}
            </p>
          </div>
        )}
      </div>

      {/* Sticky Comment Input Section */}
      <div className="border-t pt-4 bg-white sticky bottom-0 ">
        {!editingComment && type !== "query" && (
          <select
            value={selectedEmployee}
            onChange={(e) => {
              setSelectedEmployee(e.target.value);
              fetchComments(e.target.value); // Pass the selected employee to fetch comments accordingly
            }}

            className="w-full p-2 mb-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            {/* Ensure "All" appears first */}
            <option value="all">All</option>

            {/* Map through employees */}
            {employees.map((emp) => (
              <option key={emp.id} value={emp.id}>
                {emp.name}
              </option>
            ))}
          </select>

        )}

        <textarea
          placeholder={
            type === "query" ? "Enter your query..." : "Enter your comment..."
          }
          className="w-full min-h-[100px] p-3 mb-4 border rounded-lg resize-none"
          value={editingComment ? editingText : comment}
          onChange={(e) =>
            editingComment
              ? setEditingText(e.target.value)
              : setComment(e.target.value)
          }
        />

        {/* File Upload Section */}

        <div className="space-y-2">
          {files.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-2">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 bg-gray-100 rounded-full px-3 py-1"
                >
                  <FaPaperclip className="h-4 w-4" />
                  <span className="text-sm truncate max-w-[150px]">
                    {file.name}
                  </span>
                  <button
                    onClick={() => removeFile(index)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FaTimes className="h-4 w-4" />
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex items-center justify-between">
            {/* Show Attach Files button only when not editing */}
            {!editingComment && (
              <button
                onClick={() => fileInputRef.current?.click()}
                className="px-4 py-2 text-sm border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors flex items-center gap-2"
              >
                <FaPaperclip className="h-4 w-4" /> Attach Files
              </button>
            )}

            <button
              onClick={
                editingComment
                  ? type === "query"
                    ? handleUpdateQuery
                    : handleUpdateComment
                  : handleSubmit
              }
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2"
            >
              <FaRegPaperPlane className="h-4 w-4" />
              {editingComment
                ? type === "query"
                  ? "Update Query"
                  : "Update Comment"
                : type === "query"
                  ? "Send Query"
                  : "Send Comment"}
            </button>
          </div>

          <input
            type="file"
            multiple
            className="hidden"
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </div>
      </div>
    </div>
    // </div>
  );
};

export default CommentModal;
