import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import useOutsideClick from "../../Hooks/useClickOutside";
import { useSocket } from '../../context/SocketContext';
import { useLoggedInUser } from '../../context/LoggedUser';
import { useNotification } from '../../context/NotificationContext'

const AssignTask = ({ closeAssignTaskModal, taskId, fetchTaskData}) => {
    const [formData, setFormData] = useState({
        employeeId: "",
        soft_deadline: "",
        hard_deadline: "",
        high_priority: false
    });

    const apiUrl = process.env.REACT_APP_API_URL;
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    const { socket } = useSocket()
    const { notification, createNotification } = useNotification()
    const { loggedInUser } = useLoggedInUser()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/team/get-team`, {
                    withCredentials: true,
                });

                // console.log("response for employees", response);

                if (response.data.success) {
                    setEmployees(response.data.team);
                }
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };

        fetchEmployees();
    }, [apiUrl]);

    const closeRef = useRef(null);
    useOutsideClick(closeRef, closeAssignTaskModal);

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent page reload
        setLoading(true);
        try {
            const response = await axios.put(
                `${apiUrl}/api/task/assign-task/${taskId}`, // Use taskId from props
                { formData },
                { withCredentials: true }
            );

            if (response.status === 200) {
                const employees = response.data.task.employeeInvolved; // Array of employee IDs
                console.log("employees", employees);
                if (formData.high_priority) {
                    employees.forEach(employeeId => {
                        socket.emit("assign-high-priority-task", {
                            to: employeeId,
                            message: "high priority task assigned",
                            date: new Date(),
                        });
                    })
                    fetchTaskData();

                    // Create notification
                    // await createNotification({
                    //     sender: loggedInUser._id,
                    //     receiver: formData.employeeId,
                    //     message: "High Priority Task assigned",
                    //     notDesc: response.data.task.taskId
                    // });
                } else {

                    // Emit event to each employee
                    employees.forEach(employeeId => {
                        socket.emit("assign-task", {
                            to: employeeId,
                            message: "task assigned",
                            date: new Date(),
                        });
                    });

                }
                // Create notification
                await createNotification({
                    sender: loggedInUser._id,
                    receiver: formData.employeeId,
                    message: "New Task assigned",
                    notDesc: response.data.task.taskId
                });

                // console.log("Assigned task to employees:", employees);
                toast.success("Task assigned successfully!");
                closeAssignTaskModal();
            }

        } catch (error) {
            console.error("Error assigning task:", error);
            const errorMessage =
                error.response?.data?.message || "Failed to assign task. Please try again.";
            toast.warn(errorMessage);
        } finally {
            setLoading(false);
        }
    };



    return (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
            <div ref={closeRef} className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
                <div className="flex justify-between items-center border-b pb-4">
                    <h2 className="text-xl font-semibold mb-4">Assign Task</h2>
                    <button
                        className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
                        onClick={closeAssignTaskModal}
                    >
                        &times;
                    </button>
                </div>
                <div className="max-h-[350px] overflow-y-scroll mt-6 scrollbar-thin">
                    <form className="space-y-6 px-4" onSubmit={handleSubmit}>
                        <div>
                            <label
                                htmlFor="employeeId"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Select an Employee
                            </label>
                            <select
                                id="employeeId"
                                name="employeeId"
                                value={formData.employeeId}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                required
                            >
                                <option value="" disabled>
                                    Select an Employee
                                </option>
                                {employees.map((employee) => (
                                    <option key={employee._id} value={employee._id}>
                                        {employee.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:space-x-4">
                            <div className="flex-1">
                                <label
                                    htmlFor="soft_deadline"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Soft Deadline
                                </label>
                                <input
                                    type="date"
                                    id="soft_deadline"
                                    name="soft_deadline"
                                    value={formData.soft_deadline}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    required
                                />
                            </div>

                            <div className="flex-1 mt-4 sm:mt-0">
                                <label
                                    htmlFor="hard_deadline"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Hard Deadline
                                </label>
                                <input
                                    type="date"
                                    id="hard_deadline"
                                    name="hard_deadline"
                                    value={formData.hard_deadline}
                                    onChange={handleChange}
                                    className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                />
                            </div>
                        </div>
                        {/* High Priority Checkbox */}
                        <div className="flex items-center space-x-2 mt-2">
                            <input
                                type="checkbox"
                                id="high_priority"
                                name="high_priority"
                                checked={formData.high_priority}
                                onChange={(e) => setFormData({ ...formData, high_priority: e.target.checked })}
                                className="w-5 h-5 text-blue-600 border-gray-300 rounded focus:ring focus:border-blue-300"
                            />
                            <label htmlFor="high_priority" className="text-sm font-medium text-gray-700">
                                Mark as High Priority
                            </label>
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className={`px-4 py-2 bg-blue-600 text-white rounded-lg shadow ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                                    }`}
                                disabled={loading}
                            >
                                {loading ? "Assigning..." : "Assign Task"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AssignTask;