import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import axios from "axios";
import AddCandidateModal from "./AddCandidate";
import { toast } from "react-toastify";
import { useRole } from "../../context/RoleContext";
import EmailModal from "../../components/EmailModal ";
import CandidatesStatsComponent from "../../components/DashboadComponents/CandidatesStatsComponent";

const Candidates = () => {
  const [candidateData, setCandidateData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [openAddWindow, setOpenAddWindow] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [entries, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const { role } = useRole();
  const isSuperAdmin = role === "superadmin";

  useEffect(() => {
    getCandidates();
  }, [apiUrl]);

  const getCandidates = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/hr/get-candidate`, {
        withCredentials: true,
      });
      setCandidateData(response.data.candidates);
    } catch (error) {
      toast.error("Failed to fetch candidates");
    } finally {
      setLoading(false);
    }
  };

  const handleEntriesChange = (value) => {
    setEntries(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearch = (value) => {
    setSearchQuery(value?.toLowerCase());
  };

  const filterCandidates = candidateData.filter((candidate) =>
    candidate.name?.toLowerCase().includes(searchQuery)
  );

  const startIndex = (currentPage - 1) * entries;
  const CandidatesPaginateData = filterCandidates.slice(startIndex, startIndex + entries);

  const handleCheckboxChange = (id) => {
    setSelectedCandidates((prev) =>
      prev.includes(id) ? prev.filter((cid) => cid !== id) : [...prev, id]
    );
  };

  const handleSelectCandidate = () => {
    if (selectedCandidates.length === 0) {
      toast.warn("Select at least one candidate to edit.");
      return;
    }
  };
  const handleEditSelected = () => {
    setEdit(!edit);
    if (selectedCandidates.length > 0) {
      handleDelete(selectedCandidates); // Pass the array
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCandidates([]); // Unselect all
    } else {
      setSelectedCandidates(filterCandidates.map((candidate) => candidate._id)); // Select all
    }
    setSelectAll(!selectAll);
  };

  const handleDelete = async (ids) => {
    try {
      setLoading(true);
      const response = await axios.delete(`${apiUrl}/api/hr/delete-candidate`, {
        data: { candidateId: ids },
        withCredentials: true,
      });
      toast.success("Candidate(s) deleted successfully");
      getCandidates();
      setSelectedCandidates([]);
    } catch (error) {
      console.error("Error deleting candidates:", error);
      toast.error("Failed to delete candidates");
    } finally {
      setLoading(false);
    }
  };

  const handleSendMail = (candidateData) => {
    setSelectedCandidate(candidateData);
    setEmailModalOpen(true);
  };

  const handleEmailSubmit = async (subject, message) => {
    try {
      setLoading(true);
      const response = await axios.post(`${apiUrl}/api/sendmail`, {
        to: selectedCandidate.email,
        subject: subject,
        text: message,
      });
      toast.success(response.data.message);

      setEmailModalOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error sending email");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    ...(edit
      ? [
          {
            header: (
              <input
                type="checkbox"
                checked={
                  selectedCandidates.length > 0 &&
                  selectedCandidates.length === filterCandidates.length
                }
                onChange={handleSelectAll}
              />
            ),
            accessor: "select",
            render: (row) => (
              <input
                type="checkbox"
                checked={selectedCandidates.includes(row._id)}
                onChange={() => handleCheckboxChange(row._id)}
              />
            ),
          },
        ]
      : []),
    ...(isSuperAdmin ? [{ header: "Organization", accessor: "org_id" }] : []),
    { header: "Name", accessor: "name" },
    { header: "Phone", accessor: "phone" },
    { header: "Email", accessor: "email" },
    { header: "Role Applied", accessor: "role" },
    { header: "Address", accessor: "address" },
    {
      header: "Resume",
      accessor: "resume",
      render: (row) => (
        <button onClick={() => window.open(row.resume, "_blank")}>📙</button>
      ),
    },
    {
      header: "Mail",
      accessor: "mail",
      render: (row) => <button onClick={() => handleSendMail(row)}>📧</button>,
    },
  ];

  const actions = [
    { label: "🔄 Reload", onClick: getCandidates },
    { label: "➕ Add", onClick: () => setOpenAddWindow(true) },
    {
      label: edit && selectedCandidates.length > 0 ? "🗑️ Delete" : "✏️ Edit",
      onClick: handleEditSelected,
    },
  ];

  return (
    <>
    {/* <div className="p-4">
      <CandidatesStatsComponent />
    </div> */}
      <div className="p-4">
        <Table
          title="Candidate Table"
          columns={columns}
          data={CandidatesPaginateData}
          actions={actions}
          currentPage={currentPage}
          totalPages={Math.ceil(filterCandidates.length / entries)}
          onPageChange={handlePageChange}
          totalEntries={filterCandidates?.length}
          entries={entries}
          onSearch={handleSearch}
          onEntriesChange={handleEntriesChange}
          entriesOptions={[5, 10, 25, 50, 100, candidateData?.length]}
          exportButton={true}
          loading={loading}
        />
      </div>

      {openAddWindow && (
        <AddCandidateModal
          onClose={() => setOpenAddWindow(false)}
          getCandidates={getCandidates}
        />
      )}

      {emailModalOpen && (
        <EmailModal
          candidateData={selectedCandidate}
          onSubmit={handleEmailSubmit}
          onClose={() => setEmailModalOpen(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default Candidates;
