import React, { useState } from "react";
import axios from "axios";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim"

const ContentGenerator = () => {
  const [prompt, setPrompt] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (prompt.trim() === "") return;

    setLoading(true);
    setError("");
    setGeneratedContent("");

    try {
      const response = await axios.post(`${apiUrl}/api/generate-content`, {
        prompt,
      });

      // console.log("API Response:", response);
      setGeneratedContent(formatContent(response.data.content));
    } catch (err) {
      // console.error("API Error:", err);
      setError("Error generating content. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to format content properly (adds line breaks and bullet points)
  const formatContent = (text) => {
    return text
      .replace(/\n/g, "<br />")
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      .replace(/\* (.*?)\n/g, "<li>$1</li>")
      .replace(/(\d+\.) (.*?)\n/g, "<li>$1 $2</li>")
      .replace(/(http[s]?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
  };
  const particleOptions = {
    background: {
      color: "transparent",
    },
    particles: {
      number: {
        value: 150,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: "#ffffff",
      },
      shape: {
        type: "circle",
      },
      opacity: {
        value: 0.6,
        random: true,
        anim: {
          enable: true,
          speed: 1,
          opacity_min: 0.1,
        },
      },
      size: {
        value: 3,
        random: true,
        anim: {
          enable: true,
          speed: 5,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: "#ffffff",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 2,
        direction: "none",
        random: false,
        straight: false,
        out_mode: "out",
      },
    },
    interactivity: {
      events: {
        onHover: {
          enable: true,
          mode: "grab",
        },
        onClick: {
          enable: false,
          mode: "push",
        },
      },
      modes: {
        repulse: {
          distance: 100,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
      },
    },
    retina_detect: true,
  };
  const particlesInit = async (engine) => {
    await loadSlim(engine);
  };

  return (
    <div className="flex flex-col items-center justify-center max-h-4xl p-6 ">
      {/* <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          ...particleOptions,
          detectRetina: true,
          fullScreen: { enable: false }, // Prevent full-page rendering
          background: { opacity: 0 }, // Ensure transparency
          particles: {
            ...particleOptions.particles,
            move: {
              ...particleOptions.particles?.move,
              outModes: { default: "bounce" }, // Keep particles inside
            },
          },
        }}
        className="absolute inset-0 w-full h-full"
      /> */}
      <div className="bg-white shadow-2xl rounded-2xl p-8 max-w-5xl w-full border border-blue-300">
        <h1 className="text-3xl font-bold text-blue-600 text-center mb-6">
          AI Content Generator
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            className="w-full border border-blue-400 bg-gray-50 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-blue-500 transition shadow-md"
            rows="4"
            placeholder="Enter your prompt..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg shadow-lg hover:bg-blue-700 hover:shadow-xl transition-all transform hover:scale-105"
            disabled={loading}
          >
            {loading ? "⏳ Generating..." : "Generate Content"}
          </button>
        </form>
        {error && <p className="text-red-500 mt-3 text-center">{error}</p>}
        {generatedContent && (
          <div className="mt-6 p-4 border border-blue-300 rounded-lg bg-gray-50 shadow-md">
            <h2 className="text-lg font-semibold text-blue-600">🔹 Generated Content:</h2>
            <div
              className="mt-2 text-gray-700 text-md leading-relaxed max-h-[300px] overflow-y-auto pr-2"
              dangerouslySetInnerHTML={{ __html: generatedContent }}
            />
          </div>
        )}
      </div>
    </div>

  );
};

export default ContentGenerator;
