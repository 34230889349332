import React, { useState } from "react";
import login from "../Images/login.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ClientLogin = () => {
  const [clientPasswordVisible, setClientPasswordVisible] = useState(false);

  // Toggle password visibility
  const toggleClientPasswordVisibility = () => {
    setClientPasswordVisible(!clientPasswordVisible);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-light p-6">
      {/* Card Container */}
      <div className="bg-white shadow-card rounded-xl overflow-hidden flex flex-col lg:flex-row w-full max-w-5xl">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 relative">
          <img
            src={login}
            alt="Login Illustration"
            className="w-full h-full object-cover"
          />
        </div>
        {/* Form Section */}
        <div className="p-8 lg:w-1/2 flex flex-col justify-center">
          <h2 className="text-4xl font-extrabold text-primaryText mb-4 text-center">
            Welcome Back
          </h2>
          <p className="text-center text-secondaryGray mb-8">
            Let's achieve great things together today.
          </p>
          <form>
            <div className="mb-6">
              <label htmlFor="email" className="block text-primaryText font-medium">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-4 py-3 mt-2 border border-lightGray rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent"
                placeholder="Enter Your Email"
              />
            </div>
            <div className="mb-6 relative">
              <label htmlFor="password" className="block text-gray-700 font-medium">
                Password
              </label>
              <input
                type={clientPasswordVisible ? "text" : "password"}
                id="password"
                className="w-full px-4 py-3 mt-2 border border-lightGray rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-accent focus:border-accent"
                placeholder="Enter Your Password"
              />
              <span
                onClick={toggleClientPasswordVisibility}
                className="absolute right-4 top-12 cursor-pointer text-gray-600"
              >
                {clientPasswordVisible ? <FaEye/> : <FaEyeSlash />}
              </span>
            </div>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="remember"
                  className="h-4 w-4 text-accent focus:ring-accent border-gray-300 rounded"
                />
                <label htmlFor="remember" className="ml-2 text-gray-600 text-sm">
                  Remember Me
                </label>
              </div>
            </div>
            <button
              type="submit"
              className="w-full bg-accent text-white py-3 rounded-lg hover:bg-neonBlue transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClientLogin;
