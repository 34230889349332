import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../../components/Table';
import { useRole } from '../../context/RoleContext';
import ViewBDAreport from './ViewBDAreport';

const BDAreport = () => {
    const [data, setData] = useState([]);
    const [entries, setEntries] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([]);
    const [toggle, setToggle] = useState('Total');
    const [startDate, setStartDate] = useState(new Date(2000, 0, 1).toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [summary, setSummary] = useState({});
    const [selectedBDA, setSelectedBDA] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    // Function to set date range dynamically
    const handleToggleChange = (filter) => {
        setToggle(filter);
        const today = new Date();
        let newStartDate, newEndDate;

        if (filter === 'Last Month') {
            newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
        } else if (filter === 'Last Year') {
            newStartDate = new Date(today.getFullYear() - 1, 0, 1);
            newEndDate = new Date(today.getFullYear() - 1, 11, 31);
        } else { // 'total'
            newStartDate = new Date(2000, 0, 1);
            newEndDate = today;
        }

        setStartDate(newStartDate.toISOString().split('T')[0]);
        setEndDate(newEndDate.toISOString().split('T')[0]);
    };

    const fetchReportData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/reports/get-task-bda-reports`, {
                withCredentials: true,
                params: { startDate, endDate },
            });
            
            const { tasksByBDA, summary } = response.data;

            const formattedData = Object.entries(tasksByBDA).map(([bdaId, details]) => ({
                bdaId,
                bdaName: details.bdaName,
                emp_id: details.emp_id,
                totalTasks: details.totalTasks,
                completedTasks: details.completedTasks,
                deliverTasks: details.deliveredTasks,
                delayedTasks: details.delayedTasks,
                revenueGiven: details.revenueGiven,
                amountPaid: details.amountPaid,
                remainingAmount: details.remainingAmount,
                tasks: details.tasks,
            }));

            setData(formattedData);
            setSummary(summary);
        } catch (error) {
            console.error(`Error fetching report:`, error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            fetchReportData();
        }
    }, [toggle, startDate, endDate]);

    useEffect(() => {
        setFilteredData([...data]);
    }, [data]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

    const handleOnSearch = (value) => {
        const searchValue = value?.toLowerCase();
        if (!searchValue) {
            setFilteredData(data);
            return;
        }
        const filteredResults = data.filter((item) => 
            item.bdaId?.toLowerCase().includes(searchValue) ||
            item.totalTasks.toString().includes(searchValue) ||
            item.completedTasks.toString().includes(searchValue)
        );
        setFilteredData(filteredResults);
    };

    const startIndex = (currentPage - 1) * entries;
  const ReportpaginatedData = filteredData.slice(startIndex, startIndex + entries);

    const columns = [
        { header: "BDA ID", accessor: "emp_id" },
        { header: "BDA Name", accessor: "bdaName" },
        { header: "Total Tasks", accessor: "totalTasks" },
        { header: "Completed Tasks", accessor: "completedTasks" },
        { header: "Delivered Tasks", accessor: "deliverTasks" },
        { header: "Delayed Tasks", accessor: "delayedTasks" },  
        { header: "Revenue", accessor: "revenueGiven" },  
        { header: "Total Recovered Amount", accessor: "amountPaid"},
        { header: "Remaining Amount", accessor: "remainingAmount" },
    ]
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBDA(null);
    };

    const handleReportEntriesChange = (report) => {
        setEntries(report);
        setCurrentPage(1);
    }
    const handleRowClick = (bdaId) => {
        setSelectedBDA(bdaId);
        setIsModalOpen(true);
    };

    return (
        <div className='p-4'>
            <div className='bg-white shadow-lg rounded-lg p-4 mb-6'>
                <h3 className='text-xl font-semibold text-gray-800 mb-4'>BDA Report - {toggle.charAt(0).toUpperCase() + toggle.slice(1)}</h3>
                <div className='flex flex-col md:flex-row md:items-center justify-between space-y-4 md:space-y-0'>
                    <div className='flex flex-wrap justify-center md:justify-start gap-2'>
                        {['Last Month', 'Last Year', 'Total'].map((item) => (
                            <button
                                key={item}
                                onClick={() => handleToggleChange(item)}
                                className={`px-4 py-2 rounded-md font-semibold transition-colors duration-300 ${toggle === item ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                    <div className='flex flex-wrap justify-center md:justify-end gap-2'>
                        <input type='date' value={startDate} onChange={(e) => setStartDate(e.target.value)} className='px-3 py-2 border rounded-md w-full sm:w-auto' />
                        <input type='date' value={endDate} onChange={(e) => setEndDate(e.target.value)} className='px-3 py-2 border rounded-md w-full sm:w-auto' />
                    </div>
                </div>
                <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4 mt-4'>
                    {Object.entries(summary).map(([status, count]) => (
                        <div key={status} className='bg-gray-100 p-2 rounded-md shadow-sm text-center'>
                            <span className='text-xs font-semibold text-gray-700'>{status}</span>
                            <p className='text-lg font-bold text-blue-600'>{count}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='py-6'>
                <Table
                    title='BDA Report'
                    columns={columns}
                    data={ReportpaginatedData}
                    onSearch={handleOnSearch}
                    onEntriesChange={handleReportEntriesChange}
                    entriesOptions={[5, 10, 25, 50, 100, data.length]}
                    exportButton={true}
                    onRowClick={
                        (row) => {
                            handleRowClick(row);
                        }
                    }
                    currentPage={currentPage}
                    totalPages={Math.ceil(filteredData.length / entries)}
                    onPageChange={handlePageChange}
                    totalEntries={filteredData.length}
                    entries={entries}
                    loading={loading}
                />
            </div>
            {isModalOpen && (
                <ViewBDAreport
                    isModalOpen={isModalOpen}
                    closeModal={closeModal}
                    selectedBDA={selectedBDA}
                />
            )}
        </div>
    );
};

export default BDAreport;
