import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../../components/Table';
import AddTransactions from './AddTransactions';
import ViewTransactions from './ViewTransaction';
import { MoneyIcon } from '../../utills/Svgs';

const Transactions = () => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [entries, setEntries] = useState(10); // Default entries count
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTansaction, setSelectedTansaction] = useState(null);
    const [financeData, setFinanceData] = useState({});
    const [toggle, setToggle] = useState('monthly');
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState(data);
    const [loading, setLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        const storedSearch = sessionStorage.getItem('taskId');
        if (storedSearch && dataLoaded) {
            handleOnSearch(storedSearch); // Apply the search after data is fetched
            setTimeout(() => {
                sessionStorage.removeItem('taskId');
            }, 2000);
        } else {
            setFilteredData([...data]); // Reset only if no stored search
        }
    }, [data, dataLoaded]);
    

    const fetchTransactions = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/transactions`, { withCredentials: true });
            setData(response.data.transactions);
            setFilteredData(response.data.transactions);
            setDataLoaded(true);
            // console.log(response.data.transactions);
            // console.log("Transaction Data",response.data.transactions);
        } catch (error) {
            console.error("Error fetching transactions:", error);
        } finally {
            setLoading(false);
        }
    };
    const fetchTurnover = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/transactions/getturnover`, { withCredentials: true });
            setFinanceData(response.data);

        } catch (error) {
            console.error("Error fetching turnover:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTransactions();
        fetchTurnover()
    }, [apiUrl]);

    const handleReload = () => {
        fetchTransactions();
    };

    const handleTransactionEntriesChange = (value) => {
        setEntries(value); // Update the number of entries to show
        setCurrentPage(1);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    }
    const handleRowClick = (clientId) => {
        // console.log("Client ID for row click:", clientId);
        setSelectedTansaction(clientId);
        setIsModalOpen(true);
        // console.log(selectedTansaction);

    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

    const columns = [
        { header: "Payment ID", accessor: "paymentId" },
        { header: "Task", accessor: "taskId" },
        { header: "Client", accessor: "clientname" },
        { header: "Total Amount", accessor: "amount" },
        { header: "Due Amount", accessor: "leftAmount" },
        { header: "Currency", accessor: "currency" },
        { header: "Date", accessor: "createdAt" },
        { header: "Last Payment on", accessor: "updatedAt" },
        { header: "Assigned To", accessor: "assigned_to" },
        { header: "Payment Status", accessor: "paymentStatus", className: "text-green-600 font-semibold" },
    ];

    const actions = [
        { label: "🔄 Reload", onClick: handleReload },
        { label: "➕ Add Transaction", primary: true, onClick: () => setIsAddModalOpen(true) },
    ];
    const handleToggleChange = (value) => {
        setToggle(value);
    };
    const getFinanceValue = (type) => {
        return {
            turnover: financeData[`${type}Turnover`],
            amountPaid: financeData[`${type}AmountPaid`],
            pendingAmount: financeData[`${type}PendingAmount`],
        };
    };

    const { turnover, amountPaid, pendingAmount } = getFinanceValue(toggle);

    // useEffect(() => {
    //     setFilteredData([...data]); // Reset filteredData when data changes
    // }, [data]);

    // Search to be implemented
    // const handleOnSearch = (value) => {
    //     setData(data.filter((item) => item.clientname.toLowerCase().includes(value.toLowerCase())));
    // };
    const handleOnSearch = (value) => {
        const searchValue = value?.toLowerCase();
    
        if (!searchValue) {
            setFilteredData(data); // Reset to original data if search is cleared
            return;
        }

        const filteredResults = data.filter((item) =>
            item.clientname?.toLowerCase()?.includes(searchValue) ||
            item.currency?.toLowerCase()?.includes(searchValue) ||
            item.taskId?.toLowerCase()?.includes(searchValue) ||
            item.paymentId?.toLowerCase()?.includes(searchValue) ||
            item.assigned_to?.toLowerCase()?.includes(searchValue)
        );
    
        setFilteredData(filteredResults);
    };

    const startIndex = (currentPage - 1) * entries;
  const TransactionpaginatedData = filteredData.slice(startIndex, startIndex + entries);

    return (
        <>
            <div className='p-4'>
                <div className=" bg-white shadow-lg rounded-lg p-4">
                    <h3 className="text-xl font-semibold text-gray-800 mb-4">Finance Summary of {toggle.charAt(0).toUpperCase() + toggle.slice(1)}</h3>

                    {/* Toggle buttons */}
                    <div className="flex space-x-4 mb-6">
                        {['monthly', 'yearly', 'total'].map((item) => (
                            <button
                                key={item}
                                onClick={() => handleToggleChange(item)}
                                className={`px-3 py-1 rounded-md font-semibold transition-colors duration-300 ${toggle === item ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-blue-200'}`}
                            >
                                {item.charAt(0).toUpperCase() + item.slice(1)}
                            </button>
                        ))}
                    </div>

                    {/* Finance Summary */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex items-center">
                            <div className="text-blue-500 text-xl mr-3">📝</div>
                            <p className="text-gray-700 text-lg">
                                Turnover: <span className="font-bold">₹ {turnover}</span>
                            </p>
                        </div>
                        <div className="flex items-center">
                            <div className="text-green-500 text-3xl mr-3">
                                <MoneyIcon/>
                            </div>
                            <p className="text-gray-700 text-lg">
                                Amount Paid: <span className="font-bold">₹ {amountPaid}</span>
                            </p>
                        </div>
                        <div className="flex items-center">
                            <div className="text-yellow-500 text-3xl mr-3">⏳</div>
                            <p className="text-gray-700 text-lg">
                                Pending Amount: <span className="font-bold">₹ {pendingAmount}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="py-6 justify items-center">
                    <Table
                        title="Transactions"
                        columns={columns}
                        data={TransactionpaginatedData} // Limit data to the selected number of entries
                        actions={actions}
                        onSearch={(value) => handleOnSearch(value)}
                        onEntriesChange={handleTransactionEntriesChange}
                        currentPage={currentPage}
                        totalPages={Math.ceil(filteredData.length / entries)}
                        onPageChange={handlePageChange}
                        totalEntries={filteredData.length}
                        entriesPerPage={entries}
                        entriesOptions={[5, 10, 25, 50, 100, data?.length]}
                        exportButton={true}
                        onRowClick={(row) => {
                            handleRowClick(row);
                        }}
                        loading={loading}
                    />
                </div>
            </div>
            {isAddModalOpen && <AddTransactions closeAddModal={closeAddModal} setIsAddModalOpen={setIsAddModalOpen} fetchTransactions={fetchTransactions} />}
            {isModalOpen && <ViewTransactions closeModal={closeModal} setIsModalOpen={setIsModalOpen} selectedTansaction={selectedTansaction} fetchTransactions={fetchTransactions} />}
        </>

    );
};

export default Transactions;
