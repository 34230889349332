import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaCog } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { IoReload } from "react-icons/io5";
import useOutsideClick from "../../Hooks/useClickOutside";
import AddJobPost from "./AddJobPosts";
import Loading from "../../components/Loading";

const JobOpenings = () => {
  const [jobOpeningPosts, setJobOpeningPosts] = useState([]);
  const [editingJob, setEditingJob] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 6;
  const CloseJobkRef = useRef(null);
  useOutsideClick(CloseJobkRef, () => setShowDropdown(false));
  const [loading, setLoading] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // Calculate indexes for slicing
  const startIndex = (currentPage - 1) * filesPerPage;
  const endIndex = startIndex + filesPerPage;

  const currentJobs = filterData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filterData.length / filesPerPage);

  useEffect(() => {
    getJobOpeningPosts();
  }, []);

  const getJobOpeningPosts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/hr/get-all-jobs`, {
        withCredentials: true,
      });
      setJobOpeningPosts(response.data.jobPostings);
    } catch (error) {
      console.error("Error fetching private files:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReload = () => {
    getJobOpeningPosts();
  };

  // Function to handle post selection
  const handleSelectPost = (id) => {
    setSelectedPosts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((postId) => postId !== id)
        : [...prevSelected, id]
    );
  };
  
  // Function to delete selected posts
  const handleDeleteSelectedPosts = async () => {
    if (selectedPosts.length === 0) {
      toast.error("No job posts selected for deletion.");
      return;
    }

    try {
      setLoading(true);
      await axios.delete(`${apiUrl}/api/hr/delete-job`, {
        data: { jobId : selectedPosts }, 
        withCredentials: true,
      });

      toast.success("Job Post(s) deleted successfully");
      getJobOpeningPosts();
      setSelectedPosts([]);
    } catch (error) {
      console.error("Error deleting Job Post:", error);
      toast.error("Failed to delete Job Post");
    } finally {
      setLoading(false);
    }
  };

  // Function to edit selected posts
  const handleEditSelectedPosts = () => {
    if (selectedPosts.length === 0) {
      toast.error("No job posts selected for editing.");
      return;
    }
  
    if (selectedPosts.length > 1) {
      toast.error("Only one job post can be edited at a time.");
      return;
    }
  
    // Find the selected job
    const selectedJob = jobOpeningPosts.find((job) => job._id === selectedPosts[0]);
  
    if (selectedJob) {
      setEditingJob(selectedJob); 
    }
    setSelectedPosts([]);
  };
  
  useEffect(() => {
    if (!searchQuery) {
      setFilterData(jobOpeningPosts);
    } else {
      setFilterData(
        jobOpeningPosts.filter((item) =>
          item.jobName.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  }, [searchQuery, jobOpeningPosts]);

  

  return (
    <div className="flex flex-col h-screen bg-gray-100 p-6">
      <main className="grid grid-cols-1 lg:grid-cols-5 gap-6">
        {/* Left Section - Job Postings */}
        <div className="lg:col-span-3 col-span-1 h-[32rem] bg-white shadow-lg rounded-lg p-6 overflow-y-auto">
          <div className="flex items-center justify-between border-b pb-3">
            <h2 className="text-sm lg:text-xl font-semibold text-gray-800">
              Job Postings
            </h2>
            <div className="flex items-center gap-3">
              {/* Search Bar */}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search files..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-24 lg:w-64 px-3 py-2 pr-10 border rounded-lg focus:outline-none focus:border-blue-500 text-xs lg:text-lg"
                />
                <FaSearch className="absolute w-3 h-3 lg:w-5 lg:h-5 text-gray-400 right-3 lg:top-2.5 top-3" />
              </div>

              {/* Reload Icon */}
              <IoReload
                className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-700"
                onClick={handleReload}
              />

              {/* Delete Button */}
              <div className="relative inline-block">
              {/* Settings Button */}
              <button
                onClick={toggleDropdown}
                className="py-2 text-gray-500 h-10 w-10 rounded-lg flex items-center gap-2 hover:text-gray-900 transition"
              >
                <FaCog />
              </button>

              {/* Dropdown Menu */}
              {showDropdown && (
                <div 
                ref={CloseJobkRef}
                className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg">
                  <button
                    onClick={handleEditSelectedPosts}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Edit
                  </button>
                  <button
                    onClick={handleDeleteSelectedPosts}
                    className="block w-full text-left px-4 py-2 text-red-600 hover:bg-red-100"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
            </div>
          </div>

          {/* Job Post List */}
          <div className="px-4 pb-4 pt-4 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {currentJobs.map((job) => (
              <div
                key={job._id}
                onClick={() => {handleSelectPost(job._id)}}
                className={`lg:p-3 p-1 transition-colors border rounded cursor-pointer ${
                  selectedPosts.includes(job._id)
                    ? "bg-blue-100 border-blue-500"
                    : "hover:bg-gray-50"
                }`}
              >
                <div className="flex flex-col space-y-1">
                  <h3 className="font-medium text-xs md:text-lg">{job.jobName}</h3>
                  <p className="text-sm text-gray-500">[{job.jobDesc}]</p>
                  <p className="text-sm text-gray-500">{job.jobLevel}</p>
                  <p className="text-sm text-gray-900">{job.salary}</p>
                </div>
              </div>
            ))}
          </div>

          {loading && <div className="flex justify-center items-center gap-4 mt-4">
            <Loading />
          </div>}

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-center items-center gap-4 mt-4">
              <button
                className={`px-3 py-1 border rounded ${
                  currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
                }`}
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span className="text-sm font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className={`px-3 py-1 border rounded ${
                  currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
                }`}
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          )}
        </div>

        {/* Right Section - Add Job Post */}
        <div className="lg:col-span-2 col-span-1 h-[32rem] bg-white shadow-lg rounded-lg p-6 flex flex-col justify-between">
        <AddJobPost 
          getJobOpeningPosts={getJobOpeningPosts}
          editingJob={editingJob} 
          setEditingJob={setEditingJob}  
        />
        </div>
      </main>
    </div>
  );
};

export default JobOpenings;
