import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Calendar, ChevronDown, ChevronLeft, ChevronRight, Plus, Upload } from "lucide-react";
import useOutsideClick from "../../Hooks/useClickOutside";
import CreateEvents from "./CreateEvents";
import axios from "axios";
import * as XLSX from "xlsx";
import Loading from "../../components/Loading";

const FullCalenders = () => {
  // const [events, setEvents] = useState([
  //   { title: "Team Meeting", date: "2025-02-25", color: "#3b82f6" },
  //   { title: "John's Birthday 🎉", date: "2025-02-28", color: "#f59e0b" },
  // ]);
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const dropdownRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useOutsideClick(dropdownRef, () => setShowDropdown(false));

  // Function to handle adding a new event
  // const handleAddEvent = (newEvent) => {
  //   setEvents([...events, newEvent]);
  //   setShowCreateEvent(false);
  // };
  const handlePrev = () => {
    const api = calendarRef.current?.getApi();
    if (currentView === "yearView") {
      setYear((prev) => prev - 1);
    } else {
      api?.prev();
      setSelectedDate(new Date(api?.getDate())); // Ensure date updates properly
    }
  };

  const handleNext = () => {
    const api = calendarRef.current?.getApi();
    if (currentView === "yearView") {
      setYear((prev) => prev + 1);
    } else {
      api?.next();
      setSelectedDate(new Date(api?.getDate())); // Ensure date updates properly
    }
  };

  const handleToday = () => {
    setYear(new Date().getFullYear());
    setSelectedDate(new Date());
    calendarRef.current?.getApi()?.today();
  };


  // Function to handle adding a new event
  const handleAddEvent = (newEvent) => {
    setEvents([...events, newEvent]);
    // setShowCreateEvent(false);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const [taskResponse, eventResponse] = await Promise.all([
          axios.get(`${apiUrl}/api/taskevents`, { withCredentials: true }),
          axios.get(`${apiUrl}/api/events/getevent`, { withCredentials: true })
        ]);

        const allEvents = [...taskResponse.data.events, ...eventResponse.data.events];
        console.log(allEvents);

        setEvents(allEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);
  // Function to handle Excel file upload
  const hahhhndleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;

    try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            // Format date and time correctly
            const formattedEvents = sheet.map(event => {
                let dateValue = event.Date;

                // Check if the date is a number (Excel stores dates as serial numbers)
                if (typeof dateValue === "number") {
                    const parsedDate = XLSX.SSF.parse_date_code(dateValue);
                    dateValue = new Date(parsedDate.y, parsedDate.m - 1, parsedDate.d);
                } else {
                    dateValue = new Date(dateValue);
                }

                // Ensure the date is valid
                if (isNaN(dateValue.getTime())) {
                    console.error("Invalid date found in row:", event);
                    return null; // Skip invalid entries
                }

                return {
                    title: event.Title?.trim() || "Untitled Event", // Ensure title is present
                    date: dateValue.toISOString(), // Convert to valid ISO format
                    color: event.Color || "#000000",
                    isOrgEvent: event.isOrgEvent === "TRUE" || event.isOrgEvent === true,
                    org_id: event.OrgID || null,
                };
            }).filter(event => event !== null); // Remove invalid entries

            const response = await axios.post(
                `${apiUrl}/api/events/import`,
                { events: formattedEvents },
                { withCredentials: true }
            );

            setEvents((prevEvents) => [...prevEvents, ...response.data.newEvents]);
        };
    } catch (error) {
        console.error("Error importing events:", error);
    }
};

const handleFileUpload = async (e) => {
  e.preventDefault();
  const file = e.target.files[0];
  if (!file) return;

  try {
    setLoading(true);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async (event) => {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

          // Format date and time correctly
          const formattedEvents = sheet.map(event => {
              let dateValue = event.Date;
              let timeValue = event.Time || "00:00"; // Default to "00:00" if missing

              // 🟢 Handle Excel's numeric date format
              if (typeof dateValue === "number") {
                  const parsedDate = XLSX.SSF.parse_date_code(dateValue);
                  dateValue = new Date(parsedDate.y, parsedDate.m - 1, parsedDate.d);
              } else {
                  dateValue = new Date(dateValue);
              }

              // 🔴 Ensure the date is valid
              if (isNaN(dateValue.getTime())) {
                  console.error("Invalid date found in row:", event);
                  return null; // Skip invalid entries
              }

              // 🟢 Handle time parsing (string or number)
              let hours = 0, minutes = 0;

              if (typeof timeValue === "number") {
                  // Excel stores time as a fraction of a day, so multiply by 24 to get hours
                  const totalMinutes = Math.round(timeValue * 24 * 60);
                  hours = Math.floor(totalMinutes / 60);
                  minutes = totalMinutes % 60;
              } else if (typeof timeValue === "string" && timeValue.includes(":")) {
                  // If time is a string like "17:50"
                  [hours, minutes] = timeValue.split(":").map(Number);
              }

              // 🟢 Apply time to the date object
              dateValue.setHours(hours, minutes, 0);

              return {
                  title: event.Title?.trim() || "Untitled Event", // Ensure title is present
                  date: dateValue.toISOString(), // Convert to valid ISO format with time
                  color: event.Color || "#000000",
                  isOrgEvent: event.isOrgEvent === "TRUE" || event.isOrgEvent === true,
                  org_id: event.OrgID || null,
              };
          }).filter(event => event !== null); // Remove invalid entries

          const response = await axios.post(
              `${apiUrl}/api/events/import`,
              { events: formattedEvents },
              { withCredentials: true }
          );

          setEvents((prevEvents) => [...prevEvents, ...response.data.newEvents]);
      };
  } catch (error) {
      console.error("Error importing events:", error);
  }finally {
      setLoading(false);
  }
};

return (
    <>
    
      <div className="min-h-screen bg-gray-50 p-4 sm:p-6">
        {/* Header */}
        <div className="flex flex-col md:flex-row justify-between items-center bg-white shadow-lg p-4 rounded-xl mb-4 sm:mb-6">
          <div className="flex items-center space-x-3">
            <Calendar className="h-6 w-6 sm:h-7 sm:w-7 text-blue-600" />
            <h1 className="text-xl sm:text-2xl font-semibold text-gray-800">Calendar</h1>
          </div>
          <div className="flex flex-wrap justify-center space-x-2 sm:space-x-3 mt-4 md:mt-0">
            <button
              className="flex items-center space-x-2 bg-green-500 text-white px-4 sm:px-5 py-2 rounded-lg shadow hover:bg-green-600 transition-all"
              onClick={() => setShowCreateEvent(true)}
            >
              <Plus className="h-5 w-5" /> <span className="hidden sm:inline">Create Event</span>
            </button>
            {/* Upload Excel Button */}
            <label className="flex items-center space-x-2 bg-blue-500 text-white px-4 sm:px-5 py-2 rounded-lg shadow hover:bg-blue-600 transition-all cursor-pointer">
              <Upload className="h-5 w-5" />
              <span className="hidden sm:inline">Import Events</span>
              <input type="file" accept=".xlsx, .xls" className="hidden" onChange={handleFileUpload} />
            </label>
          </div>
        </div>

        {/* Calendar Container */}
        <div className="shadow-md rounded-xl mt-4 bg-white p-4">
          {/* Toolbar */}
          <div className="flex flex-wrap items-center justify-between p-4 gap-4">
            {/* Navigation Buttons */}
            <div className="flex items-center gap-2">
              <button
                onClick={handleToday}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all"
              >
                Today
              </button>
              <button
                onClick={handlePrev}
                className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                <ChevronLeft className="h-5 w-5" />
              </button>
              <button
                onClick={handleNext}
                className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-all"
              >
                <ChevronRight className="h-5 w-5" />
              </button>
            </div>

            {/* Current View */}
            <h2 className="text-lg font-semibold text-center flex-1">
              {currentView === "yearView" ? year : selectedDate.toDateString()}
            </h2>

            {/* View Selector Dropdown */}
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className="flex items-center bg-gray-200 px-4 py-2 rounded-lg hover:bg-gray-300 transition-all"
              >
                {currentView === "dayGridMonth"
                  ? "Month"
                  : currentView === "timeGridWeek"
                    ? "Week"
                    : currentView === "listWeek"
                      ? "List"
                      : currentView === "timeGridDay"
                        ? "Day"
                        : currentView === "yearView"
                          ? "Year"
                          : "Custom"}
                <ChevronDown className="h-4 w-4 ml-2" />
              </button>
              {showDropdown && (
                <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg w-40 z-50 border border-gray-200">
                  {[
                    { id: "dayGridMonth", name: "Month" },
                    { id: "timeGridWeek", name: "Week" },
                    { id: "listWeek", name: "List" },
                    { id: "yearView", name: "Year" },
                  ].map((view) => (
                    <button
                      key={view.id}
                      onClick={() => {
                        setCurrentView(view.id);
                        setShowDropdown(false);
                      }}
                      className="block px-4 py-2 hover:bg-gray-100 w-full text-left transition-all"
                    >
                      {view.name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Calendar Display */}
          {loading?<Loading/>:<>
          {currentView === "yearView" ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {Array.from({ length: 12 }, (_, i) => {
                const month = (i + 1).toString().padStart(2, "0");
                return (
                  <div
                    key={`${year}-${month}`}
                    className="border p-4 shadow-sm rounded-md bg-white"
                  >
                    <h2 className="text-center text-lg font-semibold mb-2">
                      {new Date(year, i).toLocaleString("en-US", { month: "long" })}
                    </h2>
                    <div className="overflow-hidden">
                      <FullCalendar
                        key={`${year}-${month}`} // 🔥 Forces re-render when year changes
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={false}
                        height="auto"
                        events={events}
                        initialDate={`${year}-${month}-01`}
                        eventColor="#1a73e8"
                        dateClick={(info) => {
                          setSelectedDate(new Date(info.date));
                          setCurrentView("timeGridDay");
                        }}
                        dayHeaderClassNames="text-[10px] sm:text-xs text-gray-600"
                        dayCellClassNames="text-xs sm:text-sm"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="p-3 pt-0 overflow-hidden">
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
                initialView={currentView}
                key={currentView} // 🔥 Ensures re-render when changing views
                headerToolbar={false}
                events={events}
                eventColor="#1a73e8"
                height="auto"
                dayMaxEvents={true}
                fixedWeekCount={false}
                firstDay={0}
                expandRows={true}
              />
            </div>
          )}
          </>}
        </div>
      </div>

      {/* Create Event Modal */}
      {showCreateEvent && (
        <CreateEvents onClose={() => setShowCreateEvent(false)} onAddEvent={handleAddEvent} />
      )}
    </>
  );
};

export default FullCalenders;
