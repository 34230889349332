import React, { useState } from 'react'
import Table from '../../components/Table'

const Orders = () => {
    const initialData = [
        {
            bdm: "Himanshu Bansal",
            name: "Sukhjinder Kaur",
            phone: "876848993",
            university: "VIT",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Aarav Jain",
            name: "Deep Patel",
            phone: "7541658521",
            university: "Kaplan",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Deep Patel",
            phone: "7110872020",
            university: "Kaplan",
            country: "USA",
            status: "Inactive",
        },
        {
            bdm: "Riya Sharma",
            name: "Maya Singh",
            phone: "7824054138",
            university: "Monash",
            country: "USA",
            status: "Active",
        },
        {
            bdm: "Riya Sharma",
            name: "Sukhjinder Kaur",
            phone: "8284211047",
            university: "ANU",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Nisha Verma",
            phone: "9085212319",
            university: "Kaplan",
            country: "Australia",
            status: "Inactive",
        },
        {
            bdm: "Aarav Jain",
            name: "Deep Patel",
            phone: "7541658521",
            university: "Kaplan",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Deep Patel",
            phone: "7110872020",
            university: "Kaplan",
            country: "USA",
            status: "Inactive",
        },
        {
            bdm: "Riya Sharma",
            name: "Maya Singh",
            phone: "7824054138",
            university: "Monash",
            country: "USA",
            status: "Active",
        },
        {
            bdm: "Riya Sharma",
            name: "Sukhjinder Kaur",
            phone: "8284211047",
            university: "ANU",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Nisha Verma",
            phone: "9085212319",
            university: "Kaplan",
            country: "Australia",
            status: "Inactive",
        },
        {
            bdm: "Aarav Jain",
            name: "Deep Patel",
            phone: "7541658521",
            university: "Kaplan",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Deep Patel",
            phone: "7110872020",
            university: "Kaplan",
            country: "USA",
            status: "Inactive",
        },
        {
            bdm: "Riya Sharma",
            name: "Maya Singh",
            phone: "7824054138",
            university: "Monash",
            country: "USA",
            status: "Active",
        },
        {
            bdm: "Riya Sharma",
            name: "Sukhjinder Kaur",
            phone: "8284211047",
            university: "ANU",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Nisha Verma",
            phone: "9085212319",
            university: "Kaplan",
            country: "Australia",
            status: "Inactive",
        },
        {
            bdm: "Aarav Jain",
            name: "Deep Patel",
            phone: "7541658521",
            university: "Kaplan",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Deep Patel",
            phone: "7110872020",
            university: "Kaplan",
            country: "USA",
            status: "Inactive",
        },
        {
            bdm: "Riya Sharma",
            name: "Maya Singh",
            phone: "7824054138",
            university: "Monash",
            country: "USA",
            status: "Active",
        },
        {
            bdm: "Riya Sharma",
            name: "Sukhjinder Kaur",
            phone: "8284211047",
            university: "ANU",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Nisha Verma",
            phone: "9085212319",
            university: "Kaplan",
            country: "Australia",
            status: "Inactive",
        },
        {
            bdm: "Aarav Jain",
            name: "Deep Patel",
            phone: "7541658521",
            university: "Kaplan",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Deep Patel",
            phone: "7110872020",
            university: "Kaplan",
            country: "USA",
            status: "Inactive",
        },
        {
            bdm: "Riya Sharma",
            name: "Maya Singh",
            phone: "7824054138",
            university: "Monash",
            country: "USA",
            status: "Active",
        },
        {
            bdm: "Riya Sharma",
            name: "Sukhjinder Kaur",
            phone: "8284211047",
            university: "ANU",
            country: "Australia",
            status: "Active",
        },
        {
            bdm: "Ishita Roy",
            name: "Nisha Verma",
            phone: "9085212319",
            university: "Kaplan",
            country: "Australia",
            status: "Inactive",
        },
    ];

    const [data, setData] = useState(initialData);
    const [entries, setEntries] = useState(10); // Default entries count

    const handleReload = () => {
        // console.log("Reload clicked");
        setData(initialData); // Reset data to the initial state
    };

    const handleEntriesChange = (value) => {
        // console.log("Entries:", value);
        setEntries(value); // Update the number of entries to show
    };

    const columns = [
        { header: "BDM", accessor: "bdm" },
        { header: "Name", accessor: "name" },
        { header: "Phone", accessor: "phone" },
        { header: "University", accessor: "university" },
        { header: "Country", accessor: "country" },
        { header: "Status", accessor: "status", className: "text-green-600 font-semibold" },
    ];

    const actions = [
        { label: "🔄 Reload", onClick: handleReload },
    ];
    return (
        <>
            <div className="p-4">
                <Table
                    title="Orders"
                    columns={columns}
                    data={data.slice(0, entries)} // Limit data to the selected number of entries
                    actions={actions}
                    onSearch={(value) =>  console.log("Search:", value)}
                    onEntriesChange={handleEntriesChange}
                    entriesOptions={[5, 10, 25, 50, 100, data?.length]}
                    exportButton={true}
                />
            </div>
        </>

    );
}

export default Orders