import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {  
      navigate('/login'); // Redirect to login
    }
  }, [navigate]);

  return (
    <div className="flex flex-col h-screen bg-gray-50 overflow-y-auto scrollbar-hidden">
      {/* Header */}
      <Header />

      {/* Main Layout */}
      <div className="flex flex-grow mt-20 relative overflow-y-auto scrollbar-hidden">
        {/* Sidebar */}
        <Sidebar />

        {/* Main Content */}
        <div className="flex flex-col flex-grow overflow-hidden relative">
          <main className="flex-grow overflow-y-auto scrollbar-hidden z-10">
            <Outlet />
          </main>

          {/* Footer */}
          <Footer className="absolute bottom-0 w-full z-0" />
        </div>
      </div>
    </div>
  );
};

export default Layout;
