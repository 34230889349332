import React, { useRef, useState, useEffect } from 'react';
import { RxCross2 } from 'react-icons/rx';
import useOutsideClick from '../../Hooks/useClickOutside';
import axios from 'axios';
import { usePermissions } from '../../context/PermissionContext';

const ViewTaskReport = ({ isModalOpen, closeModal, selectedTask, isAdmin }) => {
    const closeRef = useRef(null);
    useOutsideClick(closeRef, () => closeModal(false));
    const [names, setNames] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;
    const { permissions } = usePermissions();

    useEffect(() => {
        if (!selectedTask || !selectedTask.iterations) return;

        const fetchNames = async () => {
            const fetchedNames = {};
            const promises = selectedTask.iterations.map(async (log) => {
                if (log.assigned_by && !fetchedNames[log.assigned_by]) {
                    fetchedNames[log.assigned_by] = await fetchNameWithId(log.assigned_by);
                }
                if (log.assigned_to && !fetchedNames[log.assigned_to]) {
                    fetchedNames[log.assigned_to] = await fetchNameWithId(log.assigned_to);
                }
            });

            await Promise.all(promises);
            setNames(fetchedNames);
        };

        fetchNames();
    }, [selectedTask]);
    const fetchNameWithId = async (userId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/fetchUser/${userId}`, {
                withCredentials: true,
            });
            return response.data.name;
        } catch (error) {
            console.error("Error fetching user name:", error);
            return "N/A";
        }
    };


    if (!isModalOpen || !selectedTask) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-6 transition-opacity duration-300 ease-in-out">
            <div ref={closeRef} className="bg-white rounded-2xl shadow-xl max-h-[70vh] w-full sm:max-w-md md:max-w-2xl lg:max-w-3xl transform transition-transform duration-500 ease-in-out overflow-x-auto scrollbar-thin">
                <div className="flex justify-between items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-4">
                    <h2 className="text-lg font-semibold">Task Details - {selectedTask.taskId}</h2>
                    <button onClick={closeModal} className="text-white hover:text-gray-300 focus:outline-none">
                        <RxCross2 className="h-6 w-6" />
                    </button>
                </div>
                <div className="p-6 space-y-6">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2">
                        {[
                            { label: 'Task ID', value: selectedTask.taskId },
                            { label: 'Status', value: selectedTask.status },
                            { label: 'Word Count', value: selectedTask.wordcount },
                            { label: 'Deadline', value: new Date(selectedTask.soft_deadline).toLocaleDateString() },
                            ...(isAdmin ? [{ label: 'Hard Deadline', value: new Date(selectedTask.hard_deadline).toLocaleDateString() }] : []),
                            ...(isAdmin ? [{ label: 'Created At', value: new Date(selectedTask.createdAt).toLocaleDateString() }] : []),
                            ...(isAdmin ? [{ label: 'Updated At', value: new Date(selectedTask.updatedAt).toLocaleDateString() }] : []),
                            { label: 'Title', value: selectedTask.title, fullWidth: true },
                            { label: 'Description', value: selectedTask.description, fullWidth: true },
                        ].map(({ label, value, fullWidth }, index) => (
                            <div className={`flex items-center ${fullWidth ? 'col-span-full' : ''}`} key={index}>
                                <p className="text-gray-500 text-sm font-semibold">{label}:</p>
                                <span className="px-2 text-sm">{value}</span>
                            </div>
                        ))}
                    </div>
                    {(permissions.includes('Full Report') || isAdmin) && <>
                        <h3 className="text-lg font-semibold text-gray-900 mb-2">Iterations</h3>
                        {selectedTask.iterations.length > 0 ? (
                            <div className="space-y-4">
                                {selectedTask.iterations.map((iteration, idx) => (
                                    <div key={idx} className="p-4 bg-gray-50 rounded-lg shadow">
                                        <p><strong>Assigned To:</strong> {names[iteration.assigned_to] || iteration.assigned_to}</p>
                                        <p><strong>Assigned By:</strong> {names[iteration.assigned_by] || iteration.assigned_by}</p>
                                        <p><strong>Status:</strong> {iteration.status}</p>
                                        <p><strong>Soft Deadline:</strong> {new Date(iteration.soft_deadline).toLocaleDateString()}</p>
                                        <p><strong>Hard Deadline:</strong> {new Date(iteration.hard_deadline).toLocaleDateString()}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-500">No iterations available.</p>
                        )}
                    </>}
                </div>
            </div>
        </div>
    );
};

export default ViewTaskReport;
