import React, { useRef } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { useRole } from '../../context/RoleContext';
import useOutsideClick from '../../Hooks/useClickOutside';

const ViewTransactions = ({ isModalOpen, setIsModalOpen, closeModal, selectedTansaction, fetchTransactions }) => {
    const closeRef = useRef(null);
    useOutsideClick(closeRef, () => closeModal(false));

    const { role } = useRole();

    return (
        <div
            className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-6 transition-opacity duration-300 ease-in-out  `}
        >
            <div
                ref={closeRef} className={`bg-white rounded-2xl shadow-xl max-h-[70vh] w-full sm:max-w-md md:max-w-2xl lg:max-w-3xl transform transition-transform duration-500 ease-in-out  overflow-x-auto scrollbar-thin  `}>
                <div className="flex justify-between items-center animate-gradient-x bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-4">
                    <h2 className="text-lg font-semibold">Transaction</h2>
                    <button
                        onClick={closeModal}
                        className="text-white hover:text-gray-300 focus:outline-none"
                    >
                        <RxCross2 className="h-6 w-6" />
                    </button>
                </div>

                {/* Transaction Information */}
                <div className="p-6 space-y-6">
                <div className="flex items-start space-x-4">
              <div className="h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center">
                <span className="text-blue-600 text-xl font-semibold uppercase">
                  {selectedTansaction?.avatar || selectedTansaction.clientname?.[0] || "C"}
                </span>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-gray-900">{selectedTansaction.clientname}</h3>
                <p className="text-gray-500 text-sm">{selectedTansaction.clientemail}</p>
                <p className="text-gray-400 text-xs">ID: {selectedTansaction.clientDetail_id}</p>
              </div>
            </div>
                    {/* Transaction Info */}
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2">
                        {[
                            { label: 'Payment ID', value: selectedTansaction.paymentId },
                            { label: 'Task Title', value: selectedTansaction.tasktitle },
                            { label: 'Task ID', value: selectedTansaction.taskId },
                            { label: 'Amount', value: `${selectedTansaction.amount} ${selectedTansaction.currency}` },
                            { label: 'Left Amount', value: selectedTansaction.leftAmount || 'N/A' },
                            { label: 'Payment Status', value: selectedTansaction.paymentStatus },
                            { label: 'Created At', value: selectedTansaction.createdAt },
                            { label: 'Updated At', value: selectedTansaction.updatedAt },
                            { label: 'Created By', value: selectedTansaction.created_by },
                            { label: 'Assigned To', value: selectedTansaction.assigned_to },
                        ].map(({ label, value, bgColor = '', textColor = '' }, index) => (
                            <div key={index}>
                              <p className="text-gray-500 text-sm">{label}</p>
                              <span className={`px-4 py-2 ${bgColor} ${textColor} rounded-full text-sm`}>{value}</span>
                            </div>
                          ))}
                    </div>

                    {/* Logs Section */}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Transaction Logs</h3>
                        {selectedTansaction.logs.length > 0 ? (
                            <div className="space-y-4">
                                {selectedTansaction.logs.map((log, idx) => (
                                    <div key={idx} className="p-4 bg-gray-50 rounded-lg shadow">
                                        <p><strong>Paid Amount:</strong> {log.paidAmount}</p>
                                        <p><strong>Left Amount:</strong> {log.leftAmount}</p>
                                        <p><strong>Paid At:</strong> {new Date(log.paidAt).toLocaleString()}</p>
                                        <p><strong>Transaction ID:</strong> {log.transactionID}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-500">No transaction logs available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewTransactions;
