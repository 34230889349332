import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import useOutsideClick from '../../Hooks/useClickOutside';
import { toast } from 'react-toastify';
import { FaRegClipboard } from 'react-icons/fa';

const AddPayment = ({ closeAddModal, fetchPayments }) => {
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('INR');
    const [customerName, setCustomerName] = useState('');
    const [clientDetail, setClientDetail] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [description, setDescription] = useState('');
    const [paymentLink, setPaymentLink] = useState('');
    const [loading, setLoading] = useState(false);
    const [clientData, setClientData] = useState([]);
    const [taskDetails, setTaskDetails] = useState('');
    const [tasks, setTasks] = useState([]);
    const [org_id, setOrg_id] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleGeneratePaymentLink = async (event) => {
        event.preventDefault();
        if (!amount || !customerName || !customerEmail || !customerPhone || !org_id || !description || !taskDetails || !currency) {
            
            toast.error(`All fields are required.`);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/api/payments/create-payment`, {
                amount: Number(amount),
                currency,
                customerName,
                customerEmail,
                customerPhone,
                org_id,
                description,
                taskDetails,
                clientDetail
            }, { withCredentials: true });

            toast.success("Payment link generated successfully!");
            setPaymentLink(response.data.paymentLink);

        } catch (error) {
            console.error("Error generating payment link:", error);
            alert("Failed to generate payment link. Please try again.");
        } finally {
            setLoading(false);
        }
    };
    const closeRef = useRef(null);
    useOutsideClick(closeRef, () => closeAddModal());

    const fetchTasksByClient = async (clientId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/fetchTaskwithClientID/${clientId}`, { withCredentials: true });
            setTasks(response.data);
        } catch (err) {
            console.error("Error fetching tasks:", err);
        }
    };


    useEffect(() => {
        fetchTasksByClient(clientDetail);
    }, [clientDetail]);
    ;

    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/clients/get-clients`, { withCredentials: true });
                setClientData(response.data.data);
            } catch (error) {
                console.error("Error fetching client data:", error);
            }
        };
        fetchClientData();
    }, [apiUrl]);

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        if (!paymentLink) {
            console.error("No payment link available to copy");
            return;
        }

        navigator.clipboard.writeText(paymentLink)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error("Failed to copy: ", err));
    };





    return (
        <>


            <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
                <div ref={closeRef} className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
                    <div className="flex justify-between items-center border-b pb-4">
                        <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">{paymentLink ? `Payment Link Generated` : `Generate Payment Link`}</h2>
                        <button
                            className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
                            onClick={closeAddModal}
                        >
                            &times;
                        </button>
                    </div>

                    <div className="max-h-[350px] overflow-y-scroll mt-6 scrollbar-thin">
                        <form className="space-y-6 px-4" onSubmit={handleGeneratePaymentLink}>

                            {paymentLink ? (
                                <div className="mt-4">
                                    <span className="text-sm font-medium text-gray-700">Payment Link:</span>
                                    <div className="flex items-center space-x-2">
                                        <a
                                            href={paymentLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm 
                    text-blue-600 underline focus:outline-none focus:ring focus:border-blue-300"
                                        >
                                            {paymentLink}
                                        </a>
                                        <button
                                            type="button"  // Prevent form submission
                                            onClick={handleCopy}
                                            className="p-2 border border-gray-300 rounded-lg shadow-sm bg-gray-100 hover:bg-gray-200"
                                        >
                                            {copied ? "Copied!" : <FaRegClipboard className="w-5 h-5 text-gray-700" />}
                                        </button>
                                    </div>
                                </div>
                            ) : <> <div>
                                <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                                    Amount
                                </label>
                                <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                    required
                                />
                            </div>
                                <div>
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                        Description
                                    </label>
                                    <input
                                        type="description"
                                        id="description"
                                        name="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                                        Currency
                                    </label>
                                    <select
                                        id="currency"
                                        name="currency"
                                        value={currency}
                                        onChange={(e) => setCurrency(e.target.value)}
                                        className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    >
                                        <option value="INR">INR</option>
                                        <option value="USD">USD</option>
                                        <option value="EUR">EUR</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="customerName" className="block text-sm font-medium text-gray-700">
                                        Customer Name
                                    </label>
                                    <select
                                        id="customerName"
                                        name="customerName"
                                        value={clientDetail}
                                        onChange={(e) => {
                                            const selectedClient = clientData.find(client => client._id === e.target.value);
                                            if (selectedClient) {
                                                setClientDetail(e.target.value);
                                                setCustomerName(selectedClient.name);
                                                setCustomerEmail(selectedClient.email);
                                                setCustomerPhone(selectedClient.phone);
                                                setOrg_id(selectedClient.org_id);
                                            }
                                        }}
                                        className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    >
                                        <option value="">Select Client</option>
                                        {clientData.map((client) => (
                                            <option key={client._id} value={client._id}>{`${client.name} (${client.clientId})`}</option>
                                        ))}
                                    </select>
                                </div>
                                <div >
                                    <label htmlFor="taskDetails" className="block text-sm font-medium text-gray-700">
                                        Task Details
                                    </label>
                                    <select
                                        id="taskDetails"
                                        value={taskDetails}
                                        onChange={(e) => setTaskDetails(e.target.value)}
                                        className="mt-1 block w-full px-4 py-3 border rounded-lg"
                                        required
                                    >
                                        <option value="">Select Task</option>
                                        {tasks.map(task => (
                                            <option key={task._id} value={task._id}>{`${task.taskId} - ${task.title}`}</option>
                                        ))}
                                    </select>

                                </div>


                                <div>
                                    <label htmlFor="customerEmail" className="block text-sm font-medium text-gray-700">
                                        Customer Email
                                    </label>
                                    <input
                                        type="email"
                                        id="customerEmail"
                                        name="customerEmail"
                                        value={customerEmail}
                                        onChange={(e) => setCustomerEmail(e.target.value)}
                                        className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    />
                                </div>






                                <div>
                                    <label htmlFor="customerPhone" className="block text-sm font-medium text-gray-700">
                                        Customer Phone
                                    </label>
                                    <input
                                        type="text"
                                        id="customerPhone"
                                        name="customerPhone"
                                        value={customerPhone}
                                        onChange={(e) => setCustomerPhone(e.target.value)}
                                        className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                                        required
                                    />
                                </div>
                            </>
                            }




                            <div className="flex justify-end space-x-4 mt-6">
                                <button
                                    type="button"
                                    className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition"
                                    onClick={closeAddModal}
                                >
                                    {paymentLink ? `Close` : `Cancel`}
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-blue-600 transition"
                                    hidden={paymentLink}
                                    disabled={loading}
                                >
                                    {paymentLink ? "Payment Link Generated Successfully" : (loading) ? "Generating..." : "Generate Payment Link"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
};

export default AddPayment;



// import React, { useEffect, useRef, useState } from 'react';
// import axios from 'axios';
// import useOutsideClick from '../../Hooks/useClickOutside';
// import { toast } from 'react-toastify';

// const AddPayment = ({ closeAddModal, fetchPayments }) => {
//     const [amount, setAmount] = useState('');
//     const [currency, setCurrency] = useState('INR');
//     const [customerName, setCustomerName] = useState('');
//     const [customerEmail, setCustomerEmail] = useState('');
//     const [customerPhone, setCustomerPhone] = useState('');
//     const [paymentLink, setPaymentLink] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [clientData, setClientData] = useState([]);

//     const apiUrl = process.env.REACT_APP_API_URL;

//     const handleGeneratePaymentLink = async () => {
//         if (!amount || !customerName || !customerEmail || !customerPhone) {
//             alert("All fields are required.");
//             return;
//         }

//         setLoading(true);
//         try {
//             const response = await axios.post(`${apiUrl}/api/payments/create-payment`, {
//                 amount: Number(amount),
//                 currency,
//                 customerName,
//                 customerEmail,
//                 customerPhone,
//                 org_id: "your-org-id"
//             }, { withCredentials: true });

//             setPaymentLink(response.data.paymentLink);
//             toast.success("Payment link generated successfully!");

//         } catch (error) {
//             console.error("Error generating payment link:", error);
//             alert("Failed to generate payment link. Please try again.");
//         } finally {
//             setLoading(false);
//         }
//     };
//     const closeRef = useRef(null);
//     useOutsideClick(closeRef, () => closeAddModal());

//     useEffect(() => {
//         const fetchClientData = async () => {
//             try {
//                 const response = await axios.get(`${apiUrl}/api/clients/get-clients`, { withCredentials: true });
//                 setClientData(response.data.data);
//             } catch (error) {
//                 console.error("Error fetching client data:", error);
//             }
//         };
//         fetchClientData();
//     }, [apiUrl]);


//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
//             <div ref={closeRef} className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
//                 <div className="flex justify-between items-center border-b pb-4">
//                     <h2 className="text-xl sm:text-2xl font-semibold text-gray-800">Generate Payment Link</h2>
//                     <button
//                         className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
//                         onClick={closeAddModal}
//                     >
//                         &times;
//                     </button>
//                 </div>

//                 <div className="max-h-[350px] overflow-y-scroll mt-6 scrollbar-thin">
//                     <form className="space-y-6 px-4" onSubmit={handleGeneratePaymentLink}>
//                         <div>
//                             <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
//                                 Amount
//                             </label>
//                             <input
//                                 type="number"
//                                 id="amount"
//                                 name="amount"
//                                 value={amount}
//                                 onChange={(e) => setAmount(e.target.value)}
//                                 className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
//                                 required
//                             />
//                         </div>

//                         <div>
//                             <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
//                                 Currency
//                             </label>
//                             <select
//                                 id="currency"
//                                 name="currency"
//                                 value={currency}
//                                 onChange={(e) => setCurrency(e.target.value)}
//                                 className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
//                                 required
//                             >
//                                 <option value="INR">INR</option>
//                                 <option value="USD">USD</option>
//                                 <option value="EUR">EUR</option>
//                             </select>
//                         </div>


//                         <select
//                             id="customerName"
//                             name="customerName"
//                             value={customerName}
//                             onChange={(e) => {
//                                 const selectedClient = clientData.find(client => client._id === e.target.value);
//                                 if (selectedClient) {
//                                     setCustomerName(e.target.value);
//                                     setCustomerEmail(selectedClient.email); // Automatically set email
//                                     setCustomerPhone(selectedClient.phone); // Automatically set phone
//                                 }
//                             }}
//                             className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
//                             required
//                         >
//                             <option value="">Select Client</option>
//                             {clientData.map((client) => (
//                                 <option key={client._id} value={client._id}>{`${client.name} (${client.clientId})`}</option>
//                             ))}
//                         </select>

//                         <div>
//                             <label htmlFor="customerEmail" className="block text-sm font-medium text-gray-700">
//                                 Customer Email
//                             </label>
//                             <input
//                                 type="email"
//                                 id="customerEmail"
//                                 name="customerEmail"
//                                 value={customerEmail}
//                                 onChange={(e) => setCustomerEmail(e.target.value)}
//                                 className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
//                                 required
//                             />
//                         </div>





//                         <div>
//                             <label htmlFor="customerPhone" className="block text-sm font-medium text-gray-700">
//                                 Customer Phone
//                             </label>
//                             <input
//                                 type="text"
//                                 id="customerPhone"
//                                 name="customerPhone"
//                                 value={customerPhone}
//                                 onChange={(e) => setCustomerPhone(e.target.value)}
//                                 className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
//                                 required
//                             />
//                         </div>

//                         {paymentLink && (
//                             <div className="mt-4">
//                                 <p className="text-sm font-medium text-gray-700">Payment Link:</p>
//                                 <a href={paymentLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
//                                     {paymentLink}
//                                 </a>
//                             </div>
//                         )}

//                         <div className="flex justify-end space-x-4 mt-6">
//                             <button
//                                 type="button"
//                                 className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition"
//                                 onClick={closeAddModal}
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 type="submit"
//                                 className="bg-blue-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-blue-600 transition"
//                                 disabled={loading}
//                             >
//                                 {loading ? "Generating..." : "Generate Payment Link"}
//                             </button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>

//     );
// };

// export default AddPayment;

