import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useRole } from "../../context/RoleContext";
const apiUrl = process.env.REACT_APP_API_URL;

const AddJobPost = ({ getJobOpeningPosts, editingJob, setEditingJob }) => {
  const [organizations, setOrganizations] = useState([]);
  const { role } = useRole();
  const [formData, setFormData] = useState({
    jobName: "",
    jobDesc: "",
    jobLevel: "",
    salary: "",
    org_id : "",
    location: "",
  });

  useEffect(() => {
    if (editingJob && Object.keys(editingJob).length > 0) {
      setFormData({
        jobName: editingJob.jobName || "",
        jobDesc: editingJob.jobDesc || "",
        jobLevel: editingJob.jobLevel || "",
        salary: editingJob.salary || "",
        org_id: editingJob.org_id || "", // Check org_id presence
        location: editingJob.location || "", // Check location presence
      });
    }
    // console.log("editingJob", editingJob);
  }, [editingJob]);
  
  const handleChange = (e) => {
    if (e.target.name === "salary") {
      if (!/^\d*$/.test( e.target.value)) return;
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (editingJob) {
        // Update existing job
        await axios.put(
          `${apiUrl}/api/hr/edit-job/${editingJob._id}`,
          formData,
          { withCredentials: true }
        );

        toast.success("Job post updated successfully!");
        setEditingJob(null);
      } else {
        // Create a new job
        const response = await axios.post(
          `${apiUrl}/api/hr/create-job`,
          formData,
          { withCredentials: true }
        );

        // console.log("response", response)
        toast.success("Job post added successfully!");
      }

      setFormData({ jobName: "", jobDesc: "", jobLevel: "", salary: "", org_id: "", location: "" });
      getJobOpeningPosts(); 
    } catch (error) {
      toast.error("Failed to save job post.");
    }
  };


  const handleCancelEdit=()=>{
    setEditingJob(null)
    setFormData({
        jobName: "",
        jobDesc: "",
        jobLevel: "",
        salary: "",
        org_id: "",
        location: "",
      })
  }

  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchorganization`, { withCredentials: true });
      setOrganizations(response.data.organizations || []);

    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, [apiUrl]);

  return (
    <div className="bg-white rounded-lg w-full h-full pt-2">
      <h2 className="text-xl font-semibold mb-12">
        {editingJob ? "Edit Job Post" : "Add Job Post"}
      </h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="jobName"
          placeholder="Job Name"
          value={formData.jobName}
          onChange={handleChange}
          className="w-full px-4 py-2 border mb-2 border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
          required
        />
        <input
          type="text"
          name="jobDesc"
          placeholder="Job description"
          value={formData.jobDesc}
          onChange={handleChange}
          className="w-full px-4 py-2 border mb-2 border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
          required
        />
        <input
          type="text"
          name="salary"
          placeholder="Salary"
          value={formData.salary}
          onChange={handleChange}
          className="w-full px-4 py-2 border mb-2 border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
          required
        />
        <input
          type="text"
          name="jobLevel"
          placeholder="Job Level"
          value={formData.jobLevel}
          onChange={handleChange}
          className="w-full px-4 py-2 border mb-2 border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
        />
        <input
          type="text"
          name="location"
          placeholder="Location"
          value={formData.location}
          onChange={handleChange}
          className="w-full px-4 py-2 border mb-2 border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
        />
        {role === "superadmin" && (
            <select
              name="org_id"
              value={formData.org_id}
              onChange={handleChange}
              className="w-full px-4 py-2 border mb-2 border-gray-300 rounded-lg shadow-sm focus:ring focus:border-blue-300"
              required
            >
              <option value="">Select Organization</option>
              {organizations.map((org) => (
                <option key={org.
                  org_id} value={org.
                    org_id}>
                  {org.org_name}
                </option>
              ))}
            </select>
          )}
        <div className="flex justify-end space-x-2 mt-4 gap-2">
        <button type="submit" className="bg-blue-500 p-2 px-5 text-white rounded-lg">
          {editingJob ? "Update Job" : "Create Job"}
        </button>

        {editingJob && (
          <button
            type="button"
            onClick={() => handleCancelEdit()}
            className="bg-red-500 p-2 px-5 text-white rounded-lg"
          >
            Cancel Edit
          </button>
        )}
        </div>
      </form>
    </div>
  );
};

export default AddJobPost;
