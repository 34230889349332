import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLoggedInUser } from "../../context/LoggedUser";
import { BuildingIcon2, HiredCandidatesIcon, PendingReviewIcon, TotalCandidatesIcon } from "../../utills/Svgs";

const CandidatesStatsComponent = () => {
  const [selectedOrg, setSelectedOrg] = useState("all");
  const [stats, setStats] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const { loggedInUser } = useLoggedInUser();

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch Candidate Statistics
  const fetchCandidatesStats = async (org_id = "") => {
    try {
      const response = await axios.get(`${apiUrl}/api/hr/getCandidatesStats`, {
        params: { org_id },
        withCredentials: true,
      });
      setStats(response.data.data || {});
    } catch (error) {
      console.error("Error fetching candidate stats:", error);
    }
  };

  // Fetch Organizations List
  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchorganization`, {
        withCredentials: true,
      });
      setOrganizations(response.data.organizations || []);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (loggedInUser?.org_id) {
      setSelectedOrg(loggedInUser.org_id);
    }
  }, [loggedInUser]);

  useEffect(() => {
    fetchCandidatesStats(selectedOrg === "all" ? "" : selectedOrg);
  }, [selectedOrg]);

  return (
    <div className="bg-white shadow-lg rounded-xl p-6">
      <h3 className="text-2xl font-semibold text-gray-800 mb-1">
        Candidate Statistics - {selectedOrg === "all" ? "All Organizations" : `${selectedOrg}`}
      </h3>

      {loggedInUser?.org_id === "" && (
        <div className="mb-6 flex justify-end">
          <select
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
            className="p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-300"
          >
            <option value="all">All Organizations</option>
            {organizations.map((org) => (
              <option key={org.org_id} value={org.org_id}>
                {org.org_name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className={`grid grid-cols-1  ${selectedOrg === "all" ? 'md:grid-cols-4' : 'md:grid-cols-3'} gap-6`}>
        {selectedOrg === "all" && <SummaryCard icon={<BuildingIcon2/>}  title="Organizations" value={stats.organizations} color="text-blue-500" />}
        <SummaryCard icon={<TotalCandidatesIcon/>} title="Total Candidates" value={stats.totalCandidates} color="text-yellow-500" />
        <SummaryCard icon={<HiredCandidatesIcon/>} title="Hired Candidates" value={stats.hiredCandidates} color="text-green-500" />
        <SummaryCard icon={<PendingReviewIcon/>} title="Pending Review" value={stats.pendingCandidates} color="text-red-500" />
      </div>
    </div>
  );
};

// Reusable Summary Card Component
const SummaryCard = ({ icon, title, value, color }) => (
  <div className="flex items-center bg-gray-50 p-4 rounded-lg shadow-sm">
    <div className={`text-3xl mr-4 ${color}`}>{icon}</div>
    <div>
      <p className="text-gray-600 text-sm">{title}</p>
      <p className="text-lg font-bold text-gray-800">{value !== undefined ? value : 0}</p>
    </div>
  </div>
);

export default CandidatesStatsComponent;
