import React, { useEffect, useState } from "react";
import login from "../Images/login.png";
import { FaEyeSlash, FaEye } from "react-icons/fa"
import { useNavigate } from "react-router-dom";
import { useRole } from "../context/RoleContext";
import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import videooo1 from './../Images/layers.webm'
import videooo from './../Images/sea.webm'
import crmHome from './../Images/crmHome.png'
import blogs from './../Images/blogs.png'
import pricingimg from './../Images/pricing.png'
import aboutus from './../Images/about-us.png'


const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { role, setRole } = useRole();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [pricingEmail, setPricingEmail] = useState("");

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && token !== 'null' && token !== 'undefined' && role !== null) {
      navigate(`/${role?.toLowerCase()}`);
    }
  }, [navigate, role]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!email || !password) {
      setError('Please fill in all fields');
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/api/auth/login`,
        { email, password }, // Properly pass the body
        { withCredentials: true } // Config object

      );
      if (response.data.success) {
        toast.success('Login successful');
        const { token } = response.data;
        const decodedToken = jwtDecode(token);
        setRole(decodedToken.role || null);
        localStorage.setItem('token', token);
        // navigate(`/${role.toLowerCase()}`);
      } else {
        setError(response.data.message || 'Login failed');
        toast.error('Login failed');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
      toast.warn(err.response?.data?.message);
      console.error(err);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }
  const handlePricingRequest = async () => {
    if (!pricingEmail) {
      toast.warn("Please enter your email");
      return;
    }

    try {
      const response = await axios.post(`${apiUrl}/api/send-pricing-email`, { email: pricingEmail });
      if (response.data.success) {
        toast.success("Pricing request sent successfully!");
        setPricingEmail(""); // Clear input after submission
      } else {
        toast.error(response.data.message || "Failed to send request");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error sending request");
    }
  };

  return (

    // {/* Main Container */}

    <div className="min-h-screen flex items-center justify-center p-4 relative overflow-hidden">
      {/* Background Image with Blur Effect */}
      {/* <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(https://i.ibb.co/M0rKkV2/freepik.png)`,
          filter: "blur(5px)",
          opacity: "0.7",
        }}
      ></div> */}

      <div className="absolute inset-0 overflow-hidden">
        <video
          autoPlay
          loop
          muted
          speed="0.1"
          className="absolute inset-0 w-full h-full object-cover opacity-10 "
        >
          <source src={videooo} type="video/mp4" />
        </video>
      </div>

      {/* Overlay to Lighten Background */}
      <div className="absolute inset-0 bg-white bg-opacity-5"></div>
      {/* <img
        src={imgeee}
        alt=""
        className="absolute z-50 -top-10 left-1/2 transform -translate-x-1/2 w-full hidden lg:block pointer-events-none"
      /> */}

      <div className="bg-white shadow-lg rounded-lg flex flex-col lg:flex-row w-full max-w-5xl h-auto lg:h-[75vh] overflow-hidden relative z-10">
        <div className="absolute inset-0 overflow-hidden">
          <video
            autoPlay
            loop
            muted
            speed="0.1"
            className="absolute inset-0 w-full h-full object-cover opacity-5 pointer-events-none"
          >
            <source src={videooo1} type="video/mp4" />
          </video>
        </div>
        {/* Left Section */}
        <div className="w-full lg:w-1/2 p-8  flex flex-col relative ">
          <h1 className="text-4xl underline font-bold text-[#00a9ff] mb-4">RequinOps</h1>
          <h1 className="border-b-2 border-[#00a9ff] opacity-10 mt-2 mb-6"></h1>
          <h2 className="text-md font-semibold text-gray-800 mb-6">
            CRM Solutions Optimizing Customer Relationships Across Industries
          </h2>
          <p className="text-gray-600 mb-4">Welcome back! Please login to your account.</p>

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* <div>
              <label className="block text-gray-700 font-medium">Email Address</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your email"
              />

            </div> */}
            <div className="relative">
              <label className="block text-gray-700 font-medium">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your Email"
              />
              
            </div>
            <div className="relative">
              <label className="block text-gray-700 font-medium">Password</label>
              <input
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                placeholder="Enter your password"
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-4 top-9 cursor-pointer text-gray-600"
              >
                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {/* <div className="flex relative justify-between items-center text-sm text-gray-600">
              <label className="flex items-center">
                <input type="checkbox" className="mr-2" /> Remember Me
              </label>
            </div> */}
            <button className="w-full relative bg-[#00a9ff] text-white py-2 rounded-lg hover:bg-[#3aa3d7] transition ease-in-out duration-300">
              Login
            </button>
          </form>
        </div>

        {/* Right Section - Navigation & Image */}
        <div className="w-full lg:w-1/2 bg-slate-100 p-6 relative flex-col hidden sm:block justify-start">

          <nav className="flex flex-wrap justify-start space-x-4 text-gray-700 mb-4">
            {["home", "about", "blog", "pricing"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`${activeTab === tab ? "text-[#00a9ff] border-b-2 border-[#00a9ff]" : ""}`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </nav>
          <div className="text-gray-600 mb-6">
            {activeTab === "home" && <div className="flex flex-col items-center"><p>Welcome to RequinOps! Our platform provides state-of-the-art Customer Relationship Management (CRM) solutions designed to help businesses manage and optimize their interactions with customers. With a focus on innovation, efficiency, and scalability, RequinOps empowers companies to deliver exceptional customer experiences, drive growth, and improve operational performance. Explore how our platform can transform your customer relationships today.</p>
              <img src={crmHome} alt="" className="h-auto w-3/4 " />
            </div>}

            {activeTab === "about" && (<div className="flex flex-col items-center"><p className="mb-4 ">At RequinOps, we specialize in creating CRM solutions that adapt to the unique needs of businesses across industries. Our mission is to provide seamless and personalized customer management tools that foster long-lasting relationships. With advanced automation, AI-powered insights, and easy-to-use interfaces, we enable businesses to enhance customer engagement, improve sales workflows, and drive better decision-making. <a href="https://www.requingroup.com/aboutus" target="_blank" className="text-blue-500 underline">Know more about us</a> and how we’re transforming CRM solutions.</p>
              <img src={aboutus} alt="" className="h-auto w-3/4 " />
            </div>)}

            {activeTab === "blog" && <div className="flex flex-col items-center"><p>Stay ahead of the curve with our expert insights on CRM best practices, emerging trends, and case studies from industries leveraging RequinOps. Our blog covers topics such as CRM strategy, customer experience optimization, AI in CRM, and more. Whether you're a small business or an enterprise, our blog offers valuable resources to help you understand the latest developments and how to make the most of your CRM investment. <a href="https://www.requingroup.com/blog" target="_blank" className="text-blue-500 underline">Read more on our blog</a>.</p>
              <img src={blogs} alt="" className="h-auto w-3/4 " />
            </div>}

            {activeTab === "pricing" && (
              <div>
                <p>Explore our flexible pricing plans that cater to businesses of all sizes. Enter your email below to get personalized pricing details and unlock premium features tailored to your needs.</p>
                <div className="mt-4 flex flex-col">
                  <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Enter your email to get pricing:</label>
                  <input
                    type="email"
                    id="email"
                    value={pricingEmail} // Bind value to state
                    onChange={(e) => setPricingEmail(e.target.value)} // Update state on change
                    placeholder="Your email address"
                    className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                  />

                  <div>
                    <button
                      onClick={handlePricingRequest}
                      className="mt-2 px-6 py-2 bg-[#00a9ff] text-white font-semibold rounded-lg hover:bg-[#3aa3d7] transition ease-in-out duration-300"
                    >
                      Get Price
                    </button>
                  </div>
                </div>
                <img src={pricingimg} alt="" className="h-auto w-2/4 " />
              </div>

            )}
          </div>


          {/* <img
            src="https://i.ibb.co/M0rKkV2/freepik.png"
            alt="Illustration"
            className="w-full h-auto rounded-lg"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
