import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import axios from "axios";
import EmailModal from "../../components/EmailModal ";
import { toast } from "react-toastify";
import { useRole } from "../../context/RoleContext";

const AllEmployees = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [employeeSearchQuery, setEmployeeSearchQuery] = useState("");

  const apiUrl = process.env.REACT_APP_API_URL;
  const { role } = useRole();
  const isSuperAdmin = role === "superadmin";

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/employees/get-all-employees`, { withCredentials: true });
      setEmployeeData(response.data.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleReload = () => {
    fetchEmployees();
  };

  const handleEntriesChange = (value) => {
    setEntriesPerPage(value);
    setCurrentPage(1);
  };

  const handleSendMail = (employee) => {
    setSelectedEmployee(employee);
    setEmailModalOpen(true);
  };

  const handleEmailSubmit = async (subject, message) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${apiUrl}/api/sendmail`,
        {
          to: selectedEmployee.email,
          subject,
          text: message,
        },
        { withCredentials: true }
      );
      toast.success(response.data.message);
      setEmailModalOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error sending email");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleEmployeeSearch = (value) => {
    setEmployeeSearchQuery(value.toLowerCase() || "");
  };

  const filteredEmployeeData = employeeData.filter((employee) =>
    [employee?.name, employee?.email, employee?.phone]
      .filter(Boolean)
      .some((field) => field.toLowerCase().includes(employeeSearchQuery))
  );

  const startIndex = (currentPage - 1) * entriesPerPage;
  const paginatedData = filteredEmployeeData.slice(startIndex, startIndex + entriesPerPage);

  const columns = [
    ...(isSuperAdmin ? [{ header: "Organization", accessor: "org_id" }] : []),
    { header: "Employee ID", accessor: "emp_id" },
    { header: "Name", accessor: "name" },
    { header: "Phone", accessor: "phone" },
    { header: "Email", accessor: "email" },
    { header: "Role", accessor: "role_name" },
    { header: "Dept", accessor: "dept_name" },
    { header: "Manager", accessor: "manager_name" },
    { header: "Status", accessor: "status" },
    {
      header: "Mail",
      accessor: "mail",
      render: (row) => <button onClick={() => handleSendMail(row)}>📧</button>,
    },
  ];

  const actions = [{ label: "🔄 Reload", onClick: handleReload }];

  return (
    <>
      <div className="p-4">
        <Table
          title="Employee Table"
          columns={columns}
          data={paginatedData}
          actions={actions}
          onSearch={handleEmployeeSearch}
          onEntriesChange={handleEntriesChange}
          entriesOptions={[5, 10, 25, 50, 100, employeeData?.length]}
          exportButton={true}
          loading={loading}
          currentPage={currentPage}
          totalPages={Math.ceil(filteredEmployeeData.length / entriesPerPage)}
          onPageChange={handlePageChange}
          totalEntries={filteredEmployeeData.length}
          entriesPerPage={entriesPerPage}
        />
      </div>
      {emailModalOpen && (
        <EmailModal
          employee={selectedEmployee}
          onSubmit={handleEmailSubmit}
          onClose={() => setEmailModalOpen(false)}
          loading={loading}
        />
      )}
    </>
  );
};

export default AllEmployees;
