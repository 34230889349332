import React from 'react'
import Dashboard from '../components/Dashboard'
import TaskComponent from '../components/DashboadComponents/TaskComponent'
import TransactionsComponent from '../components/DashboadComponents/TransactionComponent'
import OrganizationStatsComponent from '../components/DashboadComponents/OrganizationStatsComponent'
import LeadsStatsComponent from '../components/DashboadComponents/LeadsStatsComponent'
import CandidatesStatsComponent from '../components/DashboadComponents/CandidatesStatsComponent'
import JobsStatsComponent from '../components/DashboadComponents/JobsStatsComponent'
import { useRole } from '../context/RoleContext'
import { usePermissions } from '../context/PermissionContext'
import StatsCarousel from './StatsCarousel'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CalendarComponent from '../components/DashboadComponents/CalendarComponent'

const DashboardPage = () => {
  const { role } = useRole();
  const { permissions } = usePermissions();
  const isAdmin = role === 'superadmin' || role === 'admin';
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className='px-4 py-2 pt-4'>

        <TaskComponent />
        {!isAdmin && (<CalendarComponent/> )}
      </div>
      {isAdmin && (
        <div className="">
          <div className="px-4 py-2">
            <TransactionsComponent />
          </div>
          <div className="px-4 py-2">
            <OrganizationStatsComponent />
          </div>
        </div>
      )}

      {isAdmin ? (
        <Carousel
          responsive={responsive}
          infinite
          autoPlay={true}  // Set true for autoplay
          keyBoardControl
          customTransition="all .5s"
          transitionDuration={500}
          containerClass="carousel-container"

          removeArrowOnDeviceType={["mobile"]}
        >
          <div className="p-4">
            <div className="rounded-xl ">
              <LeadsStatsComponent />
            </div>
          </div>
          <div className="p-4">
            <div className="rounded-xl shadow">
              <CandidatesStatsComponent />
            </div>
          </div>
          <div className="p-4">
            <div className="rounded-xl ">
              <JobsStatsComponent />
            </div>
          </div>
        </Carousel>
      ) : (
        <>
          {permissions.includes('LeadsStatistics') && <div className="px-4 py-2">
            <LeadsStatsComponent />
          </div>}
          {permissions.includes('CandidatesStatistics') && <div className="px-4 py-2">
            <CandidatesStatsComponent />
          </div>}
          {permissions.includes('JobsStatistics') && <div className="px-4 py-2">
            <JobsStatsComponent />
          </div>}
        </>
      )
      }
      {isAdmin && (<div className='px-4'><CalendarComponent/> </div>)}
      <div className='p-2'> </div>
    </>
  )
}

export default DashboardPage