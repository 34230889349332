import React, { useState, useEffect, use, useRef } from "react";
import axios from "axios";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useRole } from "../../context/RoleContext";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css"; // Import styles
import useOutsideClick from "../../Hooks/useClickOutside";

const AddClients = ({ closeAddModal, setIsAddModalOpen, initialClientData, addClients }) => {
  // State to manage form input fields
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    university: "",
    address: "",
    country: "",
    assigned_to: "",
    org_id: "",
  });

  console.log("Initial Client Data:", initialClientData);
  
  const apiUrl = process.env.REACT_APP_API_URL;

  // State for storing fetched BDMs and Organizations
  const [bdms, setBdms] = useState([]);
  const [organizations, setOrganizations] = useState([]);

   // State for storing fetched Countries
  const [countries, setCountries] = useState([]);
  
  // State for managing loading and error handling
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
 
  //State for managing Phone Number
  const [selectedCountry, setSelectedCountry] = useState(""); // Store selected country
  const [phoneNumber, setPhoneNumber] = useState("");
  // State for toggling password visibility
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { role } = useRole();

  
  // Fetch BDM list from API
  useEffect(() => {
    const fetchBDM = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/fetchbdm`, { withCredentials: true });
        setBdms(response.data);
      } catch (err) {
        console.error("Error fetching BDMs:", err);
        setError("Failed to fetch BDM list");
      }
    };
    
    // Fetch Organization list from API
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/fetchorganization`, { withCredentials: true });
        setOrganizations(response.data.organizations);
      } catch (err) {
        console.error("Error fetching Organization:", err);
        setError("Failed to fetch Organization list");
      }
    };
    
    fetchOrganization();
    fetchBDM();
  }, [apiUrl]);
  // Populate form fields when editing a client
  useEffect(() => {
    if (initialClientData) {
      setFormData({
        first_name: initialClientData.first_name || "",
        last_name: initialClientData.last_name || "",
        email: initialClientData.email || "",
        phone: initialClientData.phone || "",
        university: initialClientData.university || "",
        address: initialClientData.address || "",
        country: initialClientData.country || "",
        assigned_to: initialClientData.assigned_to || "",
        status: initialClientData.status || "",
        password: initialClientData.previewPassword || "",
        org_id: initialClientData.org_id || "",
      });
  
      // **Set phoneNumber separately**
      setPhoneNumber(initialClientData.phone || "");
    }
  }, [initialClientData]);
  

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const payload = { ...formData, phone: phoneNumber };

      if (!payload.first_name || !payload.email || !payload.phone) {
        setError("Please fill in all required fields.");
        setLoading(false);
        return;
      }

      if (initialClientData) {
        await axios.put(`${apiUrl}/api/clients/update-client-data/${initialClientData._id}`, payload, {
          withCredentials: true,
        });
      } else {
        await axios.post(`${apiUrl}/api/clients/create`, payload, { withCredentials: true });
      }

      addClients();
      setIsAddModalOpen(false);
    } catch (err) {
      console.error("Error submitting client data:", err);
      setError(err.response?.data?.message || "Failed to submit client data");
    } finally {
      setLoading(false);
    }
  };


  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        setCountries(response.data); // ✅ Fix: Set response.data, not response
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };

    getCountries();
  }, []);


const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    setFormData((prev) => ({ ...prev, phone: value }));
  };

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    setSelectedCountry(countryName);

    const countryData = countries.find((c) => c.name.common === countryName);
    if (countryData) {
      const countryCode = countryData.idd?.root + (countryData.idd?.suffixes?.[0] || "");
      setPhoneNumber(countryCode || "");
      setFormData((prev) => ({ ...prev, phone: countryCode || "" }));
    }
  };
  const closeRef = useRef(null);
  useOutsideClick(closeRef, () => closeAddModal(false));

  return (
     <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
      <div  ref={closeRef}  className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
        {/* Header Section */}
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold mb-4">
            {initialClientData ? "Edit Client" : "Add Client"}
          </h2>
          {error && <p className="text-red-500">{error}</p>}
          <button
            className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
            onClick={closeAddModal}
          >
            &times;
          </button>
        </div>
        <div className="max-h-[350px]  overflow-y-scroll mt-6 scrollbar-thin">
          {error && <p className="text-red-500">{error}</p>}
          <form className="space-y-6 px-4 " onSubmit={handleSubmit}>
            <div>
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                First Name*
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter first name"
                required
              />
            </div>

            <div>
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter last name"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email*
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter email"
                required
              />
            </div>

            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password*
              </label>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="mt-1 block w-full px-4 py-3 pr-12 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter password"
                  required
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-4 flex items-center cursor-pointer text-gray-600"
                >
                  {passwordVisible ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>

            <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone*
              </label>
              <PhoneInput country={"us"} value={phoneNumber} onChange={handlePhoneChange} inputClass="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm" />
            </div>

            <div>
              <label htmlFor="university" className="block text-sm font-medium text-gray-700">
                University
              </label>
              <input
                type="text"
                id="university"
                name="university"
                value={formData.university}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter university"
              />
            </div>

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter address"
              />
            </div>

            <div>
              <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Country
              </label>
              <select
        className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
        id="country"
        name="country"
        value={formData.country}
        onChange={handleChange}
      >
        <option value="" disabled>
          Select Country
        </option>
        {countries.length > 0 ? (
          countries.map((country) => (
            <option key={country.cca2} value={country.name.common}>
              {country.name.common}
            </option>
          ))
        ) : (
          <option disabled>Loading countries...</option>
        )}
      </select>
            </div>

            <div>
              <label htmlFor="assigned_to" className="block text-sm font-medium text-gray-700">
                Assigned To (BDM)
              </label>
              <select
                id="assigned_to"
                name="assigned_to"
                value={formData.assigned_to}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"

              >
                <option value="" disabled>
                  Select Assigned BDM
                </option>
                {bdms.map((bdm) => (
                  <option key={bdm._id} value={bdm._id}>
                    {bdm.name || `${bdm.first_name} ${bdm.last_name}`}
                  </option>
                ))}
              </select>
            </div>

            {initialClientData && <div>
              <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                required
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>}
            {
              role === 'superadmin' && <div>
                <label htmlFor="org_id" className="block text-sm font-medium text-gray-700">
                Organization
                </label>
                <select
                  id="org_id"
                  name="org_id"
                  value={formData.org_id}
                  onChange={handleChange}
                  className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                  required
                >
                  <option value="" disabled>
                    Select Organization
                  </option>
                  {organizations.map((org) => (
                    <option key={org._id} value={org.org_id}>
                      {org.org_name}
                    </option>
                  ))}
                </select>
              </div>
            }

            <div className="flex justify-end space-x-4 mt-6">
              <button
                type="button"
                className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition"
                onClick={closeAddModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-blue-600 transition"
                disabled={loading}
              >
                {initialClientData ? (loading ? "Editing..." : "Edit Client") : (loading ? "Creating..." : "Create Client")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddClients;
