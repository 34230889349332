import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { usePermissions } from "../../context/PermissionContext";
import { useRole } from "../../context/RoleContext";
import { RxCross2 } from "react-icons/rx";
import useOutsideClick from "../../Hooks/useClickOutside";

const CreateEvents = ({ onClose, onAddEvent }) => {
    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [color, setColor] = useState("#1a73e8");
    const [isOrgEvent, setIsOrgEvent] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState("");
    const [time, setTime] = useState("");
    const { permissions } = usePermissions();
    const { role } = useRole();
    const apiUrl = process.env.REACT_APP_API_URL;

    // Fetch organizations only if superadmin and org event checkbox is checked
    useEffect(() => {
        if (isOrgEvent && role === "superadmin") {
            const fetchOrganizations = async () => {
                try {
                    const response = await axios.get(`${apiUrl}/api/fetchorganization`, { withCredentials: true });
                    setOrganizations(response.data.organizations);
                } catch (err) {
                    console.error("Error fetching organizations:", err);
                }
            };
            fetchOrganizations();
        }
    }, [isOrgEvent, role]);

    const handleSubmit = async (e) => {
        // console.log("first")
        e.preventDefault();
        try {

            const dateTime = `${date}T${time}:00`;

            const newEvent = {
                title,
                date: dateTime,
                color,
                isOrgEvent,
                org_id: isOrgEvent && role === "superadmin" ? selectedOrg : null,
            };

            const response = await axios.post(`${apiUrl}/api/events/create`, newEvent, { withCredentials: true });
            onAddEvent(response.data);
            onClose();
        } catch (error) {
            console.error("Error creating event:", error);
        }
    };

    const closeref = useRef(null);
    useOutsideClick(closeref, onClose);
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm z-50 p-6">
            <div ref={closeref} className="bg-white backdrop-blur-lg rounded-3xl shadow-2xl w-full max-w-[95%] sm:max-w-md md:max-w-2xl lg:max-w-3xl p-6 sm:p-8 border border-gray-300">

                {/* Header */}
                <div className="flex justify-between items-center border-b border-gray-300 pb-4">
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-wide">Create Event</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-red-600 transition-all duration-300 rounded-full p-2 hover:bg-gray-300"
                    >
                        <RxCross2 className="w-6 h-6" />
                    </button>
                </div>

                {/* Content */}
                <form onSubmit={handleSubmit} className="mt-6 space-y-4 text-gray-800">
                    {/* Event Title */}
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Event Title</label>
                        <input
                            type="text"
                            className="w-full border border-gray-300 px-4 py-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                            placeholder="Enter event title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>

                    {/* Event Date & Time */}
                    <div className="grid sm:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Event Date</label>
                            <input
                                type="date"
                                className="w-full border border-gray-300 px-4 py-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Event Time</label>
                            <input
                                type="time"
                                className="w-full border border-gray-300 px-4 py-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    {/* Event Color Picker */}
                    <div>
                        <label htmlFor="hs-color-input" className="block text-sm font-medium text-gray-700 mb-2">
                            Event Color
                        </label>
                        <input
                            type="color"
                            className="p-1 h-10 w-14 bg-white border border-gray-300 cursor-pointer rounded-lg shadow-sm"
                            id="hs-color-input"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            title="Choose your color"
                        />
                    </div>

                    {/* Checkbox for Organization Event */}
                    {(permissions.includes("AddOrgEvent") || role === "superadmin" || role === "admin") && (
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                checked={isOrgEvent}
                                onChange={(e) => setIsOrgEvent(e.target.checked)}
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            />
                            <span className="text-gray-700">Create for Organization</span>
                        </label>
                    )}

                    {/* Organization Selection - Only for Superadmin */}
                    {isOrgEvent && role === "superadmin" && (
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Select Organization</label>
                            <select
                                value={selectedOrg}
                                onChange={(e) => setSelectedOrg(e.target.value)}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                                required
                            >
                                <option value="" disabled>
                                    Select Organization
                                </option>
                                {organizations.map((org) => (
                                    <option key={org._id} value={org.org_id}>
                                        {org.org_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    {/* Footer */}
                    <div className="flex justify-end mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-6 py-2 text-lg bg-gray-500 text-white rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300 mr-3"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-6 py-2 text-lg bg-gradient-to-r from-blue-600 to-blue-800 text-white rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300"
                        >
                            Create Event
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateEvents;
