import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLoggedInUser } from "../../context/LoggedUser";
import { BuildingIcon2, ConfirmedLeadsIcon, PendingLeadsIcon, PeopleIcon, ReachedOutIcon, TotalLeadsIcon } from "../../utills/Svgs";

const LeadsStatsComponent = () => {
  const [selectedOrg, setSelectedOrg] = useState("all"); // Default to all organizations
  const [stats, setStats] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const { loggedInUser } = useLoggedInUser();

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch Leads Statistics
  const fetchLeadsStats = async (org_id = "") => {
    try {
      const response = await axios.get(`${apiUrl}/api/leads/getLeadsStats`, {
        params: { org_id },
        withCredentials: true,
      });
      setStats(response.data.data || {});
    } catch (error) {
      console.error("Error fetching leads stats:", error);
    }
  };

  // Fetch Organizations List
  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchorganization`, {
        withCredentials: true,
      });
      setOrganizations(response.data.organizations || []);
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (loggedInUser?.org_id) {
      setSelectedOrg(loggedInUser.org_id);
    }
  }, [loggedInUser]);

  useEffect(() => {
    fetchLeadsStats(selectedOrg === "all" ? "" : selectedOrg);
  }, [selectedOrg]);

  return (
    <div className="bg-white shadow-lg rounded-xl p-6">
      <h3 className="text-2xl font-semibold text-gray-800 mb-1">
        Leads Statistics - {selectedOrg === "all" ? "All Organizations" : `${selectedOrg}`}
      </h3>

      {loggedInUser?.org_id === "" && (
        <div className="mb-6 flex justify-end">
          <select
            value={selectedOrg}
            onChange={(e) => setSelectedOrg(e.target.value)}
            className="p-2 border rounded-lg shadow-sm focus:ring focus:ring-blue-300"
          >
            <option value="all">All Organizations</option>
            {organizations.map((org) => (
              <option key={org.org_id} value={org.org_id}>
                {org.org_name}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className={`grid grid-cols-1 ${selectedOrg === "all" ? 'md:grid-cols-5' : 'md:grid-cols-4'} gap-6`}>
        {selectedOrg === "all" && <SummaryCard icon={<BuildingIcon2/>} title="Organizations" value={stats.organizations} color="text-blue-500" />}
        <SummaryCard icon={<TotalLeadsIcon />} title="Total Leads" value={stats.totalLeads} color="text-yellow-500" />
        <SummaryCard icon={<PendingLeadsIcon />} title="Pending Leads" value={stats.pendingLeads} color="text-red-500" />
        <SummaryCard icon={<ReachedOutIcon />} title="Reached Out" value={stats.reachedOutLeads} color="text-indigo-500" />
        <SummaryCard icon={<ConfirmedLeadsIcon />} title="Confirmed Leads" value={stats.confirmedLeads} color="text-green-500" />
      </div>
    </div>
  );
};

// Reusable Summary Card Component
const SummaryCard = ({ icon, title, value, color }) => (
  <div className="flex items-center bg-gray-50 p-4 rounded-lg shadow-sm">
    <div className={`text-3xl mr-4 ${color}`}>{icon}</div>
    <div>
      <p className="text-gray-600 text-sm">{title}</p>
      <p className="text-lg font-bold text-gray-800">{value !== undefined ? value : 0}</p>
    </div>
  </div>
);

export default LeadsStatsComponent;
