import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useRole } from "../../context/RoleContext";

const AddTask = ({ closeAddTaskModal, editingTask,  fetchTaskData }) => {
  const [taskOrganization, setTaskOrganization] = useState([]);
  const [department, setDepartment] = useState([]);
  const {role} = useRole();
  const [formData, setFormData] = useState({
    title: "",
    wordcount: "",
    soft_deadline: "",
    hard_deadline: "",
    description: "",
    dept_id: "",
    org_id: "",
    clientId: "",
    status: "Unassigned",
    // projectId: "",
  });

  const apiUrl = process.env.REACT_APP_API_URL;
  const [error, setError] = useState("");
  const [clients, setClients] = useState([]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fetchTaskOrganization = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchorganization`, {
        withCredentials: true,
      });
      setTaskOrganization(response.data.organizations);
    } catch (err) {
      console.error("Error fetching Organization:", err);
      setError("Failed to fetch Organization list");
    }
  };

  const fetchDepartment = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchdept`, {
        withCredentials: true,
      });
      setDepartment(response.data);
    } catch (err) {
      console.error("Error fetching Department:", err);
      setError("Failed to fetch Department list");
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/clients/get-clients`, {
        withCredentials: true,
      });

      setClients(response.data.activeClients);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };
  useEffect(() => {
    fetchTaskOrganization();
    fetchClients();
    fetchDepartment();
  }, [apiUrl]);
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${apiUrl}/api/task/create`, formData, { withCredentials: true });
  //     if (response.status === 201) {
  //       // Corrected: returning or handling the success message properly
  //       // console.log("response from add",response);

  //       alert("Task created successfully"); // You can replace this with a modal message if needed
  //       closeAddTaskModal();
  //     }
  //   } catch (error) {
  //     console.error("Error creating task:", error);
  //     alert("Failed to create task. Please try again.");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTask) {
        // Edit Task
        const response = await axios.put(
          `${apiUrl}/api/task/update-task/${editingTask._id}`,
          formData,
          { withCredentials: true }
        );
      } else {
        // Create Task
        const response = await axios.post(
          `${apiUrl}/api/task/create`,
          formData,
          {
            withCredentials: true,
          }
        );
      }
      closeAddTaskModal();
      fetchTaskData();
    } catch (error) {
      toast.warn("Failed to create task. Please try again.");
    }
  };

  useEffect(() => {
    if (editingTask) {
      setFormData({
        title: editingTask.title,
        wordcount: editingTask.wordcount,
        soft_deadline: editingTask.soft_deadline,
        hard_deadline: editingTask.hard_deadline,
        description: editingTask.description,
        dept_id: editingTask.dept_id,
        org_id: editingTask.org_id,
        clientId: editingTask.clientId,
        status: editingTask.status,
        // projectId: editingTask.projectId,
      });
    }
  }, [editingTask]);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
      <div className="bg-white rounded-lg shadow-lg p-8 sm:p-10 w-screen max-w-3xl transition-transform transform scale-100 mt-20">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold mb-4">
            {editingTask ? "Edit Task" : "Add Task"}
          </h2>
          <button
            className="text-gray-500 hover:text-gray-800 focus:outline-none text-2xl"
            onClick={closeAddTaskModal}
          >
            &times;
          </button>
        </div>

        <div className="max-h-[350px] overflow-y-scroll mt-6 scrollbar-thin">
          {error && <p className="text-red-500">{error}</p>}
          <form className="space-y-6 px-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter title"
                required
              />
            </div>

            {/* Wordcount Input */}
            <div>
              <label
                htmlFor="wordcount"
                className="block text-sm font-medium text-gray-700"
              >
                Wordcount
              </label>
              <input
                type="text"
                id="wordcount"
                name="wordcount"
                value={formData.wordcount}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter Wordcount"
                required
              />
            </div>

            {/* Soft Deadline and Hard Deadline Side by Side */}
            <div className="flex flex-col sm:flex-row sm:space-x-4">
              {/* Soft Deadline */}
              <div className="flex-1">
                <label
                  htmlFor="soft_deadline"
                  className="block text-sm font-medium text-gray-700"
                >
                  Soft Deadline
                </label>
                <input
                  type="date"
                  id="soft_deadline"
                  name="soft_deadline"
                  value={formData.soft_deadline}
                  onChange={handleChange}
                  className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                  required
                />
              </div>

              {/* Hard Deadline */}
              <div className="flex-1 mt-4 sm:mt-0">
                <label
                  htmlFor="hard_deadline"
                  className="block text-sm font-medium text-gray-700"
                >
                  Hard Deadline
                </label>
                <input
                  type="date"
                  id="hard_deadline"
                  name="hard_deadline"
                  value={formData.hard_deadline}
                  onChange={handleChange}
                  className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </div>
            {/* Description */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter task description"
              ></textarea>
            </div>
            {/* Department Select */}
            <div>
              <label
                htmlFor="dept_id"
                className="block text-sm font-medium text-gray-700"
              >
                Department ID
              </label>
              <select
                type="text"
                id="dept_id"
                name="dept_id"
                value={formData.dept_id}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter Department ID"
                required
              >
                <option value="" disabled>
                  Select Department
                </option>
                {department.map((dept) => (
                  <option key={dept._id} value={dept._id}>
                    {dept.dept_name}
                  </option>
                ))}
              </select>
            </div>
            {(role === 'superadmin') && <div>
              <label
                htmlFor="org_id"
                className="block text-sm font-medium text-gray-700"
              >
                Organization
              </label>
              <select
                id="org_id"
                name="org_id"
                value={formData.org_id}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                required
              >
                <option value="" disabled>
                  Select Organization
                </option>
                {taskOrganization.map((org) => (
                  <option key={org._id} value={org.org_id}>
                    {org.org_name}
                  </option>
                ))}
              </select>
            </div>}
            <div>
              <label
                htmlFor="clientId"
                className="block text-sm font-medium text-gray-700"
              >
                Clients
              </label>
              <select
                id="clientId"
                name="clientId"
                value={formData.clientId}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                required
              >
                <option value="" disabled>
                  Select Clients
                </option>
                {clients.map((client) => (
                  <option key={client._id} value={client._id}>
                    {client.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="status"
                className="block text-sm font-medium text-gray-700"
              >
                Status
              </label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                required
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value="Unassigned">Unassigned</option>
                <option value="Assigned to">Assigned to</option>
                <option value="Running">Running</option>
                <option value="Approved By">Approved By</option>
                <option value="Quality Check">Quality Check</option>
                <option value="Quality Check done by">
                  Quality Check done by
                </option>
                <option value="Completed">Completed</option>
                <option value="Delivered">Delivered</option>
              </select>
            </div>

            {/* Buttons */}
            <div className="flex justify-end space-x-4 mt-6">
              <button
                type="button"
                className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition"
                onClick={closeAddTaskModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow-lg focus:outline-none"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTask;
