import React from "react";

const PostDetailsModal = ({ isOpen, onClose, post }) => {
  // If modal is not open, do not render anything
  if (!isOpen) return null;

  return (
    // Modal backdrop with semi-transparent background
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {/* Modal container with responsive width and padding */}
      <div className="bg-white p-6 top-28 rounded-lg shadow-lg w-1/2">
        {/* Post title */}
        <h2 className="text-xl font-bold mb-3 text-gray-900">{post.topic}</h2>

        {/* Post image with fallback handling */}
        {post.postPhoto ? (
          <div className="relative w-full h-40 overflow-hidden rounded mb-6">
            <img
              src={post.postPhoto}
              alt={post.topic}
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        ) : (
          <div className="w-full h-40 bg-gray-200 flex items-center justify-center text-gray-500 mb-4 rounded">
            No Image Available
          </div>
        )}

        {/* Post platform information */}
        <p className="text-gray-700 text-sm">
          Platform:{" "}
          <span className="font-medium">{post.platform || "N/A"}</span>
        </p>

        {/* Close button */}
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-gray-700 hover:bg-gray-800 text-white rounded-lg w-full transition duration-200"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PostDetailsModal;
