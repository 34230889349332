import React from 'react';
import Videooo from './loadingts.gif';

const Loading = () => {
  return (
    <div className="flex  justify-center backdrop-blur-sm z-50">
      <img src={Videooo} alt="Loading..." className="w-24 h-24 object-cover" />
    </div>
    // <div className="flex items-end h-[45vh] justify-center backdrop-blur-sm z-50">
    //   <img src={Videooo} alt="Loading..." className="w-24 h-24 object-cover" />
    // </div>
  );
};

export default Loading;
