import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Calendar, ChevronDown, ChevronLeft, ChevronRight, Plus } from "lucide-react";
import useOutsideClick from "../../Hooks/useClickOutside";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../context/RoleContext";

const CalendarComponent = () => {
  // const [events, setEvents] = useState([
  //   { title: "Team Meeting", date: "2025-02-25", color: "#3b82f6" },
  //   { title: "John's Birthday 🎉", date: "2025-02-28", color: "#f59e0b" },
  // ]);
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("dayGridMonth");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCreateEvent, setShowCreateEvent] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const calendarRef = useRef(null);
  const dropdownRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigator = useNavigate();
  const { role } = useRole();

  useOutsideClick(dropdownRef, () => setShowDropdown(false));

  // Function to handle adding a new event
  const handleAddEvent = (newEvent) => {
    setEvents([...events, newEvent]);
    setShowCreateEvent(false);
  };
  const handlePrev = () => {
    const api = calendarRef.current?.getApi();
    if (currentView === "yearView") {
      setYear((prev) => prev - 1);
    } else {
      api?.prev();
      setSelectedDate(new Date(api?.getDate())); // Ensure date updates properly
    }
  };

  const handleNext = () => {
    const api = calendarRef.current?.getApi();
    if (currentView === "yearView") {
      setYear((prev) => prev + 1);
    } else {
      api?.next();
      setSelectedDate(new Date(api?.getDate())); // Ensure date updates properly
    }
  };

  const handleToday = () => {
    setYear(new Date().getFullYear());
    setSelectedDate(new Date());
    calendarRef.current?.getApi()?.today();
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/taskevents`, { withCredentials: true });
        console.log(response.data);
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);




  return (
    <div className="min-h-auto shadow-md rounded-xl mt-4 bg-white">
      {/* Header */}
      <div className="flex flex-wrap items-center justify-between  p-4  gap-4">
        <div className="flex items-center gap-2">
          <button onClick={handleToday} className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
            Today
          </button>
          <button onClick={handlePrev} className="p-2 bg-gray-200 rounded-full hover:bg-gray-300">
            <ChevronLeft className="h-5 w-5" />
          </button>
          <button onClick={handleNext} className="p-2 bg-gray-200 rounded-full hover:bg-gray-300">
            <ChevronRight className="h-5 w-5" />
          </button>
        </div>
        <h2 className="text-lg font-semibold text-center flex-1">
          {currentView === "yearView" ? year : selectedDate.toDateString()}
        </h2>
        <div className="relative mt-3 sm:mt-0" ref={dropdownRef}>
          <button
            onClick={() => setShowDropdown(!showDropdown)}
            className="flex items-center bg-gray-200 px-4 py-2 rounded-lg hover:bg-gray-300"
          >

            {currentView === "dayGridMonth"
              ? "Month"
              : currentView === "timeGridWeek"
                ? "Week"
                : currentView === "listWeek"
                  ? "List"
                  : currentView === "timeGridDay"
                    ? "Day"
                    : currentView === "yearView"
                      ? "Year"
                      : "Custom"}

            <ChevronDown className="h-4 w-4 ml-2" />
          </button>
          {showDropdown && (
            <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg w-40 z-50">
              {[
                { id: "dayGridMonth", name: "Month" },
                { id: "timeGridWeek", name: "Week" },
                { id: "listWeek", name: "List" },
                { id: "yearView", name: "Year" },
              ].map((view) => (
                <button
                  key={view.id}
                  onClick={() => {
                    setCurrentView(view.id);
                    setShowDropdown(false);
                  }}
                  className="block px-4 py-2 hover:bg-gray-100 w-full text-left"
                >
                  {view.name}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Calendar Display */}
      {currentView === "yearView" ? (
        <div onClick={() => navigator(`/${role}/calender`)} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">

          {Array.from({ length: 12 }, (_, i) => {
            const month = (i + 1).toString().padStart(2, "0");
            return (
              <div key={`${year}-${month}`} className="border p-4 shadow-sm rounded-md bg-white">
                <h2 className="text-center text-lg font-semibold mb-2">
                  {new Date(year, i).toLocaleString("en-US", { month: "long" })}
                </h2>
                <div className=" overflow-hidden">
                  <FullCalendar
                    key={`${year}-${month}`} // 🔥 Forces re-render when year changes
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={false}
                    height="auto"
                    events={events}
                    initialDate={`${year}-${month}-01`}
                    eventColor="#1a73e8"
                    dateClick={(info) => {
                      setSelectedDate(new Date(info.date)); // ⬅️ Set selected date
                      setCurrentView("timeGridDay"); // ⬅️ Switch to day view
                    }}
                    dayHeaderClassNames="text-[10px] sm:text-xs text-gray-600"
                    dayCellClassNames="text-xs sm:text-sm"
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div onClick={() => navigator(`/${role}/calender`)} className="p-3  pt-0 overflow-hidden">
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
            initialView={currentView}
            key={currentView} // 🔥 Ensures re-render when changing views
            headerToolbar={false}
            events={events}
            eventColor="#1a73e8"
            height="auto"
            dayMaxEvents={true}
            fixedWeekCount={false}
            firstDay={0}
            expandRows={true}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarComponent;
