import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { FaRegClipboard, FaMoneyBillWave, FaUser, FaCalendarAlt } from 'react-icons/fa';
import axios from 'axios';
import useOutsideClick from '../../Hooks/useClickOutside';
import { toast } from 'react-toastify';

const ViewPayment = ({ selectedPayment, closeModal }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const closeRef = useRef(null);
  const [tasks, setTasks] = useState([]);
  const [copied, setCopied] = useState(false);
  
  useOutsideClick(closeRef, () => closeModal(false));

  useEffect(() => {
    if (selectedPayment) {
      fetchTasksByClient(selectedPayment.taskDetails);
    }
  }, [selectedPayment]);

  const fetchTasksByClient = async (clientId) => {
    try {
      const response = await axios.get(`${apiUrl}/api/fetchTaskwithClientID/${clientId}`, { withCredentials: true });
      setTasks(response.data);
    } catch (err) {
      console.error("Error fetching tasks:", err);
    }
  };

  const handleCopy = () => {
    if (!selectedPayment?.razorpay_payment_link) return;
    navigator.clipboard.writeText(selectedPayment.razorpay_payment_link)
      .then(() => {
        setCopied(true);
        toast.success("Payment link copied!");
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(err => console.error("Failed to copy: ", err));
  };

  return (
    <div className={`fixed inset-0 bg-gray-800 backdrop:blur backdrop  bg-opacity-75 flex items-center justify-center z-50 p-6 transition-opacity duration-300 ease-in-out `}>
      <div ref={closeRef} className="bg-white rounded-2xl shadow-xl max-h-[70vh] w-full sm:max-w-md md:max-w-2xl lg:max-w-3xl transform transition-transform duration-500 ease-in-out overflow-x-auto">
        <div className="flex justify-between items-center bg-gradient-to-r  from-blue-600 to-blue-800 text-white px-6 py-4">
          <h2 className="text-lg font-semibold">Payment Details</h2>
          <button onClick={closeModal} className="text-white hover:text-gray-300 focus:outline-none">
            <RxCross2 className="h-6 w-6" />
          </button>
        </div>
        <div className="p-6 space-y-6">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              { label: 'Payment ID', value: selectedPayment?.paymentId },
              { label: 'Amount', value: `₹${selectedPayment?.amount}`, Icon: FaMoneyBillWave },
              { label: 'Currency', value: selectedPayment?.currency },
              { label: 'Customer Name', value: selectedPayment?.customerName, Icon: FaUser },
              { label: 'Phone', value: selectedPayment?.customerPhone },
              { label: 'Organization ID', value: selectedPayment?.org_id },
              { label: 'Created At', value: new Date(selectedPayment?.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }), Icon: FaCalendarAlt },
              { label: 'Email', value: selectedPayment?.customerEmail },
            ].map(({ label, value, Icon }, index) => (
              <div key={index} className="flex items-center space-x-3">
                {Icon && <Icon className="w-5 h-5 text-gray-400" />}
                <div>
                  <p className="text-gray-500 text-sm">{label}</p>
                  <p className="font-medium text-gray-800">{value}</p>
                </div>
              </div>
            ))}
          </div>
          {selectedPayment?.razorpay_payment_link && (
            <div>
              <p className="text-gray-500 text-sm">Payment Link</p>
              <div className="flex items-center space-x-2 mt-2">
                <a href={selectedPayment.razorpay_payment_link} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline truncate">
                  {selectedPayment.razorpay_payment_link}
                </a>
                <button onClick={handleCopy} className="p-2 border border-gray-300 rounded-lg shadow-sm bg-gray-100 hover:bg-gray-200">
                  {copied ? "Copied!" : <FaRegClipboard className="w-5 h-5 text-gray-700" />}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end p-6">
          <button className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewPayment;
