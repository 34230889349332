import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import axios from "axios";
import { useRole } from "../../context/RoleContext";

const Attendance = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [data, setData] = useState([]);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const { role } = useRole();
    const isSuperAdmin = role === "superadmin";

    const fetchPresentEmployees = async () => {
        try {
            setLoading(true);
            const response = await axios.get(
                `${apiUrl}/api/employees/get-all-present-users`,
                { withCredentials: true }
            );
            // console.log("Fetched present employees:", response.data.users);
            setData(response.data.users || []); // Set data to fetched users or empty array if null
            setLoading(false);
        } catch (error) {
            console.error("Error fetching employees:", error);
            setData([]); // Reset data to an empty array if API fails
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPresentEmployees(); // Fetch data on component mount
    }, []);

    const handleReload = () => {
        // console.log("Reload clicked");
        setLoading(true);
        fetchPresentEmployees(); // Refetch data from the server
    };

    const handleEntriesChange = (value) => {
        setEntriesPerPage(value);
        setCurrentPage(1);
      };

    // Custom columns
    const columns = [
        { header: "ID", accessor: "emp_id" },
        { header: "Name", accessor: "name" },
        { header: "Email", accessor: "email" },
        { header: "Role", accessor: "role" },
        { header: "Department", accessor: "deptname" },
        ...(isSuperAdmin ? [{ header: "Organization", accessor: "org_id" }] : []),
    ];

    const actions = [
        { label: "🔄 Reload", onClick: handleReload },
    ];
    
  const startIndex = (currentPage - 1) * entriesPerPage;
  const paginatedData = data.slice(startIndex, startIndex + entriesPerPage);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

    return (
        <>
            <div className="p-4">
                {
                    // loading ? (
                    //     <p>Loading attendance data...</p>
                    // ) : 
                    (
                        <Table
                            title="Attendance"
                            columns={columns}
                            data={paginatedData} // Limit data to the selected number of entries
                            actions={actions}
                            onSearch={(value) => console.log("Search:", value)}
                            onEntriesChange={handleEntriesChange}
                            entriesOptions={[5, 10, 25, 50, 100, data.length]}
                            exportButton={true}
                            loading={loading}
                            currentPage={currentPage}
                            totalPages={Math.ceil(data.length / entriesPerPage)}
                            onPageChange={handlePageChange}
                            totalEntries={data.length}
                            entriesPerPage={entriesPerPage}
                        />
                    )}
            </div>
        </>
    );
};

export default Attendance;
