import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RoleProvider } from './context/RoleContext';
import { SocketProvider } from './context/SocketContext';
import PermissionProvider from './context/PermissionContext';
import { ToastContainer, Bounce } from 'react-toastify';
import LoggedUserProvider from './context/LoggedUser';
import { NotificationProvider } from './context/NotificationContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RoleProvider>
      <PermissionProvider>
        <LoggedUserProvider>
          <SocketProvider>
            <NotificationProvider>
          <App />
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Bounce}
            bodyClassName="toastifybody"
          />
            </NotificationProvider>
          </SocketProvider>
        </LoggedUserProvider>
      </PermissionProvider>
    </RoleProvider>
  </React.StrictMode>
);
