import { Navigate } from "react-router-dom";
import { usePermissions } from "./context/PermissionContext";
import { useRole } from "./context/RoleContext";

const ProtectedRoute = ({ permission, children }) => {
  const { permissions } = usePermissions();
  const { role } = useRole();

  // If permissions or role are not yet loaded, prevent rendering
  if ((!permissions) && (!role)) {
    return <div>Loading...</div>; // Show a loading indicator instead of navigating
  }
  // if the user does not have the required permission, redirect to the home page
  if (!(permissions.includes(permission) || role === "superadmin" || role === "admin")) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
