// import React, { useState } from "react";

// const EmailModal = ({ employee, onSubmit, onClose }) => {
//     const [subject, setSubject] = useState("");
//     const [message, setMessage] = useState("");

//     const handleSelectMailType = (type) => {
//         let preFilledMessage = "";
//         let preFilledSubject = "";

//         if (type === "warning") {
//           preFilledSubject = "Formal Warning Regarding Performance";
//           preFilledMessage = `Dear ${employee?.name},\n\nThis email serves as a formal warning regarding your performance in your role at [Company Name]. Upon recent review, we have identified certain areas where your work does not meet the expectations outlined for your position.

//       We value your contributions and believe in your potential to succeed in this role. To support your improvement, we encourage you to reach out to your manager or the HR team for guidance. Please take this warning seriously and focus on addressing the identified concerns immediately.

//       Your performance will be monitored closely over the coming [timeframe, e.g., weeks or months], and further action may be taken if the necessary improvements are not made.\n\nSincerely,\n[Your Company Name]`;
//         } else if (type === "termination") {
//             preFilledSubject = "Notice of Employment Termination";
//             preFilledMessage = `Dear ${employee?.name},\n\nWe regret to inform you that your employment with [Company Name] is being terminated, effective [Insert Termination Date]. This decision was made after careful consideration of [reason, e.g., performance issues, policy violations, or organizational restructuring].

//         Please note that your final paycheck, including any outstanding payments (e.g., accrued leave), will be processed in accordance with company policy. Additionally, we request that you return any company property, including [examples: ID card, equipment, documentation], by your final working day.

//         Your access to company systems and resources will be revoked effective immediately following your termination. If applicable, HR will provide you with further instructions regarding your benefits, severance, or any additional documentation.

//         If you have any questions or require clarification, please feel free to reach out to the HR department. We appreciate your contributions during your time with [Company Name] and wish you success in your future endeavors.\n\nSincerely,\n[Your Company Name]`;
//           } else if (type === "suspension") {
//           preFilledSubject = "Notification of Suspension";
//           preFilledMessage = `Dear ${employee?.name},\n\nWe regret to inform you that you have been placed on suspension from your role at [Company Name], effective [Insert Start Date]. This decision has been made following [reason, e.g., an investigation into misconduct or breach of company policies].

//       During the suspension period, you are relieved of all work-related duties and are prohibited from accessing company facilities, systems, or resources unless explicitly authorized. This measure is intended to allow a thorough review of the situation, after which appropriate actions will be determined in alignment with company policies.

//       We encourage you to cooperate fully with the ongoing process. If you have any questions or wish to provide additional information, please reach out to HR at your earliest convenience.\n\nThank you for your understanding.\n\nSincerely,\n[Your Company Name]`;
//         }

//         setMessage(preFilledMessage);
//         setSubject(preFilledSubject);
//       };

//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//             <div className="bg-white rounded-lg shadow-lg w-[90%] md:w-[500px] p-6">
//                 {/* Header */}
//                 <div className="mb-4">
//                     <h2 className="text-lg font-semibold text-gray-800">
//                         Send Email to {employee?.name}
//                     </h2>
//                 </div>

//                 {/* Buttons for Pre-typed Messages */}
//                 <div className="flex gap-2 mb-4">
//                     <button
//                         onClick={() => handleSelectMailType("warning")}
//                         className="px-4 py-2 bg-yellow-500 text-white text-sm font-medium rounded-lg hover:bg-yellow-600 transition"
//                     >
//                         Warning
//                     </button>
//                     <button
//                         onClick={() => handleSelectMailType("suspension")}
//                         className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-lg hover:bg-blue-600 transition"
//                     >
//                         Suspension
//                     </button>
//                     <button
//                         onClick={() => handleSelectMailType("termination")}
//                         className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-lg hover:bg-red-600 transition"
//                     >
//                         Termination
//                     </button>
//                 </div>

//                 {/* Subject and Message Inputs */}
//                 <div className="mb-4">
//                     <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
//                         Subject
//                     </label>
//                     <input
//                         type="text"
//                         id="subject"
//                         value={subject}
//                         onChange={(e) => setSubject(e.target.value)}
//                         placeholder="Subject"
//                         className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 focus:outline-none text-sm"
//                     />
//                 </div>

//                 <div className="mb-4">
//                     <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
//                         Message
//                     </label>
//                     <textarea
//                         id="message"
//                         value={message}
//                         onChange={(e) => setMessage(e.target.value)}
//                         placeholder="Message"
//                         rows="5"
//                         className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200 focus:outline-none text-sm"
//                     />
//                 </div>

//                 {/* Action Buttons */}
//                 <div className="flex justify-end gap-2">
//                     <button
//                         onClick={onClose}
//                         className="px-4 py-2 bg-gray-300 text-gray-800 text-sm font-medium rounded-lg hover:bg-gray-400 transition"
//                     >
//                         Cancel
//                     </button>
//                     <button
//                         onClick={() => onSubmit(subject, message)}
//                         className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-lg hover:bg-blue-700 transition"
//                     >
//                         Send
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EmailModal;

import React, { useRef, useState } from "react";
import useOutsideClick from "../Hooks/useClickOutside";
import { useRole } from "../context/RoleContext";
import { useLoggedInUser } from "../context/LoggedUser";

const EmailModal = ({
  employee,
  onSubmit,
  onClose,
  loading,
  candidateData,
  isClient = false,
}) => {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [placeholders, setPlaceholders] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const { role } = useRole();
  const { company, loggedInUser } = useLoggedInUser();
  const currentUrl = window.location.href;

  const handleSelectMailType = (type) => {
    let preFilledMessage = "";
    let preFilledSubject = "";
    setSelectedType(type);

    if (type === "warning") {
      preFilledSubject = "Formal Warning Regarding Performance";
      preFilledMessage = `Dear ${employee?.name
        },\n\nThis email serves as a formal warning regarding your performance in your role as ${employee?.role_name
        } at ${company ? company : "[Company Name]"
        }. Upon recent review, we have identified certain areas where your work does not meet the expectations outlined for your position.\n\nWe value your contributions and believe in your potential to succeed in this role. To support your improvement, we encourage you to reach out to your manager or the HR team for guidance. Please take this warning seriously and focus on addressing the identified concerns immediately.\n\nYour performance will be monitored closely over the coming [timeframe]. Further action may be taken if necessary.`;
    } else if (type === "termination") {
      preFilledSubject = "Notice of Employment Termination";
      preFilledMessage = `Dear ${employee?.name
        },\n\nWe regret to inform you that your employment with ${company ? company : "[Company Name]"
        } is being terminated, effective [Termination Date]. This decision was made after careful consideration of [reason].\n\nPlease note that your final paycheck, including any outstanding payments (e.g., accrued leave), will be processed in accordance with company policy. Additionally, we request that you return any company property, including [examples], by your final working day.`;
    } else if (type === "suspension") {
      preFilledSubject = "Notification of Suspension";
      preFilledMessage = `Dear ${employee?.name
        },\n\nWe regret to inform you that you have been placed on suspension from your role as ${employee?.role_name
        } at ${company ? company : "[Company Name]"
        }, effective [Start Date] until [End Date]. This decision has been made following [reason].\n\nThank you for your understanding.`;
    } else if (type === "Internship confirmation") {
      preFilledSubject = "Confirmation of Internship Offer";
      preFilledMessage = `Dear ${candidateData?.name},
      
      I hope this email finds you well. We are pleased to offer you the position of ${candidateData?.role
        } at ${company ? company : "[Company Name]"}. 
      
      Joining Date: July 24th, 2024  
      Monthly Stipend: ₹10,000 per month  
      
      To confirm your acceptance of this internship offer, please reply to this email and attach scanned soft copies of the following documents:
      
      - Aadhaar Card  
      - PAN Card  
      - Rented house address details (if applicable)  
      - Degree or latest semester mark sheet (highest education achieved)  
      - Bank account details (provide the following):  
        - Bank Name  
        - Account Holder Name  
        - Account Number  
        - IFSC Code  
        - Contact Number  
        - Passbook (if available)  
      - COVID Vaccination Certificate  
      - Driving License (if applicable, include vehicle number & type)  
      
      Additional Details:  
      - Office Timings: 10 AM to 7 PM (Monday to Saturday)  
      - Duration: 6 months (full-time commitment required)  
      - Office Address: 
        Requin Solutions Pvt. Ltd  
        6/397, Sec 6, Malviya Nagar, Jaipur  
        In front of North Western Railway Headquarters, Jagatpura Road, Jaipur  

      
      Please bring photocopies of all submitted documents on your joining date.  
      
      For any questions or further clarifications, feel free to reach out at hr@requinsolutions.com or +91-9352220187.
      
      Thanks & Regards,  
      ${loggedInUser?.name}
      Human Resource Executive  
      Requin Solutions Pvt. Ltd.`;
    } else if (type === "project_kickoff") {
      preFilledSubject = "Welcome to [Project Name] – Next Steps";
      preFilledMessage = `Dear ${employee?.name},\n\nWe are excited to officially begin our collaboration on [Project Name] with [Company Name]. Our team is fully committed to ensuring the success of this project and delivering exceptional results.\n\nTo kickstart the process, please find below the next steps:\n\n- Project Timeline & Deliverables: A detailed roadmap outlining milestones and expected timelines.\n- Communication & Meetings: Weekly updates will be provided via email, and we can schedule regular check-ins as needed.\n- Access & Documentation: Please share any relevant documents, credentials, or additional requirements for smooth execution.\n\nIf you have any specific expectations or queries, feel free to reach out. We look forward to a productive partnership.\n\nBest Regards,`;
    } else if (type === "project_update") {
      preFilledSubject = "Project Update – [Project Name]";
      preFilledMessage = `Dear ${employee?.name},\n\nI hope you're doing well. I wanted to share a quick update on the progress of [Project Name]:\n\n- Current Status: [Brief update on what has been completed]\n- Upcoming Milestones: [Mention the next steps and expected completion dates]\n- Challenges (if any): [List any roadblocks and proposed solutions]\n\nPlease review the attached report for more details. If you have any feedback or adjustments, let us know at your earliest convenience. Looking forward to your thoughts.\n\nBest Regards,`;
    } else if (type === "project_completion") {
      preFilledSubject = "[Project Name] – Final Deliverables & Handover";
      preFilledMessage = `Dear ${employee?.name},\n\nWe are pleased to inform you that [Project Name] has been successfully completed as per the agreed scope and timeline.\n\nEnclosed, you will find:\n- Final Deliverables: [List of completed items]\n- User Guides & Documentation: [Instructions or resources for future reference]\n- Post-Project Support: [Details on warranty, maintenance, or support options]\n\nWe appreciate your trust and collaboration throughout this journey. Should you require any further assistance, feel free to reach out.\n\nLooking forward to working together again in the future!\n\nBest Regards,`;
    } else if (type === "project_delivery") {
      preFilledSubject = "Project Deliverables – [Project Name]";
      preFilledMessage = `Dear ${employee?.name},\n\nI hope you're doing well. We are pleased to inform you that the deliverables for [Project Name] are now ready. Please find the link below to access the files:\n\n🔗 [Delivery Link]\n\nKindly review the provided materials and let us know if you require any modifications or have any feedback. If you encounter any issues accessing the link, please inform us, and we'll assist you immediately.\n\nLooking forward to your confirmation.\n\nBest Regards,`;
    } else {
      preFilledMessage = "";
      preFilledSubject = "";
    }

    setMessage(preFilledMessage);
    setSubject(preFilledSubject);

    // Extract placeholders
    const matches = preFilledMessage.match(/\[.*?\]/g) || [];
    const placeholderKeys = matches.map((match) => match.slice(1, -1));
    setPlaceholders(placeholderKeys);
  };
  const fixedSignature = `\n\nSincerely,\n${loggedInUser?.name}(${role})\n${loggedInUser?.email} \n${company ? company : "[Your Company Name]"}`;

  const handlePlaceholderChange = (key, value) => {
    const updatedMessage = message.replace(new RegExp(`\\[${key}\\]`, "g"), value);
    setMessage(updatedMessage);
  };

  const closeref = useRef(null);
  useOutsideClick(closeref, onClose);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      {loading ? (
        <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl max-h-[70%] overflow-x-auto p-6">
          Loading.....
        </div>
      ) : (
        <div
          ref={closeref}
          className="bg-white rounded-lg shadow-lg w-full max-w-2xl max-h-[70%] overflow-x-auto p-6"
        >
          <div className="mb-4">
            <h2 className="text-lg font-semibold text-gray-800">
              Send Email to {employee?.name}
            </h2>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Select Email Type
            </label>
            <select
              value={selectedType}
              onChange={(e) => handleSelectMailType(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg text-sm"
            >
              <option disabled value="">
                -- Select Type --
              </option>
              {currentUrl.endsWith("candidates") ? (
                <option value="Internship confirmation">
                  Internship Confirmation
                </option>
              ) : (isClient) ?
                <>
                  <option value="project_kickoff">Project Kickoff</option>
                  <option value="project_update">Project Update</option>
                  <option value="project_completion">Project Completion</option>
                  <option value="project_delivery">Project Delivery</option>
                </> :
                (
                  <>
                    <option value="warning">Warning</option>
                    <option value="suspension">Suspension</option>
                    <option value="termination">Termination</option>
                  </>
                )}
              <option value="custom">Custom</option>
            </select>
          </div>

          {placeholders.length > 0 && (
            <div className="mb-4">
              <h3 className="text-sm font-medium text-gray-800 mb-2">Fill in the placeholders:</h3>
              {placeholders.map((key, index) => (
                <div key={index} className="mb-2">
                  <label className="block text-sm font-medium text-gray-700">{key.replace(/_/g, " ")}:</label>
                  <input type="text" onChange={(e) => handlePlaceholderChange(key, e.target.value)} className="w-full px-4 py-2 border rounded-lg text-sm" />
                </div>
              ))}
            </div>

          )}

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Subject
            </label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-4 py-2 border rounded-lg text-sm"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Message
            </label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="5"
              className="w-full px-4 py-2 border rounded-lg text-sm"
            />
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={() => onSubmit(subject, message + fixedSignature)}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailModal;
