import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import axios from "axios";
import { toast } from "react-toastify";
import { useRole } from "../../context/RoleContext";
import AddRoleModal from "./AddRoleModal";

const AllRoles = () => {
    const [roles, setRoles] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [initialRoleData, setInitialRoleData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredRoles, setFilteredRoles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [entries, setEntries] = useState(10);
    const { role } = useRole();
    const isSuperAdmin = role === "superadmin";

    // Fetch roles
    const fetchRoles = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/roles`, {
                withCredentials: true,
            });
            setFilteredRoles(response.data);
            setRoles(response.data);
        } catch (error) {
            toast.error("Error fetching roles");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRoles();
    }, [apiUrl]);

    const handleReload = () => {
        fetchRoles();
    };

    const handleRoleEntriesChange = (value) => {
        setEntries(value);
        setCurrentPage(1);
    };

    const columns = [
        ...(isSuperAdmin ? [{ header: "Organization ID", accessor: "org_id" }] : []),
        { header: "ID", accessor: "role_id" },
        { header: "Role Name", accessor: "role_name" },
        { header: "Created At", accessor: "created_at" },
    ];

    const actions = [
        { label: "🔄 Reload", onClick: handleReload },
        // ...(isSuperAdmin ?  [{ label: "➕ Add Transaction", primary: true, onClick: () => setIsAddModalOpen(true) }] : []),
    ];

    const closeAddModal = () => {
        setIsAddModalOpen(false);
        fetchRoles();
    };
    const handleRolePageChange = (page) => {
        setCurrentPage(page);
      };
    const HandleSearch = (value) => {
        setFilteredRoles(
            roles.filter((role) =>
                role.role_name.toLowerCase().includes(value.toLowerCase()) ||
                role.role_id.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const startIndex = (currentPage - 1) * entries;
  const RolespaginateData = filteredRoles.slice(startIndex, startIndex + entries);


    return (
        <div className="p-4">
            <Table
                title="Roles Table"
                columns={columns}
                data={RolespaginateData}
                actions={actions}
                onSearch={(value) => HandleSearch(value)}
                onRowClick={(row) => {
                    setIsAddModalOpen(true);
                    setInitialRoleData(row);
                }}
                currentPage={currentPage}
                totalPages={Math.ceil(filteredRoles.length / entries)}
                onPageChange={handleRolePageChange}
                totalEntries={filteredRoles.length}
                entriesPerPage={entries}
                onEntriesChange={handleRoleEntriesChange}
                entriesOptions={[5, 10, 25, 50, 100]}
                exportButton={false}
                loading={loading}

            />
            {isAddModalOpen && <AddRoleModal closeAddModal={closeAddModal} setIsAddModalOpen={setIsAddModalOpen} initialRoleData={initialRoleData} />}
        </div>
    );
};

export default AllRoles;