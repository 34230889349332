import React, { useState } from "react";
import {
  ArrowIconDown,
  ArrowIconUp,
  DashboardIcon,
  DotIcon,
  PeopleIcon,
  TaskIcon,
  TeamIcon,
  TransactionsIcon,
  EffortSheetIcon,
  QuickPanelTogglerIcon,
  FreelancerIcon,
  LeadsIcon,
  CalendarIcon
} from '../utills/Svgs';
import { ContentGeneratorIcon } from "../utills/Svgs";
import { NavLink } from 'react-router-dom';
import { usePermissions } from '../context/PermissionContext';
import { useRole } from '../context/RoleContext';

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleMenu = (menu) => {
    setExpandedMenu((prev) => (prev === menu ? null : menu));
  };
  const { permissions } = usePermissions();
  const { role } = useRole();

  const renderNavLink = (to, Icon, label, submenu = null, menuKey = null) => {
    const isSubmenuOpen = expandedMenu === menuKey;

    return (
      <div>
        {/* Parent Menu */}
        {to ? (
          <NavLink
            to={to}
            className={({ isActive }) =>
              `group flex items-center p-2 rounded-lg transition-ease duration-300 ${isActive ? 'bg-gray-100 text-[#3758ff]' : 'hover:bg-gray-200'}`
            }
          >
            {/* Icon Section */}
            <span className="opacity-50 transition-ease duration-300 group-hover:opacity-100">
              <Icon />
            </span>

            {/* Text Section */}
            <span className={`ml-4 text-gray-800 text-opacity-100 ${isExpanded ? 'block' : 'hidden'} group-hover:text-[#3758ff]`}>
              {label}
            </span>
          </NavLink>
        ) : (
          <div
            className={`group flex items-center p-2 rounded-lg cursor-pointer transition-ease duration-300 hover:bg-gray-200`}
            onClick={(e) => {
              if (submenu) {
                e.preventDefault();
                toggleMenu(menuKey);
              }
            }}
          >
            {/* Icon Section */}
            <span className="opacity-50 transition-ease duration-300 group-hover:opacity-100 hover:!opacity-50">
              <Icon />
            </span>

            {/* Text Section */}
            <span className={`ml-4 text-gray-800 text-opacity-100 ${isExpanded ? 'block' : 'hidden'} group-hover:text-[#3758ff]`}>
              {label}
            </span>

            {/* Arrow Icon for Submenu */}
            {submenu && (
              <span className={`ml-auto ${isExpanded ? 'block' : 'hidden'}`}>
                {isSubmenuOpen ? <ArrowIconUp /> : <ArrowIconDown />}
              </span>
            )}
          </div>
        )}

        {/* Submenu */}
        {submenu && isSubmenuOpen && (
          <ul className={`ml-2 mt-2 space-y-2 ${isExpanded ? 'block' : 'hidden'}`}>
            {submenu.map((item) => (
              <li key={item.to}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) =>
                    `group flex items-center p-2 rounded-lg transition-ease duration-300 ${isActive ? 'bg-gray-100 text-[#3758ff]' : 'hover:bg-gray-100'}`
                  }
                >
                  {/* Icon Section */}
                  <span className="opacity-50 transition-ease duration-300 group-hover:opacity-100 hover:!opacity-50">
                    <DotIcon />
                  </span>

                  {/* Text Section */}
                  <span className="ml-4 text-gray-600 text-opacity-100 group-hover:text-[#3758ff]">
                    {item.label}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <aside
      className={`md:flex flex-col top-0 left-0 h-full bg-white shadow-lg transition-all duration-300 px-4 ${isExpanded ? 'w-72' : 'w-20'} overflow-auto scrollbar-hidden flex-shrink-0`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="transition-ease duration-300 space-y-4 mt-4 mb-10">
        {/* Dashboard */}
        {renderNavLink("dashboard", DashboardIcon, "Dashboard")}

        {(role === "superadmin" || role === "admin" || permissions.includes("SeeClient") || permissions.includes("SeeClientsOrder") || permissions.includes("SeeEmployess") || permissions.includes("SeePresentEmployee") || permissions.includes("SeeLeads") || permissions.includes("GetRolesWithPermission") || permissions.includes("SeePermissions") || permissions.includes("SeeTeam") || permissions.includes("SeeTransaction")) &&
          <>
            <hr className="my-6 border-gray-200" />
            <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Instances</p>
          </>
        }

        {/* Clients */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeClient") || permissions.includes("SeeClientsOrder")) &&
          renderNavLink(
            null,
            PeopleIcon,
            "Clients",
            [
              (role === "superadmin" || role === "admin" || permissions.includes("SeeClient")) ? { to: "clients/all", label: "List" } : null,
              // (role === "superadmin" || role === "admin" || permissions.includes("SeeClientsOrder")) ? { to: "clients/orders", label: "Orders" } : null,
            ].filter(Boolean),
            "clients"
          )}

        {/* Employees */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeEmployess") || permissions.includes("SeePresentEmployee")) && renderNavLink(
          null,
          PeopleIcon,
          "Employees",
          [
            (role === "superadmin" || role === "admin" || permissions.includes("SeeEmployess")) ? { to: "employees/all", label: "List" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeePresentEmployee")) ? { to: "employees/attendance", label: "Attendance" } : null,
          ].filter(Boolean),
          "employees"
        )}
        {/* Leads */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeLeads")) && renderNavLink("leads/all", LeadsIcon, "Leads")}
        {/* {(role === "superadmin" || role === "admin" || permissions.includes("SeeLeads")) && renderNavLink(

          null,
          LeadsIcon,
          "Leads",
          [
            (role === "superadmin" || role === "admin" || permissions.includes("SeeLeads")) ? { to: "leads/all", label: "List" } : null,

          ].filter(Boolean),
          "leads"
        )} */}
        {/* posts */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeePosts")) && renderNavLink("posts", FreelancerIcon, "Posts")}
        {/* posts */}
        {(role === "superadmin" || role === "admin" || permissions.includes("Jobs")) && renderNavLink("jobs-openings", FreelancerIcon, "Jobs")}
        {/* Permissions */}
        {(role === "superadmin" || role === "admin" || permissions.includes("GetRolesWithPermission") || permissions.includes("SeePermissions")) && renderNavLink(

          null,
          EffortSheetIcon,
          "Permissions",
          [
            (role === "superadmin" || role === "admin" || permissions.includes("GetRolesWithPermission")) ? { to: "roles/all", label: "Role List" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeePermissions")) ? { to: "permissions/all", label: "Permission List" } : null,

          ].filter(Boolean),
          "permissions"
        )}

        {/* Team */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTeam")) && renderNavLink("team", TeamIcon, "Team")}

        {/* Transactions */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTransaction")) && renderNavLink("transactions", TransactionsIcon, "Transactions")}
        {(role === "superadmin" || role === "admin" || permissions.includes("Candidates")) && renderNavLink("candidates", PeopleIcon, "Candidates")}
        {(role === "superadmin" || role === "admin" || permissions.includes("PaySlip")) && renderNavLink("pay-slip", PeopleIcon, "Pay Slip")}

        {/* Efforts */}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeEfforts")) &&
          <>
            <hr className="my-6 border-gray-200" />
            <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Efforts</p>
          </>
        }

        {(role === "superadmin" || role === "admin" || permissions.includes("SeeEfforts")) && renderNavLink("my-efforts", EffortSheetIcon, "Efforts")}


        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) &&
          <>
            <hr className="my-6 border-gray-200" />
            <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Task</p>
          </>
        }
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink(
          null,
          TaskIcon,
          "Tasks",
          [
            (role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) ? { to: "tasks/all", label: "All Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeRunningTask")) ? { to: "tasks/running", label: "Running Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeCompletedTask")) ? { to: "tasks/completed", label: "Completed Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeDeliveredTask")) ? { to: "tasks/delivered", label: "Delivered Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeDelayedTask")) ? { to: "tasks/overdue", label: "Overdue Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeDeletedTask")) ? { to: "tasks/deleted", label: "Deleted Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeQualityTask")) ? { to: "tasks/quality-check", label: "Quality Check Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeAssignedTask")) ? { to: "tasks/assigned", label: "Assigned Tasks" } : null,
            (role === "superadmin" || role === "admin" || permissions.includes("SeeApprovedTask")) ? { to: "tasks/approved", label: "Approved Tasks" } : null,
          ].filter(Boolean),
          "tasks"
        )}




        {/* {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask") || permissions.includes("SeeTask")) && renderNavLink(

          null,
          TaskIcon,
          "tasks",
          [
           

          ].filter(Boolean),
          "tasks"
        )}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks/all", TaskIcon, "All Tasks")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks-running", TaskIcon, "Running Tasks")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks-completed", TaskIcon, "Completed Tasks")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks-delivered", TaskIcon, "Delivered Tasks")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks-overdue", TaskIcon, "Overdue Tasks")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks-deleted", TaskIcon, "Deleted Tasks")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTask")) && renderNavLink("tasks-quality-check", TaskIcon, "Quality Check Tasks")} */}

        {(role === "superadmin" || role === "admin" || permissions.includes("SeeBDAreports") || permissions.includes("SeeTaskReport")) &&
          <>
            <hr className="my-6 border-gray-200" />
            <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Reports</p>
          </>
        }

        {(role === "superadmin" || role === "admin" || permissions.includes("SeeBDAreports")) && renderNavLink("bda-report", EffortSheetIcon, "BDA Reports")}
        {(role === "superadmin" || role === "admin" || permissions.includes("SeeTaskReport")) && renderNavLink("task-report", EffortSheetIcon, "Task Reports")}


        {/* Private Space */}

        {(role === "superadmin" || role === "admin" || permissions.includes("Samples") || permissions.includes("PrivateSpace")) &&
          <>
            <hr className="my-6 border-gray-200" />
            <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Files</p>
          </>
        }
        {/* {(role === "superadmin" || role === "admin" || permissions.includes("Samples")) && renderNavLink("samples", FreelancerIcon, "Samples")} */}
        {(role === "superadmin" || role === "admin" || permissions.includes("PrivateSpace")) && renderNavLink("private-space", QuickPanelTogglerIcon, "Private Space")}
        {(role === "superadmin" || role === "admin") && renderNavLink("all-private-space", QuickPanelTogglerIcon, "All Private Space")}
        <>
          <hr className="my-6 border-gray-200" />
          <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Calendar</p>
        </>
        {renderNavLink("calender", CalendarIcon, "Calendar")}

        {(role === "superadmin" || role === "admin" || permissions.includes("GeneratePayments")) &&
          <>
            <hr className="my-6 border-gray-200" />
            <p className={`text-gray-500 font-semibold text-sm mt-4 ${isExpanded ? 'block' : 'hidden'}`}>Payment Gateway</p>
          </>
        }
        {(role === "superadmin" || role === "admin" || permissions.includes("GeneratePayments")) && renderNavLink("razorpay", TransactionsIcon, "Razorpay")}

        {((role === "superadmin" || role === "admin")|| permissions.includes("ContentGeneration")) && renderNavLink("content-generator", ContentGeneratorIcon, "Content Generator")}
      </div>
    </aside>
  );
};

export default Sidebar;

