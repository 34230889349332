import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '../../components/Table';
import AddPayment from './AddPayment';
import ViewPayment from './ViewPayment';

const RazorPay = () => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [data, setData] = useState([]); // Store full payment data
    const [entries, setEntries] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [filteredData, setFilteredData] = useState([]); // Fix: Initialize as empty array
    const [loading, setLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchPayments = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/api/payments`, { withCredentials: true });
            setData(response.data.payments);
        } catch (error) {
            console.error("Error fetching payments:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPayments();
    }, [apiUrl]);

    const handleReload = () => {
        fetchPayments();
    };

    useEffect(() => {
        setFilteredData([...data]);
    }, [data]);

    const handleEntriesChange = (value) => {
        setEntries(value);
        setCurrentPage(1);
    };

    const closeAddModal = () => {
        setIsAddModalOpen(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Fix: Store the full payment object instead of just the ID
    const handleRowClick = (payment) => {
        setSelectedPayment(payment);
        setIsModalOpen(true);
    };

    const columns = [
        { header: "Transaction ID", accessor: "paymentId" },
        { header: "Payment ID", accessor: "razorpay_payment_id" },
        { header: "Order ID", accessor: "razorpay_order_id" },
        // { header: "Client", accessor: "clientDetail" },
        // { header: "Task", accessor: "taskDetails" },
        { header: "Amount", accessor: "amount" },
        { header: "Currency", accessor: "currency" },
        { header: "Date", accessor: "createdAt" },
        { header: "Payment Link", accessor: "razorpay_payment_link" , className: "text-blue-900 font-semibold" },

        { header: "Payment Status", accessor: "status", className: "text-green-600 font-semibold" },
    ];

    const actions = [
        { label: "🔄 Reload", onClick: handleReload },
        { label: "➕ Create Payment", primary: true, onClick: () => setIsAddModalOpen(true) },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };

    const handleOnSearch = (value) => {
        const searchValue = value?.toLowerCase();
        if (!searchValue) {
            setFilteredData(data);
            return;
        }
        const filteredResults = data.filter((item) =>
            item.razorpay_payment_id?.toLowerCase()?.includes(searchValue) ||
            item.razorpay_order_id?.toLowerCase()?.includes(searchValue) ||
            item.clientDetail?.toLowerCase()?.includes(searchValue) ||
            item.taskDetails?.toLowerCase()?.includes(searchValue)
        );
        setFilteredData(filteredResults);
    };

    const startIndex = (currentPage - 1) * entries;
  const paginatedData = filteredData.slice(startIndex, startIndex + entries);


    return (
        <>
            <div className='p-4'>
                <div className="py-6">
                    <Table
                        title="Payments"
                        columns={columns}
                        data={paginatedData}
                        actions={actions}
                        onSearch={handleOnSearch}
                        onEntriesChange={handleEntriesChange}
                        entriesOptions={[5, 10, 25, 50, 100, data.length]}
                        exportButton={true}
                        onRowClick={handleRowClick}
                        loading={loading}
                        entries={entries}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        totalEntries={filteredData.length}
                        totalPages={Math.ceil(filteredData.length / entries)}
                    />
                </div>
            </div>
            {isAddModalOpen && <AddPayment closeAddModal={closeAddModal} fetchPayments={fetchPayments} />}
            {isModalOpen && <ViewPayment closeModal={closeModal} selectedPayment={selectedPayment} />}
        </>
    );
};

export default RazorPay;
