import React, { useState, useEffect } from "react";
import AddPostModal from "./AddPosts";
import PostDetailsModal from "./PostDetailsModal";
import axios from "axios";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loading from "../../components/Loading";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [category, setCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/posts/get-post`, { withCredentials: true });
        setPosts(response.data.data);
      } catch (err) {
        setError("Error fetching posts");
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, []);

  const handleAddPost = (newPost) => {
    setPosts((prevPosts) =>
      prevPosts.some((post) => post._id === newPost._id)
        ? prevPosts.map((post) => (post._id === newPost._id ? newPost : post))
        : [...prevPosts, newPost]
    );
  };

  const handleEditPost = (post) => {
    setSelectedPost(post);
    setIsAddModalOpen(true);
  };

  const handleDeletePost = async (postId) => {
    if (window.confirm("Are you sure you want to delete this post?")) {
      try {
        await axios.delete(`${apiUrl}/api/posts/delete-post/${postId}`, { withCredentials: true });
        setPosts(posts.filter((post) => post._id !== postId));
      } catch (error) {
        console.error("Error deleting post:", error);
      }
    }
  };

  const handleOpenDetails = (post) => {
    setSelectedPost(post);
    setIsDetailsModalOpen(true);
  };

  const todaysPosts = posts.filter(
    (post) => new Date(post.createdAt).toDateString() === new Date().toDateString()
  );

  const filteredPosts = category === "All" ? posts : posts.filter((post) => post.platform === category);

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 1024 }, items: 3 },
    desktop: { breakpoint: { max: 1024, min: 768 }, items: 2 },
    tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      {/* Header */}
      <h1 className="text-2xl font-bold text-gray-900 mb-4">Content Hub</h1>

      {/* Carousel for Today's Posts */}
      {todaysPosts.length > 0 && (
        <Carousel responsive={responsive} infinite autoPlay>
          {todaysPosts.map((post) => (
            <div key={post._id} className="relative p-2">
              <img
                src={post.postPhoto || "/placeholder.png"}
                alt={post.topic}
                className="rounded-lg shadow-md w-full"
              />
              {/* Edit & Delete Buttons on Carousel */}
              {/* <div className="absolute top-2 right-2 flex space-x-2">
                <button onClick={() => handleEditPost(post)} className="text-blue-600 hover:text-blue-800">
                  <FaEdit size={18} />
                </button>
                <button onClick={() => handleDeletePost(post._id)} className="text-red-600 hover:text-red-800">
                  <FaTrash size={18} />
                </button>
              </div> */}
            </div>
          ))}
        </Carousel>
      )}

      {/* Filter Buttons */}
      <div className="flex justify-center my-4 space-x-2">
        {["All", "Blog", "Twitter", "Instagram"].map((cat) => (
          <button
            key={cat}
            onClick={() => setCategory(cat)}
            className={`px-4 py-2 rounded-full border ${category === cat ? "bg-blue-600 text-white" : "bg-white text-gray-900 border-gray-300"
              }`}
          >
            {cat}
          </button>
        ))}
      </div>

      {/* Posts Grid */}
      {loading ? (
        <Loading />
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : filteredPosts.length === 0 ? (
        <p className="text-center text-gray-500">No posts found.</p>
      ) : (
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {filteredPosts.map((post) => (
          <div
            key={post._id}
            className="relative group cursor-pointer"
            onClick={() => handleOpenDetails(post)}
          >
            <div className="relative aspect-square rounded-lg overflow-hidden">
              <img
                src={post.postPhoto || "/placeholder.png"}
                alt={post.topic}
                className="absolute inset-0 w-full h-full object-cover"
              />
      
              {/* Overlay with Post Topic (Visible on Hover) */}
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center text-white text-lg font-bold transition">
                <p>{post.topic}</p>
                <p>{post.platform}</p>
              </div>
            </div>
            
            {/* Edit & Delete Buttons (Visible on Hover) */}
            <div className="absolute top-2 right-2 flex space-x-2 opacity-0 group-hover:opacity-100 transition duration-200">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditPost(post);
                }}
                className="text-blue-600 hover:text-blue-800"
              >
                <FaEdit size={18} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeletePost(post._id);
                }}
                className="text-red-600 hover:text-red-800"
              >
                <FaTrash size={18} />
              </button>
            </div>
          </div>
        ))}
      </div>
      

      )}

      {/* Floating Add Button */}
      <button
        onClick={() => {
          setSelectedPost(null);
          setIsAddModalOpen(true);
        }}
        className="fixed bottom-4 right-4 w-12 h-12 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transition-colors flex items-center justify-center"
      >
        <FaPlus size={20} />
      </button>

      {/* Modals */}
      <AddPostModal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)} onAddPost={handleAddPost} post={selectedPost} />
      <PostDetailsModal isOpen={isDetailsModalOpen} onClose={() => setIsDetailsModalOpen(false)} post={selectedPost} />
    </div>
  );
};

export default Posts;
