import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useRole } from "./context/RoleContext";
import Layout from "./components/Layout";
import DashboardPage from "./pages/DashboardPage";
import NotFoundPage from "./pages/NotFoundPage";
import AllClients from "./pages/Clients/AllClients";
import AllEmployees from "./pages/Employees/AllEmployees";
import Login from "./components/Login";
import ClientLogin from "./components/ClientLogin";
import Orders from "./pages/Clients/Orders";
import Attendance from "./pages/Employees/Attendance";
import Transactions from "./pages/Transactions/Transactions";
import setupAxiosInterceptors from "./context/setupAxiosInterceptors";
import AllTasks from "./pages/Tasks/AllTasks";
import RunningTask from "./pages/Tasks/RunningTask";
import CompletedTask from "./pages/Tasks/CompletedTask";
import DeliveredTask from "./pages/Tasks/DeliveredTask";
import OverdueTask from "./pages/Tasks/OverDueTask";
import DeletedTask from "./pages/Tasks/DeletedTask";
import QualityCheckTask from "./pages/Tasks/QualityCheckTask";
import TeamHierarchy from "./pages/Team/Team";
import PrivateSpace from "./pages/PrivateSpace/PrivateSpace";
// import AllTasks from './pages/Tasks/AllTasks';
// import Teams from './pages/Teams/Teams';
import Candidates from "./pages/Candidates/Candidates";
import AllLeads from "./pages/Leads/AllLeads";
import JobOpenings from "./pages/JobOpenings/JobOpenings";
import AllPermission from "./pages/Permission/AllPermission";
import AllRoles from "./pages/Permission/AllRoles";
import ProtectedRoute from "./ProtectedRoute";
import ContentHub from "./pages/Posts/posts";
import Efforts from "./pages/Efforts/Efforts";
import PaySlip from "./pages/paySlip/PaySlip";
import BDAreport from "./pages/Reports/BDAreport";
import TaskReport from "./pages/Reports/TaskReport";
import RazorPay from "./pages/RazorPay/RazorPay";
import {  useSocket  } from "./context/SocketContext";
import AssignedTask from "./pages/Tasks/AssignedTask";
import ApprovedTask from "./pages/Tasks/ApprovedTask";
import AllPrivateSpace from "./pages/PrivateSpace/AllPrivateSpace";
import FullCalendar from "./pages/Calender.js/FullCalender";
import FullCalendars from "./pages/Calender.js/FullCalender";
import ContentGenerator from "./pages/ContentGenerator/contentGenerator";

function App() {
  const { role } = useRole();
  const { socket } = useSocket();

  const [roleLoaded, setRoleLoaded] = useState(false);

  useEffect(() => {
    setupAxiosInterceptors();
    setRoleLoaded(true)
  }, []);

  useEffect(() => {
    const requestNotificationPermission = async () => {
      if ("Notification" in window) {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          alert("Permission denied for notifications");
        }
      }
    };
  
    const handleAssignClient = ({ message }) => {
      new Notification("New message", { body: message });
    };
  
    const handleAssignTask = ({ message }) => {
      new Notification("New message", { body: message });
    };
  
    const handleHighPriorityTask = ({ message }) => {
      new Notification("🔥 High Priority Task", { body: message });
  
      // Alert that blocks interaction until acknowledged
      alert(`🚨 HIGH PRIORITY TASK: ${message}`);
  
      // Send acknowledgment back to the server
      // socket.emit("notification-acknowledged", { taskId, userId });
    };
  
    const handleCompletedTask = ({ message }) => {
      new Notification("New message", { body: message });
    };
  
    const handleDeliveredTask = ({ message }) => {
      new Notification("New message", { body: message });
    };

    const handleCommentTask = ({ message }) => {
      new Notification("New message", { body: message });
    };


  
    // Request notification permission on mount
    requestNotificationPermission();
  
    if (socket) {
      // Remove any existing event listeners before adding new ones
      socket.off("client-assigned");
      socket.off("task-assigned");
      socket.off("high-priority-task-assigned");
      socket.off("task-completed");
      socket.off("task-delivered");
      socket.off("comment-received");
  
      // Add event listeners
      socket.on("client-assigned", handleAssignClient);
      socket.on("task-assigned", handleAssignTask);
      socket.on("high-priority-task-assigned", handleHighPriorityTask);
      socket.on("task-completed", handleCompletedTask);
      socket.on("task-delivered", handleDeliveredTask);
      socket.on("comment-received", handleCommentTask);
    }
  
    return () => {
      if (socket) {
        socket.off("client-assigned", handleAssignClient);
        socket.off("task-assigned", handleAssignTask);
        socket.off("high-priority-task-assigned", handleHighPriorityTask);
        socket.off("task-completed", handleCompletedTask);
        socket.off("task-delivered", handleDeliveredTask);
      }
    };
  }, [socket]);
  

  if (!roleLoaded) {
    return <div>Loading...</div>
  }

  // Redirect to role-based path when role changes
  // useEffect(() => {
  //   if (role) {
  //     window.location.href = `/${role}/dashboard`;
  //   }
  // }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/login" replace />, // Redirect root path to login
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "client/login",
      element: <ClientLogin />,
    },
    {
      path: `${role}/`,
      element: <Layout />,
      children: [
        {
          path: "",
          element: <DashboardPage />,
        },
        {
          path: "dashboard",
          element: <DashboardPage />,
        },

        // Clients (Protected)
        {
          path: "clients",
          children: [
            {
              path: "all",
              element: (
                <ProtectedRoute permission="SeeClient">
                  <AllClients />
                </ProtectedRoute>
              ),
            },
            {
              path: "orders",
              element: (
                <ProtectedRoute permission="SeeClientsOrder">
                  <Orders />
                </ProtectedRoute>
              ),
            },
          ],
        },

        // Leads (Protected)
        {
          path: "leads",
          children: [
            {
              path: "all",
              element: (
                <ProtectedRoute permission="SeeLeads">
                  <AllLeads />
                </ProtectedRoute>
              ),
            },
            {
              path: "orders",
              element: (
                <ProtectedRoute permission="SeeLeadsOrders">
                  <Orders />
                </ProtectedRoute>
              ),
            },
          ],
        },
        // Efforts (Protected)
        {
          path: "my-efforts",
          element: (
            <ProtectedRoute permission="SeeEfforts">
              <Efforts />
            </ProtectedRoute>
          ),
        },

        //Content Generator (Protected)
        {
          path: "content-generator",
          element: (
            
              <ContentGenerator />
            

          ),
        },


        // Permissions (Protected)
        {
          path: "permissions",
          children: [
            {
              path: "all",
              element: (
                <ProtectedRoute permission="SeePermissions">
                  <AllPermission />
                </ProtectedRoute>
              ),
            },
          ],
        },

        // Roles (Protected)
        {
          path: "roles",
          children: [
            {
              path: "all",
              element: (
                <ProtectedRoute permission="GetRolesWithPermission">
                  <AllRoles />
                </ProtectedRoute>
              ),
            },
          ],
        },

        // Employees (Protected)
        {
          path: "employees",
          children: [
            {
              path: "all",
              element: (
                <ProtectedRoute permission="SeeEmployess">
                  <AllEmployees />
                </ProtectedRoute>
              ),
            },
            {
              path: "attendance",
              element: (
                <ProtectedRoute permission="SeePresentEmployee">
                  <Attendance />
                </ProtectedRoute>
              ),
            },
          ],
        },

        // Team (Protected)
        {
          path: "team",
          element: (
            <ProtectedRoute permission="SeeTeam">
              <TeamHierarchy />
            </ProtectedRoute>
          ),
        },

        // Transactions (Protected)
        {
          path: "transactions",
          element: (
            <ProtectedRoute permission="SeeTransaction">
              <Transactions />
            </ProtectedRoute>
          ),
        },

        // Tasks (Protected)
        {
          path: "tasks",
          children: [
            {
              path: "all",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <AllTasks />
                </ProtectedRoute>
              ),
            },
            {
              path: "running",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <RunningTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "completed",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <CompletedTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "delivered",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <DeliveredTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "overdue",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <OverdueTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "deleted",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <DeletedTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "quality-check",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <QualityCheckTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "assigned",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <AssignedTask />
                </ProtectedRoute>
              ),
            },
            {
              path: "approved",
              element: (
                <ProtectedRoute permission="SeeTask">
                  <ApprovedTask />
                </ProtectedRoute>
              ),
            },
          ],
        },



        // Private Space (Protected)
        {
          path: "private-space",
          element: (
            <ProtectedRoute permission="PrivateSpace">
              <PrivateSpace />
            </ProtectedRoute>
          ),
        },
        {
          path: "all-private-space",
          element: (
            <ProtectedRoute permission="AllPrivateSpace">
              <AllPrivateSpace/>
            </ProtectedRoute>
          ),
        },

        {
          path: "razorpay",
          element: (

            <RazorPay />

          ),
        },
        // Candidates (Protected)
        {
          path: "candidates",
          element: (
            <ProtectedRoute permission="Candidates">
              <Candidates />
            </ProtectedRoute>

          ),
        },
        {
          path: "bda-report",
          element: (
            <ProtectedRoute permission="SeeBDAreports">
              <BDAreport />
            </ProtectedRoute>

          ),
        },
        {
          path: "task-report",
          element: (
            <ProtectedRoute permission="SeeTaskReport">
              <TaskReport />
            </ProtectedRoute>

          ),
        },
        {
          path: "jobs-openings",
          element:
            (<ProtectedRoute permission="Jobs">
              <JobOpenings />
            </ProtectedRoute>),
        },

        {
          path: "posts",
          element:
            (<ProtectedRoute permission="PrivateSpace">
              <ContentHub />
            </ProtectedRoute>)
        },
        {
          path: "calender",
          element: 
          
            <FullCalendars />
       
        },
        {
          path: "pay-slip",
          element:
            (<ProtectedRoute permission="SeePayslip">
              <PaySlip />
            </ProtectedRoute>)
        },
      ],
    },

    {
      path: "*",
      element: <NotFoundPage />,
    },
  ]);



  return (
    <>
      <div>
        <RouterProvider router={router} />
      </div>
    </>
  );
}

export default App;
