import React, { useRef } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { FaPencilAlt, FaPhoneAlt, FaGlobe, FaClipboardList, FaLinkedin } from 'react-icons/fa';
import { TbCalendarTime } from 'react-icons/tb';
import { TiSortAlphabetically } from 'react-icons/ti';
import useOutsideClick from '../../Hooks/useClickOutside';

const ViewLeads = ({ isModalOpen, leads, closeModal, openEditModal }) => {
  const closeRef = useRef(null);

  // Close modal when clicking outside
  useOutsideClick(closeRef, () => closeModal(false));

  // Lead details with labels and icons
  const leadDetails = [
    { label: 'Phone', value: leads?.phoneNo, Icon: FaPhoneAlt },
    { label: 'Country', value: leads?.country, Icon: FaGlobe },
    { label: 'Work', value: leads?.work, Icon: TiSortAlphabetically },
    { label: 'Status', value: leads?.status, Icon: FaClipboardList },
    { label: 'Created At', value: leads?.createdAt ? new Date(leads?.createdAt).toLocaleDateString() : 'N/A', Icon: TbCalendarTime },
    { label: 'Updated At', value: leads?.updatedAt ? new Date(leads?.updatedAt).toLocaleDateString() : 'N/A', Icon: TbCalendarTime },
    { label: 'LinkedIn', value: leads?.linkedInURL, Icon: FaLinkedin },
  ];

  return (
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-4 sm:p-6 transition-opacity duration-300 ease-in-out ${
        isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      {/* Modal Container */}
      <div
        ref={closeRef}
        className={`bg-white rounded-xl shadow-xl w-full max-w-[90vw] sm:max-w-[80vw] md:max-w-3xl lg:max-w-4xl transform transition-transform duration-500 ease-in-out overflow-auto max-h-[80vh] ${
          isModalOpen ? 'translate-y-0' : 'translate-y-full'
        }`}
      >
        {/* Header Section */}
        <div className="flex justify-between items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white px-4 sm:px-6 py-3">
          <h2 className="text-base sm:text-lg font-semibold">Lead Details</h2>
          <button onClick={closeModal} className="text-white hover:text-gray-300 focus:outline-none">
            <RxCross2 className="h-5 w-5 sm:h-6 sm:w-6" />
          </button>
        </div>

        {/* Lead Information */}
        <div className="p-4 sm:p-6 space-y-6">
          <div className="flex flex-wrap sm:flex-nowrap items-center justify-between gap-4">
            {/* Profile Picture and Name */}
            <div className="flex items-start space-x-3 sm:space-x-4">
              <div className="h-14 w-14 sm:h-16 sm:w-16 rounded-full bg-blue-100 flex items-center justify-center">
                <span className="text-blue-600 text-lg sm:text-xl font-semibold uppercase">
                  {leads?.name?.[0] || 'L'}
                </span>
              </div>
              <div>
                <h3 className="text-lg sm:text-xl font-semibold text-gray-900">{leads?.name}</h3>
                <p className="text-gray-500 text-sm truncate max-w-[200px]">{leads?.email}</p>
              </div>
            </div>

            {/* Edit Button */}
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-3 rounded-full flex items-center"
              onClick={openEditModal}
              title="Edit"
            >
              <FaPencilAlt className="w-4 h-4 sm:w-5 sm:h-5" />
            </button>
          </div>

          {/* Details Section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {leadDetails.map(({ label, value, Icon }, index) => (
              <div key={index} className="flex items-center space-x-2 sm:space-x-3">
                <Icon className="w-5 h-5 text-gray-400" />
                <div className="overflow-hidden">
                  <p className="text-gray-500 text-xs sm:text-sm">{label}</p>
                  <p className="font-medium text-gray-800 text-sm sm:text-base text-nowrap max-w-[280px]">
                    {label === 'LinkedIn' && value ? (
                      <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                        {value}
                      </a>
                    ) : (
                      value || 'N/A'
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLeads;
