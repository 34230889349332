
import React, { useRef } from 'react';
import { RxCross2 } from 'react-icons/rx';
import useOutsideClick from '../../Hooks/useClickOutside';

const ViewBDAreport = ({ isModalOpen, closeModal, selectedBDA }) => {
    const closeRef = useRef(null);
    useOutsideClick(closeRef, () => closeModal(false));

    if (!isModalOpen || !selectedBDA) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-6 transition-opacity duration-300 ease-in-out">
            <div ref={closeRef} className="bg-white rounded-2xl shadow-xl max-h-[70vh] w-full sm:max-w-md md:max-w-2xl lg:max-w-3xl transform transition-transform duration-500 ease-in-out overflow-x-auto scrollbar-thin">
                <div className="flex justify-between items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white px-6 py-4">
                    <h2 className="text-lg font-semibold">Task Details - {selectedBDA.bdaName}</h2>
                    <button onClick={closeModal} className="text-white hover:text-gray-300 focus:outline-none">
                        <RxCross2 className="h-6 w-6" />
                    </button>
                </div>
                <div className="p-6 space-y-6">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-2">
                        {[
                            { label: 'BDA ID', value: selectedBDA.emp_id },
                            { label: 'Total Tasks', value: selectedBDA.totalTasks },
                            { label: 'Completed Tasks', value: selectedBDA.completedTasks },
                            { label: 'Delivered Tasks', value: selectedBDA.deliverTasks },
                            { label: 'Overdue Tasks', value: selectedBDA.delayedTasks },
                            { label: 'Revenue Given', value: selectedBDA.revenueGiven },


                        ].map(({ label, value }, index) => (
                            <div className='flex items-center' key={index}>
                                <p className="text-gray-500 text-sm">{label}</p>
                                <span className="px-4 py-2text-sm">{value}</span>
                            </div>
                        ))}
                    </div>

                    <h3 className="text-lg font-semibold text-gray-900 mb-2">Tasks</h3>
                    {selectedBDA.tasks.length > 0 ? (
                        <div className="space-y-4">
                            {selectedBDA.tasks.map((task, idx) => (
                                <div key={idx} className={`p-4 bg-gray-50 rounded-lg shadow`}>
                                    <p><strong>Title:</strong> {task.title}</p>
                                    <p><strong>Description:</strong> {task.description}</p>
                                    <p><strong>Task ID:</strong> {task.taskId}</p>
                                    <p><strong>Status:</strong> {task.status}</p>
                                    <p><strong>Soft Deadline:</strong> {new Date(task.soft_deadline).toLocaleDateString()}</p>
                                    <p><strong>Hard Deadline:</strong> {new Date(task.hard_deadline).toLocaleDateString()}</p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-500">No tasks available.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ViewBDAreport;