
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useRole } from './RoleContext';

const PermissionContext = React.createContext();

export const usePermissions = () => useContext(PermissionContext);

const PermissionProvider = ({ children }) => {
    const { role } = useRole();
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const fetchPermissions = async () => {
            if (role && role !== 'superadmin' && role !== 'admin') {
                try {
                    const response = await axios.get(`${apiUrl}/api/roles/${role}/permissions`, { withCredentials: true });
                    setPermissions(response.data);
                    // console.log('Fetched permissions:', response.data);
                } catch (error) {
                    console.error('Error fetching permissions', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setPermissions([]);
                setLoading(false);
            }
        };
        
        fetchPermissions();
    }, [role]);

    return (
        <PermissionContext.Provider value={{ permissions, loading }}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionProvider;
