import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useRole } from "../../context/RoleContext";
import { toast } from "react-toastify";
import useOutsideClick from "../../Hooks/useClickOutside";

const AddPermissionModal = ({ closeAddModal, setIsAddModalOpen, initialPermissionData }) => {
    const [formData, setFormData] = useState({
        permission_name: "",
        org_id: "",
    });
    const apiUrl = process.env.REACT_APP_API_URL;
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { role } = useRole();
    const isSuperAdmin = role === "superadmin";

    useEffect(() => {
        const fetchOrganizations = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/fetchorganization`, { withCredentials: true });
                setOrganizations(response.data.organizations);
            } catch (err) {
                console.error("Error fetching organizations:", err);
                setError("Failed to fetch organization list");
            }
        };

        fetchOrganizations();
    }, [apiUrl]);

    useEffect(() => {
        if (initialPermissionData) {
            setFormData({
                permission_name: initialPermissionData.permission_name || "",
                org_id: initialPermissionData.org_id || "",
            });
        }
    }, [initialPermissionData]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        let response;
        try {
            if (initialPermissionData) {
                response = await axios.put(`${apiUrl}/api/permissions/${initialPermissionData._id}`, formData, { withCredentials: true });
            } else {
                response = await axios.post(`${apiUrl}/api/permissions/`, formData, { withCredentials: true });
            }
        } catch (err) {
            console.error("Error submitting permission data:", err);
            setError(err.response?.data?.message || "Failed to submit permission data");
        } finally {
            toast(response.data.message);
            setLoading(false);
            closeAddModal(false);
        }
    };
    const closeRef = useRef(null);
    useOutsideClick(closeRef, () => closeAddModal(false));
    return (
        <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 sm:p-8 min-h-screen">
            <div ref={closeRef}  className="bg-white rounded-lg shadow-lg p-8 sm:p-10 max-h-[33rem] overflow-y-auto scrollbar-thin w-screen max-w-lg transition-transform transform scale-100">
                <div className="flex justify-between items-center border-b pb-4">
                    <h2 className="text-xl font-semibold mb-4">
                        {initialPermissionData ? "Edit Permission" : "Add Permission"}
                    </h2>
                    <button className="text-gray-500 hover:text-gray-800 text-2xl" onClick={closeAddModal}>
                        &times;
                    </button>
                </div>
                <form className="space-y-6 mt-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="permission_name" className="block text-sm font-medium text-gray-700">
                            Permission Name*
                        </label>
                        <input
                            type="text"
                            id="permission_name"
                            name="permission_name"
                            value={formData.permission_name}
                            onChange={handleChange}
                            className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                            placeholder="Enter permission name"
                            required
                        />
                    </div>
                    {isSuperAdmin && (
                        <div>
                            <label htmlFor="org_id" className="block text-sm font-medium text-gray-700">
                                Organization
                            </label>
                            <select
                                id="org_id"
                                name="org_id"
                                value={formData.org_id}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                            >
                                <option value="" disabled>
                                    Select Organization
                                </option>
                                {organizations.map((org) => (
                                    <option key={org._id} value={org.org_id}>
                                        {org.org_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="flex justify-end space-x-4 mt-6">
                        <button
                            type="button"
                            className="bg-gray-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-gray-600 transition"
                            onClick={closeAddModal}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 text-sm rounded-lg hover:bg-blue-600 transition"
                            disabled={loading}
                        >
                            {initialPermissionData ? (loading ? "Editing..." : "Edit Permission") : (loading ? "Creating..." : "Create Permission")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddPermissionModal;
