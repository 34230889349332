import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNotification } from '../../context/NotificationContext'
import { useLoggedInUser } from '../../context/LoggedUser'


const GenerateSalarySlip = () => {
  const [formData, setFormData] = useState({
    employeeId:"",
    employeeName: "",
    bankName: "",
    bankAccountHolder: "",
    bankAccountNumber: "",
    ifscCode: "",
    daysOfWorking: 0,
    perDaySalary: 0,
    // role: "",
    incentive: 0,
    deduction:0,
    month:"",
  });
  
  const apiUrl = process.env.REACT_APP_API_URL;
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [salarySlipData, setsalarySlipData] = useState();
  const {createNotification} = useNotification()
  const { loggedInUser } = useLoggedInUser()
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  

  useEffect(() => {
  const fetchEmployees = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/employees/get-all-employees`,
        { withCredentials: true }
      );
      setEmployeeData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };
   fetchEmployees()
}, []);
  
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; 
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    // console.log("Selected Emp",selectedEmployee)

    let perDaySalary = Math.ceil(selectedEmployee?.salary/daysInMonth)

      setFormData({
        employeeId:selectedEmployee?._id || "",
        employeeName: selectedEmployee?.name || "",
        bankName: selectedEmployee?.bankDetails?.bankName || "",
        bankAccountHolder: selectedEmployee?.bankDetails?.bankAccountHolder || "",
        bankAccountNumber: selectedEmployee?.bankDetails?.bankAccountNumber || "",
        ifscCode: selectedEmployee?.bankDetails?.ifscCode || "",
        perDaySalary: perDaySalary || "",
      });
  }, [selectedEmployee]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Fields that should only allow numbers
    const isNumberField = ["perDaySalary", "daysOfWorking", "incentive", "deduction"];
    
    // Prevent non-numeric values
    if (isNumberField.includes(name) && !/^\d*$/.test(value)) {
        return;
    }

    // Check if daysOfWorking is greater than or equal to 30
    if (name === "daysOfWorking" && Number(value) >= 30) {
        toast.error("Days of working should be less than 30");
        return;
    }

    setFormData({ ...formData, [name]: value });
};


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const { employeeName, ...dataToSend } = formData;
        const response = await axios.post(`${apiUrl}/api/hr/createPaySlip`, dataToSend, { withCredentials: true });
        
      if(response.status === 201){
        setsalarySlipData(response.data.data)
        setFormData({ employeeName: "", month:"", employeeId:"", bankName: "", bankAccountHolder: "", bankAccountNumber: "", ifscCode: "", daysOfWorking: "", perDaySalary: "", incentive: "" });

        //socket for realtime notification
        createNotification({
          sender: loggedInUser._id,
          receiver: response.data.data._doc.employeeId,
          message: "You Salary Slip Generated",
          // notDesc:
        });
      }
      

    } catch (error) {
      toast.error("Failed to generate salary slip.");
    }
  };

  useEffect(() => {
    if (salarySlipData) {
      generatePDF();
    }
  }, [salarySlipData]);
  
  
  const generatePDF = () => {
    const doc = new jsPDF();
  
    // Add Company Logo (Replace 'logoBase64' with your Base64-encoded image or use a URL)
    const logoUrl = `${window.location.origin}/images/logo.png`;
  
    // Load Image and Place it on the Top-Right
    const imgWidth = 40; // Adjust width as needed
    const imgHeight = 20; // Adjust height as needed
    const imgX = 150; // Position towards the right
    const imgY = 10; // Position at the top
    
    doc.addImage(logoUrl, "PNG", imgX, imgY, imgWidth, imgHeight); // Add Image
  
    // Title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text("Pay Slip", 90, 15); // Centered Title
  
    // General Information
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
  
    const generalDetails = [
      { title: "Employee Name", value: salarySlipData.employeeName || formData.employeeName },
      { title: "Bank Name", value: salarySlipData._doc.bankDetails.bankName || "N/A" },
      { title: "Bank Account Holder", value: salarySlipData._doc.bankDetails.bankAccountHolder || "N/A" },
      { title: "Bank Account Number", value: salarySlipData._doc.bankDetails.bankAccountNumber || "N/A" },
      { title: "IFSC Code", value: salarySlipData._doc.bankDetails.ifscCode || "N/A" },
      { title: "Month", value: salarySlipData._doc.month || "N/A" },
    ];
  
    let y = 30; // Start position for general details
    generalDetails.forEach((detail) => {
      doc.text(`${detail.title}: ${detail.value}`, 20, y);
      y += 10;
    });
  
    // Table Data for Salary Breakdown
    const salaryDetails = [
      ["Days of Working", salarySlipData._doc.daysOfWorking || "N/A"],
      ["Per Day Salary", salarySlipData._doc.perDaySalary || "N/A"],
      ["Incentive", salarySlipData._doc.incentive || "N/A"],
      ["Deduction", salarySlipData._doc.deduction || "N/A"],
      ["Total Salary(Rs)", salarySlipData._doc.totalSalary],
    ];
  
    y += 10;
  
    doc.autoTable({
      startY: y,
      head: [["Field", "Details"]],
      body: salaryDetails,
      theme: "grid",
      styles: {
        font: "helvetica",
        fontSize: 12,
        cellPadding: 4,
      },
      headStyles: {
        fillColor: [0, 102, 204], // Blue Header
        textColor: [255, 255, 255], // White Text
        fontSize: 13,
        fontStyle: "bold",
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light Grey Background for Alternate Rows
      },
      margin: { top: 25 },
    });
  
    // Save the PDF
    doc.save("Pay_Slip.pdf");
    toast.success("Salary Slip generated successfully!");
  };
  
  

  return (
    <div className="rounded-lg w-auto h-full p-10 m-6">
      <h2 className="text-2xl font-bold pb-12">
        Generate Pay Slip
      </h2>
      <form onSubmit={handleSubmit}>
        <p className="p-2 text-lg font-medium">Select an Employee</p>
        <select
          name="employeeId"
          value={formData.employeeId}
          onChange={(e) => {
            const selectedEmployee = employeeData.find(emp => emp._id === e.target.value);
            setSelectedEmployee(selectedEmployee);
            handleChange(e); 
          }}
          className="w-full p-4 border rounded-lg mb-2"
          required
        >
          <option value="" disabled>Select Employee</option>
          {employeeData.map((employee, index) => (
            <option key={index} value={employee._id}>
              {employee.name}
            </option>
          ))}
        </select>

        <p className="p-2 text-lg font-medium">Bank Name</p>
        <input
          type="text"
          name="bankName"
          placeholder="Enter Bank Name"
          value={formData.bankName}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
          disabled
        />
        <p className="p-2 text-lg font-medium">Bank Account Holder</p>
        <input
          type="text"
          name="bankAccountHolder"
          placeholder="Enter bank Holder"
          value={formData.bankAccountHolder}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
          disabled
        />
        <p className="p-2 text-lg font-medium">Bank Account Number</p>
        <input
          type="text"
          name="bankAccountNumber"
          placeholder="Enter Bank Account Number"
          value={formData.bankAccountNumber}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
          disabled
        />
        <p className="p-2 text-lg font-medium">IFSC Code</p>
        <input
          type="text"
          name="ifscCode"
          placeholder="Enter IFSC Code"
          value={formData.ifscCode}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
          disabled
        />
        <p className="p-2 text-lg font-medium">Days of Working</p>
        <input
          type="number"
          name="daysOfWorking"
          placeholder="Enter days of working"
          value={formData.daysOfWorking}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
          required
        />
        <p className="p-2 text-lg font-medium">Month</p>
          <select
            name="month"
            value={formData.month}
            onChange={handleChange}
            className="w-full p-4 border rounded-lg mb-2"
            required
          >
            <option value="" disabled>Select a month</option>
            {months.map((month, index) => (
              <option key={index} value={month}>{month}</option>
            ))}
          </select>
        <p className="p-2 text-lg font-medium">Per Day Salary</p>
        <input
          type="number"
          name="perDaySalary"
          placeholder="Enter per day salary"
          value={formData.perDaySalary}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
          disabled
        />
        {/* <p className="p-2 text-lg font-medium">Role</p> */}
        {/* <select
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="w-full p-4 border rounded-lg mb-2"
            required
            >
            <option value="" disabled>Select Employee</option>
            <option value="intern">Intern</option>
            <option value="employee">Employee</option>
        </select> */}
        <p className="p-2 text-lg font-medium">Incentive</p>
        <input
          type="number"
          name="incentive"
          placeholder="Enter Incentive"
          value={formData.incentive}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
        />
        <p className="p-2 text-lg font-medium">Deduction</p>
        <input
          type="number"
          name="deduction"
          placeholder="Enter Deduction"
          value={formData.deduction}
          onChange={handleChange}
          className="w-full p-4 border rounded-lg mb-2"
        />
        <div className="flex justify-start space-x-2 mt-4 gap-2 py-5">
        <button type="submit" className="bg-blue-500 p-2 px-5 text-white text-xl rounded-lg">
         Generate
        </button>
        </div>
      </form>
    </div>
  );
};

export default GenerateSalarySlip;
