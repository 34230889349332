import React, { useState, useRef, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { IoReload } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";

const AllPrivateSpace = () => {
  const [files, setFiles] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // Store selected file
  const apiUrl = process.env.REACT_APP_API_URL;
  const hasFetched = useRef(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 4;

  const startIndex = (currentPage - 1) * filesPerPage;
  const endIndex = startIndex + filesPerPage;
  const currentFiles = filterData.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filterData.length / filesPerPage);
  
  const getPrivateFiles = async () => {
    try {
      if (hasFetched.current) return;
      hasFetched.current = true;

      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/privateSpace/get-all-privatefile`, {
        withCredentials: true,
      });

      console.log("response", response);
      
      setFiles(response.data.data || []);
      setFilterData(response.data.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching private files:", error.response?.data || error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivateFiles();
  }, [apiUrl]);

  const formatFileSize = (size) => {
    if (!size) return "Unknown";
  
    let sizeInBytes = parseFloat(size);
  
    if (isNaN(sizeInBytes)) return size; // If it's not a number, return the original
  
    let sizeInGB = sizeInBytes / (1024 * 1024 * 1024); // Convert to GB
    let sizeInMB = sizeInBytes / (1024 * 1024); // Convert to MB
  
    // If the file is >= 1GB, display in GB, else in MB
    return sizeInGB >= 1
      ? `${sizeInGB.toFixed(2)} GB`
      : `${sizeInMB.toFixed(2)} MB`;
  };
  
  const getFileType = (fileName) => {
    if (!fileName) return "Unknown"; // If no file name, return "Unknown"
  
    const fileExtension = fileName.split(".").pop(); // Extract the last part after the dot
    return fileExtension ? fileExtension.toUpperCase() : "Unknown"; // Convert to uppercase
  };

  
  const handleReload = () => {
    hasFetched.current = false;
    getPrivateFiles();
  };

  useEffect(() => {
    const filtered = files.filter((file) =>
      file.fileName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilterData(filtered);
    setCurrentPage(1);
  }, [searchQuery, files]);

  return (
    <div className="flex flex-col h-screen bg-gray-50 p-6">
      <main className="grid grid-cols-1 lg:grid-cols-5 gap-6">
        {/* Left Section - Uploaded Files */}
        <div className="lg:col-span-3 col-span-1 h-[32rem] bg-white shadow-md rounded-xl p-6 overflow-y-auto">
          <div className="flex items-center justify-between border-b pb-4">
            <h2 className="text-lg lg:text-xl font-semibold text-gray-800">📁 All PrivateSpace Files</h2>
            <div className="flex items-center gap-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search files..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-28 lg:w-64 px-4 py-2 pr-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                />
                <FaSearch className="absolute w-4 h-4 text-gray-400 right-3 top-2.5" />
              </div>
              <IoReload
                className="w-6 h-6 text-gray-600 cursor-pointer hover:text-gray-800 transition"
                onClick={handleReload}
              />
            </div>
          </div>
  
          {/* File List */}
          <div className="py-4 space-y-3">
            {loading ? (
              <p className="text-center text-gray-500">Loading files...</p>
            ) : currentFiles.length > 0 ? (
              currentFiles.map((file) => (
                <div
                  key={file._id}
                  className="p-3 border rounded-lg cursor-pointer hover:bg-gray-100 transition"
                  onClick={() => setSelectedFile(file)}
                >
                  <div className="flex justify-between items-center">
                    <h3 className="font-medium text-sm lg:text-lg text-blue-600 truncate">
                      {file.fileName}
                    </h3>
                    <span className="px-3 py-1 text-xs text-green-700 bg-green-100 rounded-md">
                      {new Date(file.timestamp).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No files found.</p>
            )}
          </div>
  
          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-center items-center gap-4 mt-4">
              <button
                className={`px-4 py-2 rounded-lg border ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-200"}`}
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                Previous
              </button>
              <span className="text-sm font-medium">Page {currentPage} of {totalPages}</span>
              <button
                className={`px-4 py-2 rounded-lg border ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-200"}`}
                disabled={currentPage === totalPages}
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Next
              </button>
            </div>
          )}
        </div>
  
        {/* Right Section - Task Details */}
        <div className="lg:col-span-2 col-span-1 h-[32rem] bg-white shadow-md rounded-xl p-6 overflow-y-auto border">
          <h2 className="text-lg lg:text-xl font-semibold text-gray-900 border-b pb-3 flex items-center gap-2">📂 File Details</h2>
          {selectedFile ? (
            <div className="mt-4 space-y-4">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-800 truncate">
                  {selectedFile.fileName}
                </h3>
                {/* <IoMdDownload
                  className="w-6 h-6 text-blue-600 cursor-pointer hover:text-blue-800 transition"
                  onClick={() => window.open(selectedFile.fileUrl, "_blank")}
                /> */}
              </div>
              
              <div className="text-gray-600 text-sm space-y-3 border-l-4 border-blue-500 pl-4">
                <p>
                  <span className="font-medium text-gray-700">📅 Uploaded on:</span> {new Date(selectedFile.timestamp).toLocaleString()}
                </p>
                <p>
                  <span className="font-medium text-gray-700">📄 File Type:</span> {getFileType(selectedFile.fileName)}
                </p>
                <p>
                  <span className="font-medium text-gray-700">💾 Size:</span> {formatFileSize(selectedFile.fileSize)}
                </p>
              </div>
  
              <div className="flex gap-3">
                <button
                  onClick={() => window.open(selectedFile.fileUrl, "_blank")}
                  className="w-full py-2 text-center bg-blue-500 text-white font-medium rounded-lg shadow-md hover:bg-blue-600 transition"
                >
                  🔍 View File
                </button>
                <button
                  onClick={() => window.open(selectedFile.fileUrl, "_blank")}
                  className="w-full py-2 text-center bg-gray-200 text-gray-800 font-medium rounded-lg shadow-md hover:bg-gray-300 transition"
                >
                  ⬇️ Download
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-gray-500">
              <p className="text-center text-sm">📌 Click on a file to see details.</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
  
};

export default AllPrivateSpace;
