import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white p-4 shadow-md ">
      <p className="text-gray-600 text-sm text-center">
        &copy; 2025 Requin Solutions. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
